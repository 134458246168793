import React from 'react'
import { useQuery, useMutation } from 'react-query'
import { pathOr } from 'ramda'
import { UserColumns } from './auth.types'
import { request, MutationOptions, QueryVariables, Mutation } from '@shared/innmaxLib/services'

export const updateUserColumnSettingAPI = (body: any) => {
  return request('/memberInfo/columnMap', { method: 'POST', body })
}

export const useUserColumns = ({
  variables,
  ...options
}: QueryVariables = {}) => {
  const { data, isLoading, ...others } = useQuery(
    ['/memberInfo/columnMap', variables],
    {
      ...options,
      cacheTime: 60000,
      select: res => pathOr<UserColumns>({}, ['data'], res)
    }
  )

  const dataSource = React.useMemo(() => {
    let _data: any = data
    if (_data) {
      _data['solarstreetlight'] = {
        // group: true,
        // node: true,
        // deviceName: true,
        // mac: true,
        // gatewayId: true,
        // solarType: true,
        // baterryType: true,
        // lampType: true,
        // batteryStatus: true,
        // lat: true,
        // lon: true,
        // reportDatetime: true,
        // solarVoltage: true,
        // batteryCurrent: true,
        // solarPower: true,
        // batteryVoltage: true,
        // outputVoltage: true,
        // outputCurrent: true,
        // outputPower: true,
        // rssi: true,
        showMore: false,
        web: true,
      }
    }
    return _data
  }, [data])

  return {
    data: dataSource,
    personalColumn: dataSource,
    userColumns: dataSource,
    checking: isLoading,
    loading: isLoading,
    ...others,
  }
}

export function useUpdateUserColumn(options: MutationOptions) {
  const { mutate, isLoading } = useMutation(updateUserColumnSettingAPI, options)

  return [isLoading, mutate] as Mutation
}
