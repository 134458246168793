import Icon from '@ant-design/icons'
import cx from 'classnames'

const PermissionSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M3.125 12h2.75C6.497 12 7 11.497 7 10.875V9.5c0-.863-.436-1.622-1.1-2.072.37-.362.6-.869.6-1.428 0-1.105-.895-2-2-2s-2 .895-2 2c0 .56.23 1.066.6 1.428C2.436 7.878 2 8.638 2 9.5v1.375C2 11.497 2.503 12 3.125 12zM4.5 5.125c.483 0 .875.392.875.875s-.392.875-.875.875-.875-.392-.875-.875.392-.875.875-.875zM3.125 9.578c0-.005 0-.01.002-.015-.002-.02-.002-.043-.002-.063 0-.76.616-1.375 1.375-1.375.76 0 1.375.616 1.375 1.375 0 .022 0 .042-.002.063.002.004.002.009.002.015v1.219c0 .044-.034.078-.078.078H3.203c-.044 0-.078-.034-.078-.078V9.578zM14.875 2H1.125C.503 2 0 2.503 0 3.125v9.75C0 13.497.503 14 1.125 14h13.75c.622 0 1.125-.503 1.125-1.125v-9.75C16 2.503 15.497 2 14.875 2zm0 10.875H1.125v-9.75h13.75v9.75zM8.562 8.562h3.876c.31 0 .562-.251.562-.562 0-.31-.252-.563-.563-.563H8.563C8.252 7.438 8 7.69 8 8c0 .31.252.563.563.563zm0 2.563h2.876c.31 0 .562-.252.562-.563 0-.31-.252-.562-.563-.562H8.563c-.311 0-.563.252-.563.563 0 .31.252.562.563.562zm0-5.125h4.876c.31 0 .562-.252.562-.563 0-.31-.252-.562-.563-.562H8.564c-.311 0-.563.252-.563.563 0 .31.252.562.563.562z"
                transform="translate(-1132 -184) translate(216 180) translate(908) translate(8 4)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const Permission: React.FC<any> = ({ className, ...props }) => (
  <Icon
    {...props}
    component={PermissionSVG}
    className={cx('icon-checked', className)}
  />
)

export default Permission
