// TODO: 初次載入的讀取效果
import React from 'react'
import styled from 'styled-components'
import InfiniteScroller from 'react-infinite-scroller'
import { BackTop, List } from 'antd'
import { length } from 'ramda'
import { Skeleton } from '@shared/Loading'
import useViewport from '@shared/innmaxLib/hooks/useViewport'

type Props<T> = {
  title?: string | React.ReactNode
  onLoadMore?: () => any
  loading?: boolean
  hasMore?: boolean
  called?: boolean
  loader?: React.ReactElement
  dataSource?: T[]
  containerHeight?: number | string
  renderItem: (item: T) => React.ReactNode
}

export default function InfiniteScroll<T extends { id: number }>({
  loader = <Skeleton length={4} />,
  loading,
  hasMore,
  title,
  onLoadMore,
  dataSource = [],
  renderItem,
  containerHeight = '84vh',
}: Props<T>) {
  const ref = React.useRef<HTMLElement>()

  const [offsetTop, setOffsetTop] = React.useState(0)

  React.useEffect(() => {
    if (ref.current) {
      setOffsetTop(ref.current.offsetTop)
    }
  }, [ref])

  const { viewport } = useViewport()

  const handleInfiniteOnLoad = () => {
    if (onLoadMore) {
      onLoadMore()
    }
  }

  const renderListItem = (item: T) => {
    return (
      <ListItem key={item.id} className="mb-0">
        {renderItem(item)}
      </ListItem>
    )
  }

  const style = React.useMemo(
    () => ({
      height: `calc(${containerHeight} - 65px - ${offsetTop}px)`,
      overflow: 'auto',
    }),
    [viewport.height, offsetTop]
  )

  return (
    <>
      <BackTop
        key="back-top"
        target={() => document.getElementById('infinite-scroll-wrapper') as any}
      />
      {title ? title : ''}
      <Wrapper ref={ref as any} id="infinite-scroll-wrapper" style={style}>
        {length(dataSource) === 0 && loading && loader}
        {length(dataSource) > 0 && (
          <InfiniteScroller
            initialLoad={false}
            pageStart={0}
            loadMore={handleInfiniteOnLoad}
            useWindow={false}
            hasMore={!loading && hasMore}
            threshold={150}>
            <List
              grid={{ column: 1 }}
              dataSource={dataSource}
              renderItem={renderListItem}>
              {loading && hasMore && loader}
            </List>
          </InfiniteScroller>
        )}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  ::-webkit-scrollbar {
    /* display: none; */
    width: 0.5px;
  }
`
const ListItem = styled(List.Item)`
  /* &:after {
    content: 'xxx';
    position: absolute;
  } */
`
