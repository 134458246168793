import React from 'react'
import { Route, useHistory } from 'react-router-dom'
import { ifElse, identity, empty } from 'ramda'
import { useAuth } from '@services/auth'
import useViewport from '@shared/innmaxLib/hooks/useViewport'
import usePrepareRoutes from './usePrepareRoutes'
import type { RouteItem } from './usePrepareRoutes'

const getUrlName = (routes: RouteItem[]) => {
  let result: { [key: string]: RouteItem } = {}

  const traversal = (node: RouteItem) => {
    if (!node) return
    if (node.path) {
      result[node.path] = node
    }
    if (node.children) node.children.map(traversal)
  }

  routes.map(traversal)

  return result
}

export function useRoutes(routes: { [key: string]: any }) {
  const _routes = usePrepareRoutes()(routes)

  return {
    _routes,
    routeByUrl: getUrlName(_routes),
  }
}

export function useAppRoutes() {
  const renderRouteItem = React.useCallback((item: RouteItem) => {
    const path = `${item.path}${item.param ? `/${item.param}` : ''}`

    if (!path) {
      return
    }

    return (
      <Route
        exact
        key={item.id}
        path={path}
        component={
          item.component ? item.component : () => <h1>{item.title}</h1>
        }
      />
    )
  }, [])

  const renderRoute: any = React.useCallback(
    ({ data }: { data: RouteItem[] }) => {
      return data.map((item: RouteItem) => {
        return [
          renderRouteItem(item),
          item.children && renderRoute({ data: item.children }),
          item.hide && renderRoute({ data: item.hide }),
        ]
      })
    },
    [renderRouteItem]
  )

  const { isAuthenticated } = useAuth()
  const history = useHistory()
  const { isMobile } = useViewport()

  const [currentUrl, setCurrentUrl] = React.useState(history.location.pathname)

  history.listen(() => setCurrentUrl(history.location.pathname))

  const layoutStyle = ifElse(identity, empty, empty) as any

  return {
    isMobile,
    isAuthenticated,
    currentUrl,
    layoutStyle,
    renderRoute,
  }
}

export default useRoutes
