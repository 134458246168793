const Review = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M1 1H19V19H1z"
                  transform="translate(-10 -454) translate(0 120) translate(0 320) translate(10 14)"
                />
                <g>
                  <path
                    d="M0.018 0H18V17.982H0.018z"
                    transform="translate(-10 -454) translate(0 120) translate(0 320) translate(10 14) translate(1 1)"
                  />
                  <g
                    fill="#878787"
                    fillRule="nonzero"
                    stroke="#878787"
                    strokeWidth=".2">
                    <path
                      d="M16.859 5.627V3.512c0-.699-.566-1.264-1.265-1.264H8.587L8.148.88C7.981.355 7.495 0 6.944 0h-5.68C.565 0 0 .565 0 1.264v12.088c0 .061.005.123.014.182 0 .002 0 .006.002.007l.009.053c.001.005.001.01.003.014l.01.044.008.026.005.021c.005.02.01.037.017.055v.002c.065.19.174.358.317.495l.003.003.04.037c.008.005.015.012.02.018l.02.015c.01.01.022.018.034.029.004.001.007.005.01.007l.045.031c.001 0 .001.002.003.002.165.11.358.186.57.207.004 0 .006 0 .01.002.019.002.037.003.056.003h.01l.058.002h13.838c.58 0 1.086-.395 1.228-.957l1.616-6.462c.186-.748-.34-1.479-1.087-1.561zM1.264 1.264H6.94l.724 2.248h7.93V5.62H2.88c-.58 0-1.085.395-1.228.957l-.388 1.559V1.264zm13.838 12.082H1.264L2.88 6.884h13.838l-1.616 6.462z"
                      transform="translate(-10 -454) translate(0 120) translate(0 320) translate(10 14) translate(1 1) translate(0 1.686)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Review
