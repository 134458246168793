import { SVGProps } from 'react'

const TopArrowSVG = ({
  className,
  fill = '#0074A8',
  ...props
}: SVGProps<any>) => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M7 0L13.9282 12H0.0717969L7 0Z" fill={fill} />
  </svg>
)

export default TopArrowSVG
