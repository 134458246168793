import React from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { pathOr, forEach } from 'ramda'
import {
  request,
  useMakeMutation,
  convertData,
  DataSource,
} from '@shared/innmaxLib/services'
import type { MutationOptions } from '@shared/innmaxLib/services'

import { useConvertISchedule } from './schedule.converter'
import type { ISchedule, ILightScheduleInfo } from './schedule.types'
const { compose } = require('ramda')

export const useSchedules = (
  query?: any,
  options?: UseQueryOptions<DataSource<ISchedule>>
) => {
  const { data, isLoading, ...others } = useQuery(
    ['/device/streetlight/schedule/{pageNum}/{pageSize}', query],
    {
      ...options,
      cacheTime: 0,
    }
  )

  const { convertISchedule } = useConvertISchedule()

  const dataSource = React.useMemo(
    () =>
      compose(
        convertData<ISchedule>({
          converter: x => convertISchedule(x),
        })
      )(data),
    [data]
  )

  const scheduleById: { [key: number]: ISchedule } = {}

  forEach((x: ISchedule) => (scheduleById[x.id] = x), dataSource.content)

  return {
    ...others,
    data: dataSource,
    scheduleById,
    loading: isLoading,
  }
}

export const createSchedule = (values: Partial<ILightScheduleInfo>) =>
  request('/device/streetlight/schedule', { method: 'POST', body: values })

export const updateSchedule = ({
  id,
  ...values
}: Partial<ILightScheduleInfo> = {}) =>
  request(`/device/streetlight/schedule/${id}`, { method: 'PUT', body: values })

export const deleteSchedule = (id: React.Key) =>
  request(`/device/streetlight/schedule/${id}`, { method: 'DELETE' })

export const useAddSchedule = (options: MutationOptions = {}) => {
  return useMakeMutation(createSchedule, options)
}

export const useUpdateSchedule = (options: MutationOptions = {}) => {
  return useMakeMutation(updateSchedule, options)
}

export const useDeleteSchedule = (options: MutationOptions = {}) => {
  return useMakeMutation(deleteSchedule, options)
}

export const useGroups = () => {
  const { data, ...others } = useQuery(
    '/device/streetlight/schedule/availableLightGroups'
  )

  return {
    ...others,
    data: pathOr([], ['data'], data),
  }
}
