import { Empty, PaginationProps } from 'antd'
import { ColumnGroupType, ColumnType } from 'antd/lib/table'
import { mergeDeepRight, pathOr, prop, propOr } from 'ramda'
import React from 'react'
import styled from 'styled-components'
import Pagination from '@shared/innmaxUI/Pagination'
import { TableProps } from './table.type'
import Loading from '@shared/innmaxUI/Loading'

type Column<T> = ColumnGroupType<T> &
  ColumnType<T> & {
    dataIndex: string
  }
function MobileTableFC<T = any>(props: TableProps<T>) {
  const {
    columns,
    dataSource,
    pagination = {},
    onChange,
    loading,
    rowKey = 'id',
  } = props

  let paginationConfig = React.useMemo(
    () =>
      pagination
        ? (mergeDeepRight<any, any>(
            {
              pageSize: dataSource?.pageSize ?? 10,
              current: dataSource?.page ?? 1, //[fixed]: antd[Table] current should be positive  number
              total: dataSource?.total ?? 0,
            },
            pagination
          ) as PaginationProps)
        : pagination,
    [dataSource, pagination]
  )

  React.useEffect(() => {
    if (!paginationConfig) {
      return
    }
    const { total = 1, pageSize = 1, current = 1 } = paginationConfig
    const lastPage = Math.ceil(total / pageSize)
    if (current > lastPage) {
      onChange &&
        onChange(
          { current: lastPage, pageSize },
          {},
          {},
          {
            currentDataSource: [],
            action: 'paginate',
          }
        )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig])

  const renderRow = React.useCallback(
    (data: T, index: number) => {
      const renderColumnContent = (
        column: Column<T>,
        record: T,
        index: number
      ) => {
        const { render, dataIndex } = column

        const value = pathOr(
          '',
          Array.isArray(dataIndex) ? dataIndex : [dataIndex],
          data
        )

        if (render) {
          return render(value, record, index)
        }
        return value
      }

      return columns?.map(column => {
        const { title } = column as Column<T>
        return (
          <MobileTableColumn key={title as string}>
            <span className="title">{title}</span>
            <span className="content">
              {renderColumnContent(column as Column<T>, data, index)}
            </span>
          </MobileTableColumn>
        )
      })
    },
    [columns]
  )

  return (
    <>
      <MobileTableStyled>
        {loading ? (
          <div>
            <Loading />
          </div>
        ) : (dataSource?.content || []).length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="text-white" />
        ) : (
          (dataSource?.content || []).map((data, index) => {
            return (
              <MobileTableRow key={pathOr('', [rowKey as string], data)}>
                {renderRow(data, index)}
              </MobileTableRow>
            )
          })
        )}
      </MobileTableStyled>
      {pagination && (dataSource?.content || []).length > 0 && (
        <div className="flex justify-center">
          <Pagination
            {...paginationConfig}
            onChange={(page, pageSize) => {
              onChange &&
                onChange(
                  { current: page, pageSize },
                  {},
                  {},
                  {
                    currentDataSource: [],
                    action: 'paginate',
                  }
                )
            }}
          />
        </div>
      )}
    </>
  )
}

const MobileTableStyled = styled.div`
  margin: 15px 0;
`

const MobileTableRow = styled.div`
  box-shadow: 1px 2px 4px 2px #9a9a9a85;
  background-color: #fff;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
`

const MobileTableColumn = styled.div`
  font-size: 1.02rem;
  padding: 8px 15px;
  display: flex;
  justify-content: space-between;
  &:nth-child(even) {
    background-color: #e9e9e9;
  }
  .title {
    text-align: left;
    color: ${p => p.theme.darkGrey};
    max-width: 40%;
    word-break: break-word;
  }
  .content {
    color: #878787;
    text-align: right;
    max-width: 60%;
    word-break: break-word;
  }
`

const MobileTable = React.memo(MobileTableFC) as typeof MobileTableFC

export default MobileTable
