import {
  GoogleMapControlsOption,
  LeafletControlsOption,
} from './map.types'

export const defaultMapCenter = {
  lat: 24.028781192118768,
  lng: 120.56263625859233,
}

export const defaultMapZoom = 8

export const defaultMapStyle = { height: '728px', width: '100%' }

export const defaultGoogleMapOptions: GoogleMapControlsOption = {
  zoomControl: true,
  backRectangleSelectControl: false,
  placeSearchControl: false,
  filterControl: false,
  layerControl: false,
  satelliteControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  rotateControl: false,
  fullscreenControl: false,
  controlSize: 28,
  styles: [],
  maxZoom: 21
}

export const defaultLeafletOptions: LeafletControlsOption = {
  zoomControl: true,
  maxZoom: 21
}