import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { createPortal } from 'react-dom'
import { useGoogleMap, GoogleMap } from '@react-google-maps/api'
import { MapLayerType } from '../../map.types'
import LayerIcon from './LayerIcon'

// Currently supported only for GoogleMap

export type SatelliteControlProps = {
  position?: google.maps.ControlPosition
  styles?: React.CSSProperties
  zIndex?: string
  layerType?: MapLayerType
  center: google.maps.LatLng | google.maps.LatLngLiteral | undefined
  onClick: (layerType: MapLayerType) => void
  zoom: number
}

export default function SatelliteControl({
  position = window.google.maps.ControlPosition.BOTTOM_LEFT,
  styles = {},
  layerType = MapLayerType.SATELLITE,
  center,
  zoom,
  onClick,
}: SatelliteControlProps) {
  const { t } = useTranslation()

  const map: google.maps.Map | null = useGoogleMap()

  const [currentLayerType, setCurrentLayerType] = React.useState<MapLayerType>(
    layerType === MapLayerType.ROADMAP ? MapLayerType.SATELLITE : MapLayerType.ROADMAP
  )

  const [container] = React.useState(document.createElement('div'))

  React.useEffect(() => {
    const controlsContainer: any = map?.controls[position]
    controlsContainer.push(container)
    return () => {
      const index = controlsContainer.indexOf(container);
      if (index !== -1) {
        controlsContainer.removeAt(index);
      }
    }
  }, [map]) // eslint-disable-line

  React.useEffect(() => {
    for (const [key, value] of Object.entries(styles as any)) {
      (container.style as any)[key] = value
    }
  }, [styles]) // eslint-disable-line

  const toggleLayerType = () => {
    const _layerType = currentLayerType === MapLayerType.ROADMAP ? MapLayerType.SATELLITE : MapLayerType.ROADMAP
    setCurrentLayerType(_layerType)
    onClick && onClick(currentLayerType)
  }

  const title = React.useMemo(() =>
    currentLayerType === MapLayerType.ROADMAP ? t('dashboard:map') : t('dashboard:satellite')
  , [currentLayerType]) // eslint-disable-line

  return createPortal((
    <StyledControl title={title}>
      <GoogleMap
        id="satellite-control"
        onClick={toggleLayerType}
        center={center}
        zoom={zoom / 2}
        mapContainerStyle={{ height: '100%' }}
        options={{
          mapTypeControl: false,
          fullscreenControl: false,
          mapTypeId: currentLayerType,
          draggable: false,
        }}>
        <StyledMapType>
          <LayerIcon/>
          <span>{currentLayerType === MapLayerType.ROADMAP ? t('dashboard:map') : t('dashboard:satellite')}</span>
        </StyledMapType>
      </GoogleMap>
    </StyledControl>
  ), container);
}

const StyledControl = styled.div`
  user-select: none;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  width: 72px;
  height: 72px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border: solid 2px #fff;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    border-color: #d1e0e7;
  }

  div[aria-label='地圖'] {
    cursor: pointer !important;
  }
`

const StyledMapType = styled.div`
  display: inline-flex;
  position: absolute;
  width: 100%;
  bottom: 0;
  color: #fff;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
  background: #0d11284d;

  span {
    margin-left: 5px;
  }
`
