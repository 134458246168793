import React from 'react'
import { pathOr, equals } from 'ramda'
import { useTranslation } from 'react-i18next'
import Switch from '@shared/Form/Switch'

import { Field } from 'components/Form'

type Props = {
  name: string
  item?: any
}
const StatusFormField = React.memo(({ item, name, ...props }: Props) => {
  const { t } = useTranslation()

  const activeValue = pathOr(1, [name], item)
  const [isActive, setIsActive] = React.useState(equals(activeValue, 1))

  return (
    <>
      <div className="text-larger mr-8">
        {isActive ? t(`common:active`) : t(`common:inactive`)}
      </div>
      <Field
        {...props}
        name={name}
        getValueFromEvent={val => {
          setIsActive(val)
          return val
        }}
        valuePropName="checked"
        className="w-60"
        initialValue={isActive}>
        <Switch />
      </Field>
    </>
  )
})

export default StatusFormField
