import { useTranslation } from 'react-i18next'
import React from 'react'
import { pathOr, includes } from 'ramda'
import moment from 'moment'
import { convertData } from '@shared/innmaxLib/services'
import { momentToString, toMoment } from '@shared/utils/webHelper'
import { useAllSessions } from '../session'
import type { IRepair } from './repair.types'

export const useConvertIRepairs = (res: any) => {
  const { t } = useTranslation()
  const { stateStr, createAt, closedAt, repairTimeSec, reviewedAt, sessionName } = useConvertData()

  const dataSource = React.useMemo(
    () =>
      convertData<IRepair>({
        converter: x => {
          return {
            ...x,
            ...stateStr(x),
            ...createAt(x),
            ...closedAt(x),
            ...repairTimeSec(x),
            ...reviewedAt(x),
            ...sessionName(x),
            deviceTypeStr: x.deviceType && t(`device:${x.deviceType}`),
            ticketSourceStr: x.ticketSource && t(`repair:${x.ticketSource}`),
          }
        },
      })(res),
    [res]
  )

  return { dataSource }
}

export const useConvertIRepair = (x: any) => {
  const { t } = useTranslation()

  const { stateStr, createAt, closedAt, repairTimeSec, reviewedAt, sessionName } = useConvertData()

  return React.useMemo(() => {
    if (!x) {
      return {}
    }

    return {
      data: {
        ...x,
        ...stateStr(x),
        ...createAt(x),
        ...closedAt(x),
        ...repairTimeSec(x),
        ...reviewedAt(x),
        ...sessionName(x),
        deviceTypeStr: x.deviceType && t(`device:${x.deviceType}`),
        ticketSourceStr: x.ticketSource && t(`repair:${x.ticketSource}`),
      },
    }
  }, [x])
}

/**
 * 共用各設備轉換資料格式的功能
 */
export const useConvertData = () => {
  const { byId } = useAllSessions()

  const createAt = (d: any) => ({
    createAt: toMoment(d.createAt), //報修時間
    createAtStr: momentToString(d.createAt, 'YYYY/MM/DD HH:mm:ss'),
  })

  const reviewedAt = (d: any) => ({
    reviewedAt: toMoment(d.reviewedAt),
    reviewedAtStr: momentToString(d.reviewedAt, 'YYYY/MM/DD HH:mm:ss'),
  })

  const closedAt = (d: any) => ({
    closedAt: toMoment(d.closedAt),
    closedAtStr: momentToString(d.closedAt, 'YYYY/MM/DD HH:mm:ss'),
  })

  const repairTimeSec = (d: any) => {
    return {
      repairTimeSec: d.repairTimeSec, //維修時間
      repairTimeSecStr: (
        d?.repairTimeSec ?
          moment('00:00:00.000', 'hh:mm:ss.SSS').add(d.repairTimeSec, 's').format('mm:ss') :
          ''
      )
    }
  }

  const sessionName = (d: any) =>
    d?.sessionId
      ? {
          sessionName: pathOr('', [d.sessionId, 'name'], byId),
        }
      : {}

  const stateStr = (d: any) => ({
    stateStr:
      d.state === 'reported'
        ? '未派工'
        : d.state === 'repairing'
        ? '維修中'
        : includes(d.state, ['close', 'finish'])
        ? '結案'
        : 'Unknow',
  })

  return { stateStr, createAt, closedAt, repairTimeSec, reviewedAt, sessionName }
}
