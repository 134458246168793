import React, { Suspense } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Layout } from 'antd'
import { path, contains } from 'ramda'
import { Exception, QueryErrorBoundary } from '@shared/innmaxUI/ErrorBoundary'
import { Loading } from '@shared/innmaxUI/Loading'
import { PageHeader } from '@shared/innmaxUI/Layout'
import GlobalStyles from '@theme/GlobalStyles'
import useTitle from '@shared/innmaxLib/hooks/useTitle'
import { useProfile } from '@services/auth/useProfile'
import { useUserAccess, useUserAlive } from '@services/user'
import { RouteItem, useAppRoutes, useRoutes } from '@shared/innmaxLib/routes'
import SideMenu from 'src/components/SideMenu'
import Navbar from 'src/components/Navbar'
import Dashboard from 'src/pages/Dashboard'
import Login from 'src/pages/Login'
import LineNotifyOauthEndpoint from 'src/pages/LineNotifyOauthEndpoint'
import * as pages from './pages'
import { useMineInfo } from '@services/auth/useMineInfo'
import styled from 'styled-components'
import env, { envStore } from '@shared/env'
import WeatherWebViewManagement from './pages/WeatherWebViewManagement'

const { compose } = require('ramda')

function App() {
  const { profile, isFetching } = useProfile()

  useTitle(profile?.name ?? '')

  useGoogleTranslate()

  const { refetch: getUserMineInfo } = useMineInfo()

  const { isAuthenticated, isMobile, layoutStyle, renderRoute } = useAppRoutes()

  const [, handleUserAccess] = useUserAccess()

  const [, handleUserAlive] = useUserAlive()

  const history = useHistory()

  const { t } = useTranslation()

  const { _routes, routeByUrl } = useRoutes(pages)

  React.useEffect(() => {
    isAuthenticated && getUserMineInfo()
  }, [isAuthenticated]) // eslint-disable-line

  React.useEffect(() => {
    // 如果期間使用者有在活動(例如切換畫面移動滑鼠, 打字等)
    isAuthenticated && handleUserAccess(undefined)
  }, [isAuthenticated, history.location.pathname]) //eslint-disable-line

  React.useEffect(() => {
    // 每十分鐘打一次，確保讓使用者token不過期
    const intervalId = setInterval(() => {
      isAuthenticated && handleUserAlive(undefined)
    }, 600000)

    return () => {
      clearInterval(intervalId)
    }
  }, [isAuthenticated]) //eslint-disable-line

  React.useEffect(() => {
    if (isMobile && env.mobileUrl && !window.location.href.includes('mobile')) {
      window.location.href = env.mobileUrl
    }
  }, []) //eslint-disable-line

  useDataTitleTooltip()

  const PageTitle = compose((route: RouteItem) => {
    if (contains(history.location.pathname, ['/', '/dashboard'])) {
      return (
        <PageHeader sub={t(`menu:dashboard`)} title={t(`menu:/dashboard`)} />
      )
    }

    if (contains(history.location.pathname, ['/media-content-management'])) {
      return <PageHeader title={t('menu:media content mgmt')} />
    }

    if (!route) {
      const subRoute = Object.keys(routeByUrl).find(
        x => history.location.pathname.indexOf(x) > -1
      )

      if (subRoute) {
        const sub: any = path([subRoute])(routeByUrl)
        return <PageHeader sub={sub.parentName} title={sub.title} />
      }
      return null
    }

    return (
      <PageHeader
        sub={t(`menu:${route.mainKey}`)}
        title={t(`menu:${route.url}`) || route.title}
      />
    )
  }, path([history.location.pathname]))(routeByUrl)

  return (
    <QueryErrorBoundary>
      <GlobalStyles />
      {!isAuthenticated && (
        <Switch>
          <Route
            exact
            path="/lineNotify/oauth/endpoint"
            component={LineNotifyOauthEndpoint}
          />
          <Route
            exact
            path="/WeatherWebViewManagement"
            component={WeatherWebViewManagement}
          />
          <Switch>
            <Route
              component={() => (
                <Layout>
                  <Login />
                </Layout>
              )}
            />
          </Switch>
        </Switch>
      )}
      {isAuthenticated && (
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route
            exact
            path="/lineNotify/oauth/endpoint"
            component={LineNotifyOauthEndpoint}
          />
          <Route
            exact
            path="/WeatherWebViewManagement"
            component={WeatherWebViewManagement}
          />
          <>
            <Layout style={layoutStyle(isMobile)}>
              <LayoutStyled className="bg-secondary overflow-hidden">
                <SideMenu />
                <Layout.Content className="bg-secondary">
                  <Suspense fallback={<Loading />}>
                    <Navbar title={PageTitle} />
                    <Switch>
                      <Route exact path="/" component={Dashboard} />
                      {renderRoute({ data: _routes })}
                      <Route component={Exception} />
                    </Switch>
                  </Suspense>
                </Layout.Content>
              </LayoutStyled>
            </Layout>
          </>
        </Switch>
      )}
    </QueryErrorBoundary>
  )
}

const LayoutStyled = styled(Layout)`
  border-top: 3px solid ${({ theme }) => theme.primary};
  min-height: 100vh;
`

export default App

const useDataTitleTooltip = () => {
  React.useEffect(() => {
    window.tooltipEl = document.createElement('div')
    window.tooltipEl.id = 'data-title-tooptip'
    document.body.appendChild(window.tooltipEl)

    const handleMouseOver = (event: MouseEvent) => {
      const loopParentWithAttr = (target: any): HTMLElement | null => {
        if (target?.dataset?.title) {
          return target
        }
        if (target?.parentNode) {
          return loopParentWithAttr(target.parentNode)
        }
        return null
      }
      const attrTarget = loopParentWithAttr(event.target)
      let title = attrTarget?.dataset?.title
      if (!title) {
        window.tooltipEl.style.display = 'none'
        window.tooltipTitle = ''
      } else if (title !== window.tooltipTitle && attrTarget) {
        window.tooltipTitle = title
        window.tooltipEl.textContent = title
        window.tooltipEl.style.display = 'block'
        window.tooltipEl.style.padding = '5px'
        window.tooltipEl.style.position = 'absolute'
        window.tooltipEl.style.top = '100px'
        window.tooltipEl.style.left = '100px'
        window.tooltipEl.style.backgroundColor = '#7c7c7c'
        window.tooltipEl.style.zIndex = '999'
        window.tooltipEl.style.fontSize = '0.8rem'
        window.tooltipEl.style.borderRadius = '5px'
        window.tooltipEl.style.color = '#fff'

        const targetRect = attrTarget.getBoundingClientRect()
        window.tooltipEl.style.top = `${
          targetRect.top + window.tooltipEl.offsetHeight + 5
        }px`
        window.tooltipEl.style.left = `${targetRect.left}px`
        window.tooltipEl.style.visibility = 'hidden'
        // 延遲 500 ms 顯示，先讓 Google 翻譯完成在顯示
        setTimeout(() => {
          window.tooltipEl.style.visibility = 'visible'
        }, 500)
      }
    }

    document.addEventListener('mouseover', handleMouseOver)

    return () => {
      document.removeEventListener('mouseover', handleMouseOver)
    }
  }, [])
}

const useGoogleTranslate = () => {
  const { profile, isFetching } = useProfile()

  const comboTranslate = React.useRef<null | HTMLInputElement>(null)

  const googleTranslateElementInit = React.useCallback(() => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'zh-TW',
      },
      'google_translate_element'
    )
    const elementDetector = () => {
      const el: HTMLInputElement | null = document.querySelector(
        '#google_translate_element .goog-te-combo'
      )
      if (el) {
        comboTranslate.current = el
        profile.language &&
          setTimeout(() => googleTranslateLanguageChaged(profile.language))
      } else {
        setTimeout(elementDetector, 100)
      }
    }
    elementDetector()
  }, []) //eslint-disable-line

  const googleTranslateLanguageChaged = React.useCallback(
    (language: string) => {
      if (comboTranslate && comboTranslate.current) {
        comboTranslate.current.value = language || comboTranslate.current.value
        const event = new Event('change')
        comboTranslate.current.dispatchEvent(event)
      }
    },
    [comboTranslate]
  )

  React.useEffect(() => {
    if (envStore.isGoogleTranslateDisabled) {
      return
    }
    const addScript = document.createElement('script')
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    )
    document.body.appendChild(addScript)
    window.googleTranslateElementInit = googleTranslateElementInit
  }, [googleTranslateElementInit]) //eslint-disable-line

  React.useEffect(() => {
    profile.language &&
      !envStore.isGoogleTranslateDisabled &&
      setTimeout(() => googleTranslateLanguageChaged(profile.language))
  }, [profile.language]) //eslint-disable-line
}
