import styled, { css } from 'styled-components'

export default styled.div.attrs((props: any) => ({
  center: props.center,
  middle: props.middle,
  'justify-end': props['justify-end'],
  between: props.between,
  alginEnd: props.alginEnd,
}))`
  display: flex;

  ${p =>
    p.middle &&
    css`
      align-items: center;
    `};

  ${p =>
    p.alginEnd &&
    css`
      align-items: flex-end;
    `};

  ${p =>
    p.center &&
    css`
      justify-content: center;
    `};

  ${p =>
    p['justify-end'] &&
    css`
      justify-content: flex-end;
    `};

  ${p =>
    p.between &&
    css`
      justify-content: space-between;
    `};
`
