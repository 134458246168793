import { useQuery } from 'react-query'
import {
  QueryVariables,
  convertData,
  MutationOptions,
} from '@shared/innmaxLib/services'
import { useRequest } from '@shared/innmaxLib/services'
import { Content, OptionContent } from './'
import { DeviceType } from '../constants'

// 結案原因 (階段一)
export const useCloseReason = ({
  variables = {},
  ...options
}: QueryVariables = {}) => {
  const { data, ...others } = useQuery(
    ['/repairTicket/repair/options/closeReason', variables],
    {
      ...options,
      cacheTime: 0,
    }
  )

  const dataSource = convertData<Content>({ field: ['data'] }, data)

  return {
    ...others,
    dataSource,
    loading: others.isLoading,
  }
}

export const useAddCloseReason = (options?: MutationOptions) => {
  const { loading, data, mutate } = useRequest(
    '/repairTicket/repair/options/closeReason',
    {
      ...options,
      method: 'POST',
    }
  )

  return {
    data,
    isLoading: loading,
    mutate,
  }
}

// 結案原因 (階段二)
export const useRepairedReason = ({
  variables = {},
  ...options
}: QueryVariables = {}) => {
  const { data, ...others } = useQuery(
    ['/repairTicket/repair/options/repairedReason', variables],
    {
      ...options,
      cacheTime: 0,
    }
  )

  const dataSource = convertData<Content>({ field: ['data'] }, data)

  return {
    ...others,
    dataSource,
    loading: others.isLoading,
  }
}

export const useAddRepairedReason = (options?: MutationOptions) => {
  const { loading, data, mutate } = useRequest(
    '/repairTicket/repair/options/repairedReason',
    {
      ...options,
      method: 'POST',
    }
  )

  return {
    data,
    isLoading: loading,
    mutate,
  }
}

// 報修原因 (設備類型)
export const useReportReasonByDevice = ({
  variables = {},
  ...options
}: QueryVariables = {}) => {
  const { data, ...others } = useQuery(
    [
      '/repairTicket/repair/options/reportReason/device/{deviceType}',
      variables,
    ],
    {
      ...options,
      cacheTime: 0,
    }
  )

  const dataSource = convertData<Content>({ field: ['data'] }, data)

  return {
    ...others,
    dataSource: {
      ...dataSource,
      deviceType: variables?.deviceType,
    },
    loading: others.isLoading,
  }
}

export const useAddReportReasonByDevice = (
  deviceType: DeviceType,
  options?: MutationOptions
) => {
  const { loading, data, mutate, ...others } = useRequest(
    '/repairTicket/repair/options/reportReason/device/{deviceType}',
    {
      ...options,
      variables: {
        deviceType,
      },
      method: 'POST',
    } as any
  )

  return {
    data,
    isLoading: loading,
    mutate,
    others,
  }
}

// 報修原因 (其他)
export const useReportReason = ({
  variables = {},
  ...options
}: QueryVariables = {}) => {
  const { data, ...others } = useQuery(
    ['/repairTicket/repair/options/reportReason', variables],
    {
      ...options,
      cacheTime: 0,
    }
  )

  const dataSource = convertData<Content>({ field: ['data'] }, data)

  return {
    ...others,
    dataSource: {
      ...dataSource,
      deviceType: 'other',
    },
    loading: others.isLoading,
  }
}

export const useAddReportReason = (options?: MutationOptions) => {
  const { loading, data, mutate, ...others } = useRequest(
    '/repairTicket/repair/options/reportReason',
    {
      ...options,
      method: 'POST',
    }
  )

  return {
    data,
    isLoading: loading,
    mutate,
    others,
  }
}

// 攝影機外型
export const useCameraShape = ({
  variables = {},
  ...options
}: QueryVariables = {}) => {
  const { data, ...others } = useQuery(
    ['/device/camera/options/shape', variables],
    {
      ...options,
      cacheTime: 0,
    }
  )

  const dataSource = convertData<OptionContent>({ field: ['data'] }, data)

  return {
    ...others,
    dataSource,
    loading: others.isLoading,
  }
}

export const useAddCameraShape = (options?: MutationOptions) => {
  const { loading, data, mutate, ...others } = useRequest(
    '/device/camera/options/shape',
    {
      ...options,
      method: 'POST',
    }
  )

  return {
    data,
    isLoading: loading,
    mutate,
    ...others,
  }
}
