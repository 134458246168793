import React from 'react'
import cx from 'classnames'
import { DatePicker } from 'antd'
import { DatePickerProps } from 'antd/lib/date-picker'
import styled from 'styled-components'
import { RefProps } from '@lib/types'

type Props = DatePickerProps & RefProps

const StyledDatePicker = styled(DatePicker)`
  &.ant-picker {
    border-radius: 4px;
  }
`

const DatePickerComponent = React.forwardRef<typeof DatePicker, Props>(
  ({ className = '', ...props }, ref) => (
    <StyledDatePicker
      // forwardRef={ref}
      className={cx('c-datepicker', className)}
      placeholder=""
      {...props}
    />
  )
)

DatePickerComponent.displayName = 'DatePicker'

export default DatePickerComponent
