import React from 'react'
import { useTranslation } from 'react-i18next'
import { UserUsageStatusEnums } from '@services/user'
import { Select, SelectProps } from '@shared/Form/Select'

export const UserUsageStatusSelect = React.forwardRef<
  typeof Select,
  SelectProps
>((props, ref): any => {
  const { t } = useTranslation()

  return (
    <Select
      forwardRef={ref}
      allowClear
      placeholder={t('common:select usage state')}
      {...props}>
      {UserUsageStatusEnums().map(x => (
        <Select.Option key={x.pid} value={x.parametervalue}>
          {x.parametertext}
        </Select.Option>
      ))}
    </Select>
  )
})

export default UserUsageStatusSelect