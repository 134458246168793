import React from 'react'
import { pathOr } from 'ramda'
import { Select, SelectProps } from '@shared/Form/Select'

import { useDevices } from '@services/device'
import { DeviceType } from '@services/constants'

const SwitchBoxSelect = React.forwardRef<typeof Select, SelectProps<number>>(
  (props: SelectProps<number>, ref): any => {
    const { dataSource, loading } = useDevices({
      // deviceType: DeviceType.BOX,
      pageNum: 0,
      pageSize: 999,
    })

    return (
      <Select ref={ref as any} loading={loading} allowClear {...props}>
        {pathOr([], ['content'], dataSource).map((x: any) => (
          <Select.Option key={x.id} value={x.id}>
            {x.deviceId}
          </Select.Option>
        ))}
      </Select>
    )
  }
)

export default SwitchBoxSelect
