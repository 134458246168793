const AttachedDisplayscreen = ({ color = '#fff', ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.404 2h11.192C14.371 2 15 2.629 15 3.404v5.859c0 .775-.629 1.404-1.404 1.404H2.404A1.404 1.404 0 0 1 1 9.263V3.404C1 2.629 1.629 2 2.404 2zM8.7 10.667v2.888h4.178a.722.722 0 0 1 0 1.445L8.7 14.999 7.3 15v-.001L3.122 15a.722.722 0 1 1 0-1.444l4.178-.001v-2.888h1.4z" 
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default AttachedDisplayscreen
