import QueryTable from './QueryTable'
import styled from 'styled-components'

function ReportTable(props: any) {
  return (
    <StyledTable bordered={true} {...props}>
      {props.children}
    </StyledTable>
  )
}
const StyledTable = styled(QueryTable)`
  .ant-table-thead {
    line-height: 1em;

    > tr {
      background: transparent;
    }

    > tr > th {
      font-size: 14px;
      font-weight: 500;
      padding: 11px 12px;
      color: ${p => p.theme.reporttable.head};
      background: ${p => p.theme.reporttable.headBg};
      border-bottom-color: ${p => p.theme.reporttable.head};
      :first-child {
        font-size: 16px;
      }
    }
  }

  .ant-table-tbody {
    > tr > td {
      height: 37px;
      color: ${p => p.theme.grey} !important;
    }

    > tr.ant-table-placeholder > td:first-child {
      background: #fff !important;
    }

    > tr > td:first-child {
      font-size: 14px;
      color: ${p => p.theme.reporttable.head} !important;
      background: ${p => p.theme.reporttable.headBg} !important;
    }

    > tr > td:nth-child(2n + 1) {
      background: #f2f6f8;
    }

    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      :first-child {
        background: #9f9575 !important;
      }
      background: #fff5d5;
    }
  }
`
export default ReportTable
