import { useTranslation } from 'react-i18next'
import React from 'react'
import moment from 'moment'
import { convertData } from '@shared/innmaxLib/services'

import { DeviceActive, DeviceActiveStr } from '@services/constants'
import type { ISessionInfo, IUser } from './user.types'

export const useConvertIUsers = (res: any) => {
  const { activeStr, sessionName } = useConvertData()

  const dataSource = React.useMemo(
    () =>
      convertData<IUser>({
        converter: x => {
          return {
            ...x,
            status: Number(x.status),
            email: x?.properties?.email,
            mobile: x?.properties?.phone,
            loginTime: x?.loginTime && moment(Number(x?.loginTime)),
            loginTimeStr: x?.loginTime && moment(Number(x?.loginTime)).format('YYYY.MM.DD HH:mm:ss'),
            ...activeStr(x),
            ...sessionName(x),
          }
        },
      })(res),
    [res] //eslint-disable-line
  )

  return dataSource
}

export const useConvertData = () => {
  const { t } = useTranslation()

  const activeStr = (x: any) => {
    const status =
      x.status === -1
        ? DeviceActive.DELETE
        : x.status === 0
        ? DeviceActive.INACTIVE
        : DeviceActive.ACTIVE

    return {
      statusStr: t(`common:${DeviceActiveStr[status]}`),
    }
  }

  const sessionName = (x: any) => {
    const sessionStr = x.allSessionPermission
      ? t('common:all area')
      : x?.sessionInfos?.map((i: ISessionInfo) => i.name).join(' / ')

    const sessionIds = x.allSessionPermission
      ? [-1]
      : x?.sessionInfos?.map((i: ISessionInfo) => i.id)

    return {
      sessionStr,
      sessionIds,
    }
  }

  return {
    activeStr,
    sessionName,
  }
}
