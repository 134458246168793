import { useTranslation } from 'react-i18next'
import { Select, SelectProps } from '@shared/Form/Select'
import { useReportReasonByDevice, useReportReason } from '@services/option'
import { DeviceType } from '@services/constants'

type DeviceSelectType = {
  deviceType: DeviceType | undefined | 'other'
} & SelectProps

export default function ReportReasonSelect({ deviceType, ...props }: DeviceSelectType) {
  const { t } = useTranslation()

  const { dataSource, loading } = useReportReasonByDevice({
    variables: {
      deviceType: deviceType
    },
    enabled: deviceType !== 'other' && !!deviceType
  })

  const {
    dataSource: otherReportReason,
    loading: otherReportReasonLoading
  } = useReportReason({
    enabled: deviceType === 'other'
  })

  return (
    <Select
      {...props}
      allowClear
      showSearch
      placeholder={t('repair:brokenReasonValue')}
      loading={loading || otherReportReasonLoading}
      disabled={!deviceType}
      options={
        deviceType === 'other' ?
        otherReportReason?.content?.map((i: any) => ({ value: i.code, label: i.code })) :
        dataSource?.content?.map((i: any) => ({ value: i.code, label: i.code }))
      }>
    </Select>
  )
}
