const ExportFile = ({ color = '#fff', ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12.61 9.113a.56.56 0 0 0-.558.559v3.186c0 .16-.13.29-.291.29H2.9a.29.29 0 0 1-.29-.29V3.684c0-.16.13-.29.29-.29H6.88a.56.56 0 0 0 0-1.119H2.903c-.776 0-1.408.631-1.408 1.408v9.173c0 .775.632 1.406 1.408 1.406h8.861c.777 0 1.408-.63 1.408-1.407V9.672a.563.563 0 0 0-.561-.56zM6.77 5.828a5.665 5.665 0 0 0-.851 2.125.56.56 0 0 0 .553.64.564.564 0 0 0 .553-.476c.005-.037.147-.881.695-1.695.72-1.069 1.803-1.614 3.213-1.619l.056.003a.56.56 0 0 0 .56-.56V3.59l1.57 1.494-1.572 1.31v-.535a.56.56 0 0 0-1.119 0v1.728a.561.561 0 0 0 .916.431l2.97-2.473a.558.558 0 0 0 .198-.411.559.559 0 0 0-.171-.425L11.37 1.88a.555.555 0 0 0-.941.405v1.42c-1.964.164-3.074 1.24-3.66 2.122z" 
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  )
}

export default ExportFile
