import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Select, SelectProps } from '@shared/Form/Select'
import { RangePicker, DatePicker } from '@shared/Form/DatePicker'

import {
  TYPE,
  ReportSearchType,
  IQueryProps,
} from '@services/report'

type Props = {
  onChange?: (val?: any) => void
} & Omit<SelectProps, 'onChange'>

export default function ReportRangeTimeSelect({ onChange, ...props }: Props) {
  const { t } = useTranslation()

  const [value, setValue] = React.useState<IQueryProps>({
    reportType: TYPE.YEAR,
    conditionDate: new Date().getFullYear(),
    start: moment().startOf('year').valueOf(),
    end: moment().endOf('year').valueOf(),
  })

  const currentYear = React.useMemo(() => new Date().getFullYear(), [])

  const currentMonth = React.useMemo(() => new Date().getMonth() + 1, [])

  const handleDisabledDate = (current: any) => {
    return current >= moment().endOf('day')
  }

  const handleReportType = (val: any) => {
    let start = moment().startOf('day')
    let end = moment().endOf('day')
    let conditionDate: undefined | string | number = undefined
    switch (val) {
      case TYPE.YEAR:
        start = moment().startOf('year')
        end = moment().endOf('year')
        conditionDate = currentYear
        break
      case TYPE.MONTH:
        start = moment().startOf('month')
        end = moment().endOf('month')
        conditionDate = `${currentYear}/${currentMonth}`
        break
      case TYPE.DAY:
        start = moment().subtract(1, 'month').startOf('day')
        end = moment().endOf('day')
        conditionDate = undefined
    }
    setValue({ reportType: val, start, end, conditionDate })
  }

  const handleChangeYM = (val: any) => {
    const type = value.reportType === TYPE.YEAR ? 'year' : 'month'
    setValue((prev: any) => ({
      ...prev,
      start: val.startOf(type),
      end: val.endOf(type),
      conditionDate: value.reportType === TYPE.YEAR ?
        moment(val).format('YYYY') :
        `${moment(val).format('YYYY')}/${moment(val).format('M')}`,
    }))
  }

  const handleChangeDate = (val: any) => {
    const [start, end] = val ? val : []
    setValue((prev: any) => ({
      ...prev,
      conditionDate: undefined,
      start,
      end,
    }))
  }

  React.useEffect(() => {
    onChange && onChange(value)
  }, [value]) //eslint-disable-line

  return (
    <div>
      <Select
        value={value.reportType}
        allowClear={false}
        className="w-104 mr-8"
        onChange={handleReportType}>
        {Object.values(TYPE).map(i => (
          <Select.Option key={i} value={i}>
            {ReportSearchType[i]}
          </Select.Option>
        ))}
      </Select>
      {value.reportType === TYPE.YEAR ? (
        <DatePicker
          allowClear={false}
          picker="year"
          value={moment(value.start)}
          placeholder={t('report:year')}
          format="YYYY"
          className="w-130"
          disabledDate={handleDisabledDate}
          onChange={handleChangeYM}
        />
      ) : value.reportType === TYPE.MONTH ? (
        <DatePicker
          allowClear={false}
          picker="month"
          value={moment(value.start)}
          placeholder={t('report:month')}
          format="YYYY/MM"
          className="w-130"
          disabledDate={handleDisabledDate}
          onChange={handleChangeYM}
        />
      ) : (
        <RangePicker
          allowClear={false}
          value={[value.start, value.end]}
          placeholder={[t('report:startDate'), t('report:endDate')]}
          format="YYYY.MM.DD"
          className="w-264"
          disabledDate={handleDisabledDate}
          onChange={handleChangeDate}
        />
      )}
    </div>
  )
}
