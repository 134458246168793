import { SVGProps } from 'react'

const BackSVG = ({ color = '#4A4A4A', ...props }: SVGProps<any>) => (
  <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(4 4)">
        <circle stroke={color} strokeWidth="2.2" cx="10" cy="10" r="8.9"/>
        <circle fill={color} cx="10" cy="10" r="3.636"/>
      </g>
    </g>
  </svg>
)

export default BackSVG
