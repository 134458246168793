import React from 'react'
import { compose, defaultTo, isNil, map, pathOr } from 'ramda'
import { convertData } from '@shared/innmaxLib/services'
import { convertMS } from '@shared/utils/webHelper'
import env from '@shared/env'

import { DeviceTypeId } from '@services/constants'
import type { ILight, ILightDetail } from './light.types'
import { useConvertData, IPhotoDto, ILamp } from '@services/device'

export const useConvertILights = (res: any) => {
  const {
    switchLeakageStr,
    deviceStatus,
    activeStr,
    sessionName,
    expiredDate,
    installTime,
    updateTime,
    locationNs,
  } = useConvertData()

  const dataSource = React.useMemo(
    () =>
      convertData<ILight>({
        converter: x => {
          return {
            ...x,
            ...deviceStatus(x),
            ...activeStr(x),
            ...sessionName(x),
            ...expiredDate(x),
            ...installTime(x),
            ...updateTime(x),
            ...locationNs(x),
            properties: {
              ...x.properties,
              switchboxSeqStr: x.properties.switchboxSeq,
              ...switchLeakageStr(x.properties),
            },

            lampCount: pathOr([], ['properties', 'lamps'], x).length,
          }
        },
      })(res),
    [res]
  )

  return dataSource
}

export const useConvertILightDetail = (x?: ILightDetail) => {
  const {
    deviceStatus,
    activeStr,
    sessionName,
    expiredDate,
    installTime,
    updateTime,
    lastOnlineAt,
    expiredAt,
    installAt,
    switchLeakageStr,
    locationNs,
    sessionPath,
  } = useConvertData()

  return React.useMemo(() => {
    if (!x) {
      return {}
    }

    const lamps = pathOr([], ['properties', 'lamps'], x)

    return {
      ...x,
      ...deviceStatus(x),
      ...activeStr(x),
      ...sessionName(x),
      ...expiredDate(x),
      ...installTime(x),
      ...updateTime(x),
      ...locationNs(x),
      ...sessionPath(x),
      deviceTypeId: DeviceTypeId[x.deviceType],

      lampCount: lamps.length,
      properties: {
        ...x.properties,
        ...switchLeakageStr(x),
        lamps: lamps.map((i: ILamp) => {
          return {
            ...i,
            ...deviceStatus(i),
            ...activeStr(i),
            ...lastOnlineAt(i),
            deviceId: x.deviceId,
            extra: {
              ...i.extra,
              brightness: convertLampBrightness(i),
              time: convertMS(i.extra.usedSec),
            },
            properties: {
              ...i.properties,
              ...expiredAt(i.properties),
              ...installAt(i.properties),
              imgs: compose(
                map((y: IPhotoDto) => ({
                  ...y,
                  url: `${env.uploadFileUrlPrefix}${y.filePath}`,
                })),
                defaultTo([])
              )(i.properties.imgs),
            },
          }
        }),
      },
    }
  }, [x])
}

/**
 * brightnessCtrl 有值時，使用 brightnessCtrl
 * @param lamp
 * @returns
 */
function convertLampBrightness(lamp: ILamp) {
  return defaultTo(
    0,
    !isNil(lamp?.extra?.brightnessCtrl)
      ? lamp?.extra?.brightnessCtrl
      : lamp?.extra?.brightness
  )
}
