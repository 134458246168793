import React from 'react'
import i18n from 'i18next'
import styled from 'styled-components'
import { isNil } from 'ramda'
import { Badge } from 'antd'
import { PresetStatusColorType } from 'antd/lib/_util/colors'
import { BadgeProps } from 'antd/lib/badge'
import theme from '@theme'
import {
  DeviceStatus,
  DeviceStatusStr,
} from '@services/constants/devices'

export interface Props extends Omit<BadgeProps, 'status'> {
  status: DeviceStatus | PresetStatusColorType
  showText: boolean
  dotsize?: number
  border?: boolean
  size?: 'small' | 'default'
}

const StyledBadge = styled(({
  status,
  color,
  title,
  text = '',
  count,
  showText = false,
  border = false,
  ...props
}) => {
  const _color = (
    status === DeviceStatus.NORMAL ? theme.n.success :
    status === DeviceStatus.REPAIR ? theme.n.gray250 :
    status === DeviceStatus.ALARM ? theme.n.danger :
    color
  )

  const _title = title || text || i18n.t(`device:${DeviceStatusStr[status]}`)

  return (
    <Badge
      title={_title}
      color={!isNil(count) ? undefined : _color}
      text={showText && _title}
      count={count}
      style={isNil(count) ? {} : { backgroundColor: _color }}
      {...props}
    />
  )
})<Props>`
  .ant-badge-status-dot {
    width: ${p => p.dotsize || 10}px;
    height: ${p => p.dotsize || 10}px;
    border: ${p => p.border && '1px solid #fff'};
  }

  .ant-badge-status-text {
    color: ${theme.btn.text};
  }

  ${p => p.size === 'small' && p.count && `
    .ant-badge-count-sm {
      padding: 0 5px;
      font-size: 11px;
      line-height: 16px;
      height: 16px;
      box-shadow: none;
    }
  `}
`

export default StyledBadge
