import React from 'react'
import { useTranslation } from 'react-i18next'
import { always, cond, equals, isNil, propEq, T } from 'ramda'
import { Select, SelectProps } from '@shared/Form/Select'

export const DeviceStatusSelect = (props: SelectProps<any>) => {
  const { onChange, value, ...others } = props

  const { t } = useTranslation()

  const handleOnChange = (value: any, option: any) => {
    if (!onChange) {
      return
    }
    onChange(
      cond<any, Record<string, boolean> | undefined>([
        [isNil, always(undefined)],
        [
          equals('isNormal'),
          always({
            isNormal: true,
          }),
        ],
        [
          equals('isAlarm'),
          always({
            isAlarm: true,
          }),
        ],
        [equals('isRepair'), always({ isRepair: true })],
      ])(value),
      option
    )
  }

  const selectValue = React.useMemo(
    () =>
      cond<any, string | undefined>([
        [isNil, always(undefined)],
        [propEq('isNormal', true), always('isNormal')],
        [propEq('isAlarm', true), always('isAlarm')],
        [propEq('isRepair', true), always('isRepair')],
        [T, always('isAll')],
      ])(value),
    [value]
  )

  return (
    <Select
      placeholder={t('light:deviceStatus')}
      onChange={handleOnChange}
      value={selectValue}
      {...others}>
      <Select.Option value="isAll">{t('common:all')}</Select.Option>
      <Select.Option value="isNormal">{t('light:normal')}</Select.Option>
      <Select.Option value="isAlarm">{t('light:alarm')}</Select.Option>
      <Select.Option value="isRepair">{t('light:repair')}</Select.Option>
    </Select>
  )
}

export default DeviceStatusSelect
