import React, { SVGProps } from 'react'
const AfforestationBenefits = (props: SVGProps<any>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.8334 15.5555V37.5"
      stroke="#FFCC2B"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.8679 30.8783H31.1635C34.6632 30.8783 37.5 27.5799 37.5 23.5107C37.5 19.4415 34.6632 16.1431 31.1635 16.1431C31.1499 16.1431 31.1363 16.145 31.1227 16.145C30.7732 12.4607 27.8458 9.6283 24.5834 9.6283C21.321 9.6283 18.881 12.4607 18.5315 16.145"
      stroke="#FFCC2B"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.7874 21.7778L25.8334 24.8889"
      stroke="#FFCC2B"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.8794 21.7778L25.8334 24.8889"
      stroke="#FFCC2B"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 29.5555C6.41724 29.5555 2.5 25.9159 2.5 21.4256C2.5 16.9353 11.25 4.55554 11.25 4.55554C11.25 4.55554 20 16.9353 20 21.4256C20 25.0661 17.4247 28.1469 13.875 29.1833L11.638 29.5555"
      fill="#FFCC2B"
    />
    <path
      d="M11.25 29.5555C6.41724 29.5555 2.5 25.9159 2.5 21.4256C2.5 16.9353 11.25 4.55554 11.25 4.55554C11.25 4.55554 20 16.9353 20 21.4256C20 25.0661 17.4247 28.1469 13.875 29.1833L11.638 29.5555"
      stroke="#FFCC2B"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.115 14.7777V37.4999"
      stroke="#FFCC2B"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.30749 21.7778L10.115 25.6667"
      stroke="#003248"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.161 17.8888L10.115 20.9999"
      stroke="#003248"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.5834 30.8782H17.7299"
      stroke="#FFCC2B"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.115 14.7777V27.34"
      stroke="#003248"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default AfforestationBenefits
