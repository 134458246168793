import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'ramda'
import cx from 'classnames'
import styled from 'styled-components'
import { createPortal } from 'react-dom'
import { useGoogleMap } from '@react-google-maps/api'
import { SearchOutlined, CloseCircleFilled } from '@ant-design/icons'
import Autocomplete from 'react-google-autocomplete'
import { Button } from '@shared/innmaxUI/Form'
import env from '@shared/env'

// Currently supported only for GoogleMap

export type SearchControlProps = {
  position?: google.maps.ControlPosition
  styles?: React.CSSProperties
  onPlaceSelected: (
    place: google.maps.places.PlaceResult,
  ) => void
}

function PlaceSearchControl({
  position = window.google.maps.ControlPosition.TOP_RIGHT,
  styles,
  onPlaceSelected,
}: SearchControlProps) {
  const { i18n, t } = useTranslation()

  const map: google.maps.Map | null = useGoogleMap()

  const [container] = React.useState(document.createElement('div'))

  const [open, setOpen] = React.useState(false)

  const [value, setValue] = React.useState('')

  const autocompleteRef: any = React.useRef(null)

  React.useEffect(() => {
    const handleElementClass = (evt: any) => {
      !(evt?.composedPath().includes(document.getElementById('google-map-autocomplete'))) &&
      setOpen(false)
    }
    document.addEventListener('click', handleElementClass)
    return () => document.removeEventListener('click', handleElementClass)
  }, [])

  React.useEffect(() => {
    const controlsContainer: any = map?.controls[position]
    controlsContainer.push(container)
    return () => {
      const index = controlsContainer.indexOf(container);
      if (index !== -1) {
        controlsContainer.removeAt(index);
      }
    }
  }, [map]) // eslint-disable-line

  React.useEffect(() => {
    for (const [key, value] of Object.entries(styles as any)) {
      (container.style as any)[key] = value
    }
  }, [styles]) // eslint-disable-line

  const handleClearInput = () => {
    if (autocompleteRef && autocompleteRef.current) {
      (autocompleteRef?.current as any).value = ''
      setValue('')
    }
  }

  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (map && e.key === 'Enter' && !isEmpty(e.currentTarget.value.trim())) {
      const service = new google.maps.places.PlacesService(map)
      service.findPlaceFromQuery({
        query: e.currentTarget.value.trim(),
        fields: ['geometry'],
      }, (results, status) => {
        if (status === 'OK' && results && Array.isArray(results)) {
          onPlaceSelected(results[0])
        }
      })
    }
  }

  return createPortal((
    <StyledControl
      id="google-map-autocomplete"
      className={cx({'active': open})}>
      <Autocomplete
        ref={autocompleteRef}
        apiKey={env.googleMapsApiKey}
        options={{
          fields: ['geometry'],
          types: ['establishment'],
          strictBounds: false,
          language: i18n.language,
          componentRestrictions: { country: 'tw' },
        }}
        placeholder={t('dashboard:search place')}
        onPlaceSelected={onPlaceSelected}
        onChange={(e: any) => setValue(e.target?.value)}
        onKeyPress={handleOnKeyPress}
      />
      {
        value && (
          <StyledClearButton type="icon" onClick={handleClearInput}>
            <span className="ant-input-clear-icon">
              <CloseCircleFilled/>
            </span>
          </StyledClearButton>
        )
      }
      <button title={t('dashboard:search place')} onClick={() => setOpen(!open)}>
        <SearchOutlined />
      </button>
    </StyledControl>
  ), container)
}

const StyledControl = styled.div`
  display: flex;
  flex-direction: row;
  user-select: none;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  width: 32px;
  min-height: 32px;
  overflow: hidden;
  position: relative;
  transition: width .2s;

  &.active {
    width: 240px;
  }

  input {
    flex: 1;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    padding-left: 16px;
    padding-right: 89px;
    font-size: 15px;
  }

  button {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    min-height: 32px;
    color: #fff;
    background-color: #0074a8;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 30%);
    position: absolute;
    right: 0;

    &.active,
    &:hover {
      background-color: #ffe288;
      color: #0074a8;
    }
  }
`

const StyledClearButton = styled(Button)`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 38px !important;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  box-shadow: none !important;
  background-color: transparent !important;
  min-height: auto !important;
  min-width: auto !important;

  > span {
    line-height: 0 !important;

    span {
      margin-right: 0 !important;

      svg {
        width: 12px !important;
      }
    }
  }
`

export default React.memo(PlaceSearchControl)