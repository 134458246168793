const ImportFile = ({ color = '#fff', ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M3.398 6.892a.56.56 0 0 0 .56-.56V3.149c0-.16.13-.29.29-.29h8.86c.16 0 .29.13.29.29v9.174c0 .16-.13.29-.29.29h-3.98a.56.56 0 0 0 0 1.12h3.978c.777 0 1.408-.632 1.408-1.409V3.148c0-.775-.631-1.406-1.408-1.406h-8.86c-.777 0-1.409.631-1.409 1.408v3.184a.563.563 0 0 0 .561.558zm5.841 3.285a5.67 5.67 0 0 0 .853-2.124.56.56 0 0 0-.553-.639.564.564 0 0 0-.553.475 4.653 4.653 0 0 1-.695 1.695c-.724 1.07-1.805 1.614-3.214 1.62-.022-.002-.041-.004-.057-.004a.56.56 0 0 0-.56.56v.657l-1.57-1.494 1.573-1.309v.534a.56.56 0 0 0 1.118 0V8.42a.561.561 0 0 0-.915-.43l-2.97 2.474a.558.558 0 0 0-.2.411.559.559 0 0 0 .173.425l2.968 2.825a.555.555 0 0 0 .943-.405V12.3c1.964-.164 3.073-1.242 3.66-2.123z" 
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  )
}

export default ImportFile
