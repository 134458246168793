import React from 'react'
import { compose, path, pathOr, propEq, find } from 'ramda'
import { useLazyQuery, QueryVariables } from '@shared/innmaxLib/services'
import {
  ICameraCtrlerType,
  ICameraCtrlerTypeColumns,
  Streaming,
} from './camera.types'
import { useQuery } from 'react-query'

export const useLiveStreaming = (options: QueryVariables = {}) => {
  const { refetch, data, ...others } = useLazyQuery(
    '/device/camera/{id}/streaming',
    options
  )

  const dataSource = React.useMemo(() => {
    return pathOr<Streaming | undefined>(undefined, ['data'], data)
  }, [data])

  return {
    ...others,
    loading: others.isLoading,
    queryLiveStreaming: refetch,
    dataSource,
  }
}

export const useCameraCtrlerType = (options: QueryVariables = {}) => {
  const { data, ...others } = useQuery('/ctrler/types/camera', options)

  const dataSource = React.useMemo(() => {
    return pathOr<ICameraCtrlerType[]>([], ['data'], data).map(type => ({
      ...type,
      columns: type.columns.map((column: any) => ({
        ...column,
        required: !column.opt,
      })),
    }))
  }, [data])

  const getCameraCtrlerTypeColumnsByType = React.useCallback(
    (type: string) => {
      if (!type) {
        return []
      }

      const columns: ICameraCtrlerTypeColumns[] = compose<any, any, any>(
        pathOr([], ['columns']),
        find(propEq('ctrlerType', type))
      )(dataSource)
      return columns
    },
    [dataSource]
  )

  return {
    getCameraCtrlerTypeColumnsByType,
    ...others,
    loading: others.isLoading,
    dataSource,
  }
}
