import { SVGProps } from 'react'

const TemperatureSVG = (props: SVGProps<any>) => (
  <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h96v96H0z" />
      <path
        d="M47.922 84.263c-8.886 0-16.116-7.342-16.116-16.365 0-5.56 2.75-10.692 7.352-13.729a2.477 2.477 0 0 0 1.107-2.069V18.607c0-4.289 3.434-7.778 7.657-7.778 4.224 0 7.66 3.49 7.66 7.778V52.1c0 .836.416 1.613 1.106 2.07 4.603 3.036 7.352 8.169 7.352 13.728 0 9.023-7.23 16.365-16.118 16.365m12.519-33.45V18.606c0-7.01-5.616-12.712-12.52-12.712-6.901 0-12.515 5.702-12.515 12.712v32.205c-5.321 4.016-8.459 10.306-8.459 17.086 0 11.744 9.41 21.299 20.975 21.299 11.567 0 20.977-9.555 20.977-21.299 0-6.779-3.137-13.07-8.458-17.086"
        fill="#FFF"
      />
      <path
        d="m54.54 57.525-2.899-1.914V33.497h-7.435v22.114l-2.9 1.914c-3.48 2.297-5.557 6.175-5.557 10.373 0 6.817 5.461 12.363 12.173 12.363 6.714 0 12.176-5.546 12.176-12.363 0-4.198-2.078-8.076-5.558-10.373"
        fill="#FFD63C"
      />
    </g>
  </svg>
)

export default TemperatureSVG
