const Talkie = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#0074A8">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <path d="M9.975 2.667c2.96 0 5.358 2.387 5.358 5.333s-2.399 5.333-5.358 5.333h-.714c-1.517 0-2.886-.627-3.86-1.634L1 11.422c-.197-.013-.346-.182-.334-.378.006-.093.05-.18.12-.243l3.116-2.757V8c0-2.946 2.399-5.333 5.358-5.333h.714z" transform="translate(-323 -822) translate(184 596) translate(0 58) translate(8) translate(0 160) translate(12 7) translate(119 1)"/>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Talkie
