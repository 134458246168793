import { atom } from 'recoil'
import { Device } from '@services/device'

export const MapActiveMarkersState = atom<Device[]>({
  key: 'InnmaxMapActiveMarkersState',
  default: [],
})

export const MapActiveMarkerState = atom<Device | undefined>({
  key: 'InnmaxMapActiveMarkerState',
  default: undefined,
})