import { SVGProps } from 'react'
import theme from '@theme'

const Media = ({ color = theme.grey, ...props }: SVGProps<any>) => {
  return (
    <i className="icon-media" {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg">
        <g stroke={color} strokeWidth="1.5" fill="none" fillRule="evenodd">
          <path d="M4 2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2z" />
          <path d="M7.823 6.523c.22-.073.261-.063.298-.042l5.941 3.3a.25.25 0 0 1 0 .438l-5.94 3.3a.25.25 0 0 1-.372-.219V6.7a.25.25 0 0 1 .073-.177z" />
        </g>
      </svg>
    </i>
  )
}

export default Media
