import React from 'react'
import styled from 'styled-components'
import { Switch } from 'antd'
import { SwitchProps as AntSwitchProps } from 'antd/lib/switch'

interface StyledSwitchProps extends AntSwitchProps {
  forwardRef?: React.Ref<any>
  ref?: any
}

export interface SwitchProps extends StyledSwitchProps {
  render?: (values: any) => any
  useForm?: boolean
}

const StyledSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: ${p => p.theme.primary};
  }
`

export default class SwitchComponent extends React.Component<
  StyledSwitchProps,
  any
> {
  public static displayName = 'Switch'

  render() {
    return <StyledSwitch {...this.props}>{this.props.children}</StyledSwitch>
  }
}

export { SwitchComponent as Switch }
