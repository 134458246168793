import React from 'react'
import { useRecoilState } from 'recoil'
import { MapActiveMarkersState, MapActiveMarkerState } from './map.state'

import { Device } from '@services/device'

type ContextType = {
  handleActiveMarkers: (devices: Device[]) => void
  handleActiveMarker: (device: Device | undefined) => void
}

const MapContext = React.createContext<ContextType>({
  handleActiveMarkers: (devices: Device[]) => {},
  handleActiveMarker: (device: Device | undefined) => {},
})

export function MapProvider (props: any) {
  const [, handleActiveMarkers] = useRecoilState(MapActiveMarkersState)

  const [, handleActiveMarker] = useRecoilState(MapActiveMarkerState)

  const value = React.useMemo(() => {
    return {
      handleActiveMarkers,
      handleActiveMarker,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <MapContext.Provider value={value} {...props} />
}

export function useMap() {
  const context = React.useContext(MapContext)
  if (!context) {
    throw new Error('useMap must be within a MapProvider')
  }

  return context
}

export { MapActiveMarkersState, MapActiveMarkerState } from './map.state'

export default MapProvider