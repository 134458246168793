import React from 'react'
import { useMapLibre } from '../Provider'
import styled from 'styled-components'
import { Tooltip } from '@shared/innmaxUI'
import ToogleIcon from './ToogleIcon'
import { Dropdown } from '@shared/innmaxUI/Dropdown'

const LayerToogle = React.memo(() => {
  const { map, setMapMode } = useMapLibre()

  return (
    <div>
      <Tooltip title="圖層切換" placement="left">
        <StyledControl
          id="filter-control"
          className="leaflet-control leaflet-bar">
          <Dropdown
            placement="bottomRight"
            menu={[
              {
                key: 'laser',
                label: '2D 街景圖',
                onClick: () => {
                  setMapMode('2D')
                  map?.removeLayer('photo-tiles')
                },
                style: { textAlign: 'center' },
              },
              {
                key: 'photo',
                label: '2D 正射圖',
                onClick: () => {
                  setMapMode('2D')
                  map?.addLayer({
                    id: 'photo-tiles',
                    type: 'raster',
                    source: 'twtxPhoto',
                    minzoom: 0,
                    maxzoom: 24,
                  })
                },
                style: { textAlign: 'center' },
              },
              {
                key: '3Dlaser',
                label: '3D 街景圖',
                onClick: () => {
                  setMapMode('3D')
                  map?.removeLayer('photo-tiles')
                },
                style: { textAlign: 'center' },
              },
              {
                key: '3Dphoto',
                label: '3D 正射圖',
                onClick: () => {
                  setMapMode('3D')
                  map?.addLayer({
                    id: 'photo-tiles',
                    type: 'raster',
                    source: 'twtxPhoto',
                    minzoom: 0,
                    maxzoom: 24,
                  })
                  map?.moveLayer('3dTiles')
                },
                style: { textAlign: 'center' },
              },
            ]}>
            <ToogleIcon />
          </Dropdown>
        </StyledControl>
      </Tooltip>
    </div>
  )
})

const StyledControl = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  min-height: 32px;
  user-select: none;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #084ef8;

  &:hover {
    background-color: #ffcc2b;
    color: #000000;

    svg path {
      stroke: #000000;
    }
  }
`

export default LayerToogle
