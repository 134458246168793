import { useQuery } from 'react-query'
import { QueryVariables } from '@shared/innmaxLib/services'
import { isNil } from '@shared/utils/webHelper'

export const useLightDailyTotalPower = (querys: { [key: string]: any }, options?: QueryVariables) => {
  const { data, ...others } = useQuery(['/device/streetlight/report/dailyTotalPower', querys], {
    enabled: !isNil(querys),
    ...options,
  })

  const dataSource: any = data

  return {
    dataSource,
    ...others,
  }
}

