import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import styled from 'styled-components'
import { createPortal } from 'react-dom'
import { useGoogleMap } from '@react-google-maps/api'
import LayerIcon from './LayerIcon'

// Currently supported only for GoogleMap

export type LayerControlProps = {
  position?: google.maps.ControlPosition
  styles?: React.CSSProperties
  onClick?: () => void
  active?: boolean
  icon?: React.ReactNode
  iconColor?: string
}

function LayerControl({
  position = window.google.maps.ControlPosition.RIGHT_TOP,
  styles = {},
  onClick,
  active,
  icon,
  iconColor = '#fff',
}: LayerControlProps) {
  const { t } = useTranslation()

  const map: google.maps.Map | null = useGoogleMap()

  const [container] = React.useState(document.createElement('div'))

  React.useEffect(() => {
    const controlsContainer: any = map?.controls[position]
    controlsContainer.push(container)
    return () => {
      const index = controlsContainer.indexOf(container);
      if (index !== -1) {
        controlsContainer.removeAt(index);
      }
    }
  }, [map]) // eslint-disable-line

  React.useEffect(() => {
    for (const [key, value] of Object.entries(styles as any)) {
      (container.style as any)[key] = value
    }
  }, [styles]) // eslint-disable-line

  return createPortal((
    <StyledControl id="filter-control">
      <button
        title={t('dashboard:search device')}
        onClick={onClick}
        className={cx({ 'active': active })}>
        {icon ? icon : <LayerIcon color={iconColor}/>}
      </button>
    </StyledControl>
  ), container)
}

const StyledControl = styled.div`
  display: flex;
  flex-direction: row;
  user-select: none;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 4px;
  cursor: pointer;
  width: 32px;
  min-height: 32px;

  button {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    min-height: 32px;
    // color: #fff;
    // background-color: #0074a8;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 30%);
    position: absolute;
    right: 0;

    &.active,
    &:hover {
      background-color: #ffe288;
      color: #0074a8;

      svg path {
        fill: #0074a8;
      }
    }
  }
`

export default React.memo(LayerControl)