import maplibregl, { Marker } from 'maplibre-gl'
import React from 'react'
import { useMapLibre } from '../Provider'
import ReactDOM from 'react-dom/client'
import { useMapLibreMarker } from '../Marker/MarkerProvider'

type ITooltipProps = {}

const Tooltip = React.memo((props: React.PropsWithChildren<ITooltipProps>) => {
  const { marker } = useMapLibreMarker()
  const { children } = props

  const { map } = useMapLibre()

  React.useEffect(() => {
    if (!marker || !map) {
      return
    }

    const tooltipPopup = new maplibregl.Popup({
      className: 'maplibregl-innmax-tooltip',
      closeButton: false,
      closeOnClick: false,
    })

    if (typeof children === 'string') {
      tooltipPopup.setText(children)
    } else {
      const root = document.createElement('div')
      ReactDOM.createRoot(root).render(children)
      tooltipPopup.setDOMContent(root)
    }

    const handleMouseEnter = () => {
      tooltipPopup
        .setOffset({
          bottom: [
            0,
            0 - marker.getElement().getBoundingClientRect().height / 2,
          ],
        } as any)
        .setLngLat(marker.getLngLat())
        .addTo(map)
    }

    const handleMouseLeave = () => {
      tooltipPopup.remove()
    }

    marker.getElement().addEventListener('mouseenter', handleMouseEnter)
    marker.getElement().addEventListener('mouseleave', handleMouseLeave)

    return () => {
      tooltipPopup.remove()
      marker.getElement().removeEventListener('mouseenter', handleMouseEnter)
      marker.getElement().removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [children, map, marker])

  return <></>
})

export default Tooltip
