import InternalLeafletMap from './Leaflet'
import { LeafletProvider, useLeaflet } from './LeafletProvider'
import Marker from './Marker'
import AreaMarker from './AreaMarker'
import Tooltip from './Tooltip'

type InternalLeafletMapType = typeof InternalLeafletMap

interface LeafletMapInterface extends InternalLeafletMapType {
  Provider: typeof LeafletProvider
  Marker: typeof Marker
  AreaMarker: typeof AreaMarker
  useLeafletMap: typeof useLeaflet
  Tooltip: typeof Tooltip
}

const LeafletMap = InternalLeafletMap as LeafletMapInterface

LeafletMap.Provider = LeafletProvider
LeafletMap.Marker = Marker
LeafletMap.AreaMarker = AreaMarker
LeafletMap.useLeafletMap = useLeaflet
LeafletMap.Tooltip = Tooltip

export default LeafletMap
