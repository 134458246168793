import { SVGProps } from 'react'

const CloseSVG = ({ color = '#fff', ...props }: SVGProps<any>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.215 6.397a4.194 4.194 0 0 0-.027-1.014l.768-.343a5.072 5.072 0 0 0-.927-2.082L9.26 3.3a4.208 4.208 0 0 0-.735-.698l.3-.784A5.09 5.09 0 0 0 6.699 1l-.302.786a4.195 4.195 0 0 0-1.014.026l-.34-.764c-.76.15-1.47.472-2.08.932l.338.76c-.26.213-.494.46-.699.735l-.783-.3A4.99 4.99 0 0 0 1 5.302l.785.3c-.033.342-.023.683.027 1.015l-.768.342A5.068 5.068 0 0 0 1.97 9.04l.768-.342c.214.259.46.494.736.698l-.301.785A5.078 5.078 0 0 0 5.302 11l.301-.786c.342.033.682.023 1.014-.026l.34.763a5.077 5.077 0 0 0 2.081-.932l-.338-.76c.259-.213.494-.46.698-.735l.783.3A4.995 4.995 0 0 0 11 6.698l-.785-.301zm-2.087.42a2.28 2.28 0 1 1-4.257-1.635 2.28 2.28 0 0 1 4.257 1.634z" 
      fill={color}
      fillRule="evenodd"
    />
  </svg>
)

export default CloseSVG
