import { useQuery } from 'react-query'

const useExternalQuery = ({ key, url }: { key: any; url: string }) => {
  const fetchData = async () => {
    const response = await fetch(url)
    return response.json()
  }
  const { data, isLoading, ...rest } = useQuery(key, fetchData)
  return {
    ...rest,
    data,
    isLoading,
  }
}

export default useExternalQuery
