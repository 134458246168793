import { SVGProps } from 'react'

const LayerSVG = ({ color = '#FFF', ...props }: SVGProps<any>) => (
  <svg width="24" height="24" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <path fill={color} d="M856.55552 337.67424L525.5168 147.456a46.24384 46.24384 0 0 0-46.2848 0L145.44896 339.3536a35.38944 35.38944 0 0 0 0 61.44L479.232 592.6912a46.24384 46.24384 0 0 0 46.12096 0l331.07968-190.34112a37.31456 37.31456 0 0 0 0.12288-64.67584z m-616.0384 32.3584l253.46048-143.68768a17.16224 17.16224 0 0 1 16.95744 0l253.46048 143.64672-253.46048 143.64672a17.16224 17.16224 0 0 1-16.95744 0z" />
    <path fill={color} d="M784.1792 495.98464l-273.2032 154.78784a17.16224 17.16224 0 0 1-16.9984 0l-309.98528-177.152a35.38944 35.38944 0 0 0-35.75808 0.36864l-4.096 2.4576a35.38944 35.38944 0 0 0 0.57344 60.98944l334.68416 192.512a46.24384 46.24384 0 0 0 46.12096 0l331.776-190.79168a37.31456 37.31456 0 0 0 0.6144-64.34816 37.31456 37.31456 0 0 0-38.37952 0z"  />
    <path fill={color} d="M784.1792 630.49728l-277.42208 157.20448a17.16224 17.16224 0 0 1-16.9984 0l-306.74944-175.3088a35.38944 35.38944 0 0 0-33.3824-0.94208 35.38944 35.38944 0 0 0-1.80224 62.30016L479.232 864.256a46.24384 46.24384 0 0 0 46.12096 0l331.776-190.79168a37.31456 37.31456 0 0 0 0.6144-64.34816 37.31456 37.31456 0 0 0-38.37952 0z" />
  </svg>
)

export default LayerSVG
