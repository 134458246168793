import { SVGProps } from 'react'

const PM10SVG = (props: SVGProps<any>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <path d="M.615.29h19.21v19.206H.616z"/>
      <path d="M10.22 17.496a7.603 7.603 0 1 0 0-15.205 7.603 7.603 0 1 0 0 15.205zM6.972 9.24c.12 0 .232-.025.334-.075.101-.05.152-.124.152-.224V7.595h.618c.287 0 .547-.048.78-.143.233-.096.417-.25.552-.462.135-.212.202-.493.202-.842v-.03c0-.35-.065-.63-.196-.84a1.128 1.128 0 0 0-.53-.455 1.906 1.906 0 0 0-.746-.14h-1.29a.383.383 0 0 0-.263.087.266.266 0 0 0-.1.206v3.966c0 .1.051.175.153.224.102.05.213.075.334.075zm1.104-2.394h-.618V5.53h.618a.55.55 0 0 1 .412.153c.1.102.15.258.15.47v.07c0 .211-.05.368-.15.47a.55.55 0 0 1-.412.153zm5.489 2.394c.12 0 .232-.025.334-.075.102-.05.153-.124.153-.224V5.169c0-.179-.05-.304-.15-.377a.558.558 0 0 0-.337-.11c-.116 0-.215.01-.296.029a.465.465 0 0 0-.225.13 1.663 1.663 0 0 0-.234.328l-.642 1.123-.643-1.123a1.444 1.444 0 0 0-.23-.327.483.483 0 0 0-.228-.131 1.34 1.34 0 0 0-.296-.028.562.562 0 0 0-.334.109c-.102.073-.153.198-.153.377v3.773c0 .1.051.175.153.224.102.05.213.075.334.075.12 0 .231-.025.333-.075.102-.05.153-.124.153-.224v-2.4l.593 1.084c.037.07.084.119.14.144a.405.405 0 0 0 .165.037.412.412 0 0 0 .175-.04.356.356 0 0 0 .15-.14l.598-1.024v2.339c0 .1.051.175.153.224.102.05.213.075.334.075zm-5.558 5.522c.325 0 .542-.211.542-.539V10.94c0-.376-.23-.61-.623-.61-.214 0-.399.023-.628.178l-.814.554c-.154.107-.217.23-.217.387 0 .22.158.378.37.378.104 0 .17-.023.268-.092l.536-.372h.02v2.862c0 .328.221.54.546.54zm3.852.039c1.138 0 1.841-.864 1.841-2.267 0-1.412-.71-2.249-1.841-2.249-1.13 0-1.845.84-1.845 2.252 0 1.406.707 2.264 1.845 2.264zm0-.852c-.453 0-.733-.473-.733-1.415 0-.935.28-1.397.733-1.397.453 0 .73.459.73 1.4 0 .939-.274 1.412-.73 1.412z" fill="#FFD63C"/>
    </g>
  </svg>
)

export default PM10SVG
