import React from 'react'
import cx from 'classnames'
import { TimePicker } from 'antd'
import { TimePickerProps } from 'antd/lib/time-picker'
import { RefProps } from '@lib/types'

type Props = TimePickerProps & RefProps

const StyledTimePicker = React.forwardRef<typeof TimePicker, Props>(
  ({ className = '', ...props }, ref) => (
    <TimePicker
      // forwardRef={ref}
      className={cx('c-timepicker', className)}
      {...props}
    />
  )
)

StyledTimePicker.displayName = 'TimePicker'

export default StyledTimePicker
