import React from 'react'
import cx from 'classnames'
import { DatePicker } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker'
import { RefProps } from '@lib/types'

type Props = RangePickerProps & RefProps

const RangePicker = React.forwardRef<typeof DatePicker.RangePicker, Props>(
  ({ className = '', ...props }, ref) => (
    <DatePicker.RangePicker
      forwardRef={ref}
      className={cx('c-datepicker', className)}
      {...props}
    />
  )
)

export default RangePicker
