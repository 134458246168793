import { Select, SelectProps } from '@shared/Form/Select'
import { useRepairers } from '@services/repairer'
import { useTranslation } from 'react-i18next'

export default function RepairerSelect({ ...props }: SelectProps) {
  const { dataSource, isLoading } = useRepairers()

  const { t } = useTranslation()

  return (
    <Select allowClear placeholder={t('repair:repairer name')} loading={isLoading} {...props}>
      {dataSource?.content?.map(x => (
        <Select.Option key={x.id} value={x.id}>
          {x.name}
        </Select.Option>
      ))}
    </Select>
  )
}
