import { useTranslation } from 'react-i18next'
import { Select, SelectProps } from '@shared/Form/Select'
import { useCloseReason } from '@services/option'

export default function CloseReasonSelect(props: SelectProps) {
  const { t } = useTranslation()

  const { dataSource, loading } = useCloseReason()

  return (
    <Select
      {...props}
      allowClear
      showSearch
      placeholder={t('repair:closed reason')}
      loading={loading}
      options={dataSource?.content?.map((i: any) => ({ value: i.code, label: i.code }))}>
    </Select>
  )
}
