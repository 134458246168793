import { useTranslation } from 'react-i18next'
import React from 'react'
import { pathOr, join, pluck, isNil, apply, map, defaultTo } from 'ramda'
import { momentToString, toMoment } from '@shared/utils/webHelper'
import moment from 'moment'
import env from '@shared/env'

import { Device, ILamp } from '@services/device'
import {
  DeviceType,
  DeviceStatus,
  DeviceStatusStr,
  DeviceActive,
  DeviceActiveStr,
  DeviceTypeId,
  CameraType,
} from '@services/constants'
import { useAllSessions } from '@services/session'

import {
  STREETLIGHT_MANAGEMENT,
  CAMERA_MANAGEMENT,
  WDETECT_MANAGEMENT,
  SWITCHBOX_MANAGEMENT,
  TALKIE_MANAGEMENT,
  MAP_MANAGEMENT,
  MEDIA_SCREEN_MANAGEMENT,
  WEATHER_MANAGEMENT,
  POWERSUPPLIER_MANAGEMENT,
} from 'src/routes'
import { ISubDevice } from './subdevice'
import { MapType } from '@services/constants/map'
const { compose } = require('ramda')

export const useDeviceConverter = () => {
  const { t } = useTranslation()

  const {
    expiredDate,
    activeStr,
    sessionName,
    deviceStatus,
    installTime,
    lastOnlineAt,
    installAt,
    brightnessStr,
    brightnessType,
    cameraTypeStr,
    cameraStreamingUrl,
    gps,
    expiredAt,
    convertDetailPageUrl,
    convertDevicesAttachedOnLight,
    convertContentName,
    convertProperties,
    sessionPath,
    locationNs,
    isPositioned,
  } = useConvertData()

  const convertSubLamp = (lamp: any) => ({
    ...lamp,
    deviceType: DeviceType.LAMP_CTLER,
    deviceTypeStr: t(`device:${DeviceType.LAMP_CTLER}`),
    ...deviceStatus(lamp),
    ...brightnessStr(lamp),
    ...activeStr(lamp),
    ...lastOnlineAt(lamp),
    properties: {
      ...lamp?.properties,
      ...expiredAt(lamp?.properties),
      ...installAt(lamp?.properties),
    },
  })

  const convertSubDevices = (device: Device) => {
    const items: { [D in DeviceType | string]: Device[] } = {}
    if (device?.properties?.lamps?.length) {
      items[DeviceType.LAMP_CTLER] = []
      for (const lamp of device.properties.lamps) {
        items[DeviceType.LAMP_CTLER].push(convertSubLamp(lamp as any))
      }
    }
    if (device.subDevices) {
      for (const d of device.subDevices || []) {
        if (!items[d.deviceType]) {
          items[d.deviceType] = []
        }
        items[d.deviceType].push({
          ...d,
          deviceTypeStr: t(`device:${d.deviceType}`),
          ...deviceStatus(d),
          ...activeStr(d),
        } as Device)
      }
    }
    return {
      ...(Object.keys(items).length
        ? {
            attachedDevices: items,
          }
        : {}),
    }
  }

  return (d: Device) => ({
    ...d,
    deviceTypeStr: t(`device:${d.deviceType}`),
    ...deviceStatus(d),
    ...sessionName(d),
    ...activeStr(d),
    ...gps(d),
    ...brightnessType(d), //給marker render使用
    ...cameraTypeStr(d),
    ...cameraStreamingUrl(d),
    ...expiredDate(d),
    ...installTime(d),
    ...lastOnlineAt(d),
    ...convertDevicesAttachedOnLight(d),
    ...convertSubDevices(d),
    ...convertDetailPageUrl(d),
    ...sessionPath(d),
    ...locationNs(d),
    ...isPositioned(d),
    deviceTypeId: DeviceTypeId[d.deviceType],
    parentDeviceTypeStr: t(`device:${d?.parentDeviceType}`),
    ...(d.properties && convertProperties(d)),
    ...(d?.deviceType === DeviceType.DISPLAYSCREEN && convertContentName(d)),
  })
}

export const useConvertIDevice = (x: any) => {
  const { t } = useTranslation()
  const {
    expiredDate,
    installTime,
    lastOnlineAt,
    sessionName,
    deviceStatus,
    cameraTypeStr,
    cameraStreamingUrl,
    activeStr,
    updateTime,
    powerSupplierOutlets,
    locationNs,
    sessionPath,
  } = useConvertData()

  return React.useMemo(() => {
    if (!x) {
      return {}
    }

    return {
      data: {
        ...x,
        ...cameraTypeStr(x),
        ...cameraStreamingUrl(x),
        ...deviceStatus(x),
        ...activeStr(x),
        ...sessionName(x),
        ...expiredDate(x),
        ...installTime(x),
        ...lastOnlineAt(x),
        ...updateTime(x),
        ...locationNs(x),
        ...sessionPath(x),
        extra: {
          ...x?.extra,
          outlets: powerSupplierOutlets(x?.extra?.outlets),
        },
        deviceTypeId: DeviceTypeId[x.deviceType],
        deviceTypeStr: x.deviceType && t(`device:${x.deviceType}`),
        isSubDevice: x.parentDeviceSeq ? true : false,
      },
    }
  }, [x])
}

export const useConvertISubDevice = (x: any) => {
  const { deviceStatus } = useConvertData()
  const { t } = useTranslation()

  return React.useMemo(() => {
    if (!x) {
      return []
    }

    return compose(
      map((x: ISubDevice) => ({
        ...x,
        seq: `${x.deviceType}-${x.id}`,
        deviceTypeStr: t(`device:${x.deviceType}`),
        ...deviceStatus(x),
      }))
    )(x)
  }, [x]) as ISubDevice[]
}

/**
 * 共用各設備轉換資料格式的功能
 */
export const useConvertData = () => {
  const { t } = useTranslation()
  const { byId, getFullSessions } = useAllSessions()

  const deviceStatus = (d?: any) => {
    const status = d.isAlarm
      ? DeviceStatus.ALARM
      : (d as Device)?.isRepair
      ? DeviceStatus.REPAIR
      : DeviceStatus.NORMAL

    return {
      status: status,
      statusStr: t(`device:${DeviceStatusStr[status]}`),
      deviceStatus: status,
      deviceStatusStr: t(`device:${DeviceStatusStr[status]}`),
    }
  }

  const activeStr = (d: any) => {
    if (!d?.active) {
      return {}
    }
    const active =
      d.active === -1
        ? DeviceActive.DELETE
        : d.active === 0
        ? DeviceActive.INACTIVE
        : DeviceActive.ACTIVE

    return {
      activeStr: t(`device:${DeviceActiveStr[active]}`),
    }
  }

  const cameraTypeStr = (d?: any) => {
    if (isNil(d?.cameraType)) {
      return {}
    }

    let cameraTypeStr = ''
    switch (d?.cameraType) {
      case CameraType.UNKNOWN:
        cameraTypeStr = t('camera:unknow')
        break
      case CameraType.BULLET:
        cameraTypeStr = t('camera:bullet')
        break
      case CameraType.BULLET_OVERALL:
        cameraTypeStr = t('camera:bullet overall view')
        break
      case CameraType.DOME_OVERALL:
        cameraTypeStr = t('camera:dome overall view')
        break
    }

    return {
      cameraTypeStr,
    }
  }

  const powerSupplierOutlets = (outlets?: {}) => {
    if (isNil(outlets)) {
      return
    }

    const data = Object.keys(outlets).map((i: string, id: number) => {
      const o = outlets as any

      return { name: i, id, ...o[i] }
    })

    return data
  }

  const switchLeakageStr = (d: ILamp | any) => ({
    switchLeakageStr: t(
      `light:SWITCH_LAEKAGE_${d.switchLeakage ? 'true' : 'false'}`
    ),
  })

  const convertProperties = (d: Device) => {
    return {
      properties: {
        ...d.properties,
        ...(d.properties.switchLeakage && switchLeakageStr(d.properties)),
        lamps: d?.properties?.lamps?.map((lamp: any) => ({
          ...lamp,
          ...activeStr(lamp),
          ...lastOnlineAt(lamp),
          ...installTime(lamp),
          properties: {
            ...lamp?.properties,
            imgs: lamp?.properties?.imgs?.map((img: any) => ({
              ...img,
              url: `${env.uploadFileUrlPrefix}${img.filePath}`,
            })),
          },
        })),
      },
    }
  }

  const gps = (d: Device) =>
    d.lat &&
    d.lon && {
      gps: `${d.lat},${d.lon}`,
    }

  const sessionName = (d: Device | any) =>
    d?.sessionId
      ? {
          sessionName: pathOr('', [d.sessionId, 'name'], byId),
        }
      : {}

  const brightnessType = (d: Device) => {
    const brightness =
      d.deviceType === DeviceType.LIGHT &&
      d?.properties?.lamps.length &&
      d?.properties?.lamps
        ? apply(
            Math.max,
            d?.properties?.lamps.map((lamp: ILamp) => {
              const _brightnessValue = defaultTo(
                0,
                !isNil(lamp?.extra?.brightnessCtrl)
                  ? lamp?.extra?.brightnessCtrl
                  : lamp?.extra?.brightness
              )
              return _brightnessValue
            })
          )
        : undefined

    return {
      ...(!isNil(brightness) && {
        brightnessType:
          brightness === 0 ? 'off' : brightness >= 1 ? 'normal' : '',
      }),
    }
  }

  const convertDetailPageUrl = (d: Device) => {
    const deviceTypeId = d?.deviceType && DeviceTypeId[d.deviceType]
    let url = null
    switch (d.deviceType) {
      case DeviceType.LIGHT:
        url = STREETLIGHT_MANAGEMENT
        break
      case DeviceType.CAMERA:
        url = CAMERA_MANAGEMENT
        break
      case DeviceType.DISPLAYSCREEN:
        url = MEDIA_SCREEN_MANAGEMENT
        break
      case DeviceType.WEATHERSTATION:
        url = WEATHER_MANAGEMENT
        break
      case DeviceType.POWERSUPPLIER:
        url = POWERSUPPLIER_MANAGEMENT
        break
    }
    return {
      detailPageUrl: `${env.appUrl}${url}?device=${deviceTypeId},${d.id}`,
    }
  }

  const brightnessStr = (d: ILamp) => ({
    brightness: d?.extra?.brightnessCtrl || d?.extra?.brightness || 0,
    brightnessStr:
      (!isNil(d?.extra?.brightnessCtrl)
        ? d?.extra?.brightnessCtrl
        : !isNil(d?.extra?.brightness)
        ? d?.extra?.brightness
        : '- ') + '%',
  })

  const convertContentName = (d: Device) => {
    return d.properties.contentInfo
  }

  const convertDevicesAttachedOnLight = (device: Device) => {
    return {
      attachedDevicesStr:
        compose(
          join(', '),
          pluck('displayName'),
          pathOr([], ['subDevices'])
        )(device) || ' ',
    }
  }

  const expiredDate = (d: any) => ({
    expiredDate: d.expiredDate && moment(d.expiredDate),
    expiredDateStr: d.expiredDate && moment(d.expiredDate).format('YYYY.MM.DD'),
  })

  const installTime = (d: any) => ({
    installTime: d.installTime && moment(d.installTime),
    installTimeStr: d.installTime && moment(d.installTime).format('YYYY.MM.DD'),
  })

  const lastOnlineAt = (d: any) => ({
    lastOnlineAt: d.lastOnlineAt && moment(Number(d.lastOnlineAt)),
    lastOnlineAtStr:
      d.lastOnlineAt &&
      moment(Number(d.lastOnlineAt)).format('YYYY.MM.DD HH:mm:ss'),
  })

  const updateTime = (d: any) => ({
    updateTime: d.updateTime && toMoment(d.updateTime),
    updateTimeStr:
      d.updateTime && momentToString(d.updateTime, 'YYYY/MM/DD HH:mm:ss'),
  })

  const installAt = (d: any) => ({
    installAt: d.installAt ? moment(Number(d.installAt)) : undefined,
    installAtStr:
      d.installAt && moment(Number(d.installAt)).format('YYYY/MM/DD HH:mm:ss'),
  })

  const expiredAt = (d: any) => ({
    expiredAt: d.expiredAt && moment(Number(d.expiredAt), 'x'),
    expiredAtStr:
      d.expiredAt && moment(Number(d.expiredAt), 'x').format('YYYY.MM.DD'),
  })

  const locationNs = (d: any) => {
    const secondSessionId =
      getFullSessions(d.sessionId).length === 2
        ? d.sessionId
        : pathOr('', [d.sessionId, 'parentSessionId'], byId)
    const session = pathOr({}, [secondSessionId], byId)
    const sessionName = pathOr('', ['name'], session)
    const prefix = pathOr(undefined, ['locationNs'], session)
    const locationNs = prefix ? `${prefix}/${sessionName}` : sessionName
    return { locationNs }
  }

  const sessionPath = (d: any) => ({
    sessionPath: getFullSessions(d.sessionId),
    sessionPathStr: getFullSessions(d.sessionId)
      .map(layer => layer.name)
      .join(' > '),
  })

  const cameraStreamingUrl = (d?: any) => {
    if (isNil(d?.orgStreamingUrl)) {
      return {}
    }

    return {
      streamingUrl: `<a href="${d.orgStreamingUrl}" target="_blank" rel="noreferrer noopener">${d.orgStreamingUrl}</a>`,
    }
  }

  const isPositioned = (d: any) => {
    let isPositioned = false
    if (byId[d.sessionId]?.type === MapType.GoogleMap) {
      isPositioned = !!(d?.lon && d?.lat)
    }
    if (byId[d.sessionId]?.type === MapType.Upload) {
      isPositioned = !!(d?.x && d?.y)
    }
    return { isPositioned }
  }

  return {
    activeStr,
    brightnessStr,
    brightnessType, //給marker render使用, 亮度模式
    cameraTypeStr,
    cameraStreamingUrl, //render html
    deviceStatus,
    expiredDate,
    expiredAt,
    gps,
    installTime,
    installAt,
    lastOnlineAt,
    powerSupplierOutlets,
    sessionName,
    switchLeakageStr,
    updateTime,
    convertProperties,
    convertContentName, //用在  DeviceType.DISPLAYSCREEN
    convertDevicesAttachedOnLight,
    convertDetailPageUrl,
    sessionPath,
    locationNs,
    isPositioned,
  }
}
