import { Select, SelectProps } from '@shared/Form/Select'
import { useTranslation } from 'react-i18next'
import { SupportedDeviceType } from '@services/constants'

export default function SubDeviceSelect({ ...props }: SelectProps) {
  const { t } = useTranslation()

  return (
    <Select allowClear placeholder={t('report:select device')} {...props}>
      {Object.keys(SupportedDeviceType)
        .filter(d => ['BOX', 'LIGHT', 'LAMP_CTLER'].indexOf(d) === -1)
        .map(x => (
          <Select.Option key={x} value={(SupportedDeviceType as any)[x]}>
            {t(`device:${(SupportedDeviceType as any)[x]}`)}
          </Select.Option>
        ))}
    </Select>
  )
}
