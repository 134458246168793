import { useQuery, UseQueryOptions } from 'react-query'
import { path } from 'ramda'

/**
 * 氣象站 控制器類型清單
 */
export const useWeatherControllerType = (options?: UseQueryOptions) => {
  const { data, ...rest } = useQuery(['/ctrler/types/weatherstation'], {
    cacheTime: 0,
    select: res => path(['data'], res),
    ...options,
  })

  return {
    ...rest,
    data: data as string[],
    loading: rest.isLoading,
  }
}
