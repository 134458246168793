export default function useDailySetEnum() {
  enum TIME {
    DAY = 0,
    NIGHT,
    CUSTOMIZE,
  }

  const TimeSetEnum = {
    [`${TIME.DAY}`]: '日出',
    [`${TIME.NIGHT}`]: '日落',
    [`${TIME.CUSTOMIZE}`]: '自訂',
  }

  const getTimeSetStr = (val: string) => {
    return TimeSetEnum[val]
  }

  return { TIME, TimeSetEnum, getTimeSetStr }
}
