import { useMutation, useQuery } from 'react-query'
import { compose, forEach, pathOr, values } from 'ramda'
import request, {
  useMakeMutation,
  convertData,
} from '@shared/innmaxLib/services'
import type { MutationOptions } from '@shared/innmaxLib/services'
import { Func, FuncDefList, IUserBody, UserManagementRole } from './user.types'
import { useConvertIUsers } from './user.converter'
import { setFunc } from './user.utils'

export const useRoles = (querys?: { [key: string]: any }) => {
  const { data, isLoading, refetch } = useQuery([
    '/memberRole/list/{pageNum}/{pageSize}',
    querys,
  ])

  const dataSource = convertData<UserManagementRole>({ field: ['data'] }, data)

  return { dataSource, isLoading, refetch }
}

export const useUserList = (querys: { [key: string]: any }) => {
  const { data, isLoading, refetch } = useQuery([
    '/account/sys/list/{pageNum}/{pageSize}',
    querys,
  ])

  const dataSource = useConvertIUsers(data)

  return { dataSource, isLoading, refetch }
}

export const useGetSysDefList = () => {
  const { data } = useQuery(['/authority/defList'])
  let funcById: { [key: string]: Func } = {}
  compose(
    forEach((x: FuncDefList) => {
      funcById[x.name] = setFunc(x, funcById[x.name])
    }),
    pathOr([], ['data'])
  )(data)
  return { funcList: values(funcById) }
}

export const useAddMemberRole = () =>
  useMutation((body: any) => {
    return request('/memberRole', {
      body,
      method: 'POST',
      successText: '已新增角色權限',
    })
  })

export const useEditMemberRole = () =>
  useMutation((body: any) => {
    return request('/memberRole/{id}', {
      body,
      method: 'PUT',
      successText: '已編輯角色權限',
    })
  })

export const useDeleteMemberRole = () =>
  useMutation((body: any) => {
    return request('/memberRole/{id}', {
      body,
      method: 'DELETE',
      successText: '已刪除角色權限',
    })
  })

export const createAccount = (values: Partial<IUserBody>) =>
  request('/account/sys', { method: 'POST', body: values })
export const useAddAccount = (options: MutationOptions = {}) => {
  return useMakeMutation(createAccount, {
    ...options,
    successText: '帳號新增成功',
  })
}

export const changePassword = (values: Partial<IUserBody>) =>
  request('/account/password/{id}', { method: 'POST', body: values })
export const useChangePassword = (options: MutationOptions = {}) => {
  return useMakeMutation(changePassword, {
    ...options,
    successText: '成功修改密碼',
  })
}

export const updateAccount = ({ ...values }: Partial<IUserBody> = {}) =>
  request('/account/sys/{id}', { method: 'PUT', body: values })

export const useUpdateAccount = (options: MutationOptions = {}) => {
  return useMakeMutation(updateAccount, {
    ...options,
    successText: '帳號編輯成功',
  })
}

export const deleteAccount = (id: React.Key) =>
  request(`/account/sys/${id}`, { method: 'DELETE' })

export const useDeleteAccount = (options: MutationOptions = {}) => {
  return useMakeMutation(deleteAccount, {
    ...options,
    successText: '帳號已刪除',
  })
}

export const useUserAccess = () => useMakeMutation(
  () => request('/access', { method: 'POST', displayMessage: false, }), {}
)

export const useUserAlive = () => useMakeMutation(
  () => request('/access'), { displayMessage: false, }
)