import { useQuery, UseQueryOptions } from 'react-query'
import { path } from 'ramda'
import { QueryVariables, useLazyQuery } from '@shared/innmaxLib/services'

import { Device, useConvertISubDevice } from '@services/device'

export const useLazySubDevice = <T extends Device>({
  variables = {},
  ...options
}: QueryVariables = {}) => {
  const { data, refetch, ...others } = useLazyQuery(
    ['/device/subDevice', { current: '', size: '', isSub: false, ...variables }],
    {
      ...options,
      keepPreviousData: true,
      select: res => path(['data'], res),
    }
  )

  return {
    ...others,
    refetch,
    loading: others.isLoading,
    data,
  }
}

export const useSubDevice = (
  query?: { parentDeviceSeq?: number; parentDeviceType?: string },
  options?: UseQueryOptions
) => {
  const { data, refetch, ...others } = useQuery(['/device/subDevice', query], {
    cacheTime: 0,
    enabled: !!query?.parentDeviceSeq && !!query?.parentDeviceType,
    select: (res: any) => path(['data'], res),
    ...options,
  })

  const dataSource = useConvertISubDevice(data)

  return {
    ...others,
    refetch,
    loading: others.isLoading,
    data: dataSource,
  }
}

/**
 *
 * @returns 回傳前 五筆可附掛的設備
 */
export const useLookupSubDevices = (query?: any) => {
  const { data, refetch, ...others } = useQuery(['/device/subDevice/lookup', query], {
    select: (res: any) => path(['data'], res),
  })

  const dataSource = useConvertISubDevice(data)

  return {
    ...others,
    refetch,
    data: dataSource,
  }
}
