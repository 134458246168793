import React, { useState, Ref } from 'react'
import styled from 'styled-components'
import { Form, Modal } from 'antd'
import { FormInstance, FormProps } from 'antd/lib/form'
import { Button } from '@shared/Form'

export interface FormRef extends FormInstance {
  leave: () => void
}

interface Props extends FormProps {
  children: React.ReactNode
  ref?: React.ForwardedRef<FormRef>
  leaveChecker?: boolean
  onCancel?: () => void
}

function FormComponent(
  { leaveChecker = true, onCancel, children, ...props }: Props,
  ref: Ref<FormRef>
) {
  const [visible, setVisible] = useState<boolean>(false)
  const [changed, setChanged] = useState<boolean>(false)

  const onChange = () => setChanged(true)
  const onOk = () => setVisible(false)
  const leave = () => {
    if (leaveChecker && changed) {
      setVisible(true)
    } else {
      onCancel && onCancel()
    }
  }

  const onQuit = () => {
    setVisible(false)
    onCancel && onCancel()
  }

  React.useImperativeHandle<Pick<FormRef, 'leave'>, Pick<FormRef, 'leave'>>(
    ref,
    () => ({
      leave: leave,
    })
  )

  return (
    <>
      <StyledModal
        width="400px"
        open={visible}
        onCancel={onOk}
        footer={[
          <StyledButton
            key="cancel"
            onClick={onQuit}
            style={{ marginRight: 24 }}>
            放 棄
          </StyledButton>,
          <StyledButton key="continue" type="primary" onClick={onOk}>
            繼續編輯
          </StyledButton>,
        ]}>
        編輯的資料尚未儲存，是否放棄編輯?
      </StyledModal>
      <Form ref={ref} {...props} onChange={onChange}>
        {children}
      </Form>
    </>
  )
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    .ant-modal-close {
      padding: 16px;
      display: block;
      position: inherit;
      float: right;
      .ant-modal-close-x {
        width: 32px;
        height: 32px;
        line-height: 25px;
        font-size: 23px;
      }
    }
    .ant-modal-body {
      clear: both;
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      padding: 16px;
      margin-bottom: 23px;
    }
  }
  .ant-modal-footer {
    padding: 24px 32px;
    border: 0;
  }
`

const StyledButton = styled(Button)`
  padding: 5px 8px;
  min-width: 80px !important;
  line-height: initial !important;
`

export default React.forwardRef(FormComponent)
