import React from 'react'
import { pathOr, mergeRight } from 'ramda'
import {
  GoogleMap,
  GoogleMapProps,
  DrawingManager,
  Rectangle,
} from '@react-google-maps/api'
import { useRecoilState } from 'recoil'

import theme from '@theme'
import useLoadGoogleMap from './useGoogleMap'
import {
  defaultMapCenter,
  defaultMapZoom,
  defaultMapStyle,
  defaultGoogleMapOptions,
} from '../map.options'
import { MapLayerType, GoogleMapControlsOption } from '../map.types'
import { useGoogleMap } from './GoogleMapProvider'
import { MapActiveMarkerState } from '../MapProvider'
import { uuid } from '@shared/innmaxLib/utils/webHelper'
import ZoomControl from './ZoomControl'
import BackRectangleSelectControl from './BackRectangleSelectControl'
import PlaceSearchControl from './PlaceSearchControl'
import FilterControl from './FilterControl'
import SatelliteControl from './SatelliteControl'
import LayerControl from './LayerControl'
import BackControl from './BackControl'

export interface GoogleMapComponentProps extends GoogleMapProps {
  loading?: boolean
  renderLoading?: JSX.Element
  enableClusterer?: boolean
  options?: GoogleMapControlsOption
  onRectangleComplete?: (rectangle: google.maps.Rectangle) => void
  onRectangleClick?: (e: google.maps.MapMouseEvent) => void
  onFilterControlClick?: () => void
  onLayerControlClick?: () => void
}

const InternalGoogleMap: React.ForwardRefRenderFunction<
  GoogleMap,
  GoogleMapComponentProps
> = (
  {
    id,
    center,
    zoom,
    children,
    mapContainerStyle = {},
    options = {},
    loading = false,
    renderLoading,
    enableClusterer = false,
    onLoad,
    onClick,
    onZoomChanged,
    onDragEnd,
    onRectangleComplete,
    onRectangleClick,
    onFilterControlClick,
    onLayerControlClick,
    ...others
  },
  ref: React.ForwardedRef<GoogleMap>
) => {
  const mapRef = React.useRef<google.maps.Map>()

  const [containerId] = React.useState(id || `map-${uuid()}`)

  const [activeMarker] = useRecoilState(MapActiveMarkerState)

  const [mapZoom, setMapZoom] = React.useState<number>(defaultMapZoom)

  const [layerType, setLayerType] = React.useState<MapLayerType>(
    MapLayerType.ROADMAP
  )

  const [rectangle, setRectangle] =
    React.useState<google.maps.Rectangle | null>()

  const [drawingMode, setDrawingMode] = React.useState<
    google.maps.drawing.OverlayType.RECTANGLE | undefined
  >(undefined)

  const [mapCenter, setMapCenter] = React.useState<
    google.maps.LatLng | google.maps.LatLngLiteral | undefined
  >(defaultMapCenter)

  const { isLoaded, loadError } = useLoadGoogleMap()

  const { smoothMapZoom, setIsStreetMode, handleMapLoad } = useGoogleMap()

  const mapOptions = mergeRight(
    {
      ...defaultGoogleMapOptions,
      streetViewControlOptions: {
        position: window?.google?.maps?.ControlPosition?.LEFT_BOTTOM,
      },
      zoomControlOptions: {
        position: window?.google?.maps?.ControlPosition?.BOTTOM_RIGHT,
        styles: { margin: '0 40px 40px 0' },
      },
      backControlOptions: {
        position: window?.google?.maps?.ControlPosition?.RIGHT_BOTTOM,
        styles: { margin: '24px 40px 24px 0' },
      },
      backRectangleSelectControlOptions: {
        position: window?.google?.maps?.ControlPosition?.RIGHT_BOTTOM,
        styles: { margin: '24px 40px 24px 0' },
      },
      placeSearchControlOptions: {
        position: window?.google?.maps?.ControlPosition?.TOP_RIGHT,
        styles: { margin: '44px 40px 0 0' },
      },
      filterControlOptions: {
        position: window?.google?.maps?.ControlPosition?.RIGHT_TOP,
        styles: { margin: '24px 40px 0 0' },
      },
      layerControlOptions: {
        position: window?.google?.maps?.ControlPosition?.RIGHT_TOP,
        styles: { margin: '18px 40px 0 0' },
      },
      satelliteControlOption: {
        position: window?.google?.maps?.ControlPosition?.BOTTOM_LEFT,
        styles: { margin: '0 0 40px 14px' },
      },
    },
    options
  )

  const handleStreetViewPovChanged = React.useCallback(
    (e: google.maps.StreetViewPanorama) => {
      // 街景模式無click event，改用pov_changed
      window?.google?.maps?.event?.addListener(e, 'pov_changed', () => {
        setRectangle(null)
        setDrawingMode(undefined)
        onClick && onClick(undefined as any)
      })
    },
    []
  ) //eslint-disable-line

  const handleStreetViewVisibleChanged = React.useCallback(
    (e: google.maps.StreetViewPanorama) => {
      window?.google?.maps?.event?.addListener(e, 'visible_changed', () =>
        setIsStreetMode(e.getVisible())
      )
    },
    []
  ) //eslint-disable-line

  const handleMapLoaded = React.useCallback((map: google.maps.Map) => {
    onLoad && onLoad(map)
    mapRef.current = map
    handleStreetViewVisibleChanged(map.getStreetView())
    handleStreetViewPovChanged(map.getStreetView())
    handleMapLoad(map)
    if (typeof ref === 'function') {
      ref(map.getDiv() as any)
    } else if (ref) {
      ref.current = map.getDiv() as any
    }
  }, []) //eslint-disable-line

  const handleClickMap = React.useCallback(
    (e: google.maps.MapMouseEvent) => {
      setRectangle(null)
      setDrawingMode(undefined)
      onClick && onClick(e)
    },
    [onClick]
  ) //eslint-disable-line

  const handleMapZoomChanged = React.useCallback(() => {
    mapRef?.current && setMapZoom(mapRef?.current?.getZoom() as number)
    onZoomChanged && onZoomChanged()
  }, [onZoomChanged]) //eslint-disable-line

  const handleMapDragEnd = React.useCallback(() => {
    mapRef?.current && setMapCenter(mapRef?.current?.getCenter()?.toJSON())
    onDragEnd && onDragEnd()
  }, [onDragEnd]) //eslint-disable-line

  const handleMapZoomIn = React.useCallback(() => {
    setMapZoom((mapZoom as number) + 1)
  }, [mapZoom])

  const handleMapZoomOut = React.useCallback(() => {
    setMapZoom((mapZoom as number) - 1)
  }, [mapZoom])

  const handleRectangleComplete = React.useCallback(
    (rectangle: google.maps.Rectangle) => {
      google.maps.event.clearInstanceListeners(rectangle)
      rectangle.setMap(null)
      setRectangle(rectangle)
      setDrawingMode(undefined)
      onRectangleComplete && onRectangleComplete(rectangle)
    },
    [rectangle, onRectangleComplete]
  ) //eslint-disable-line

  const handleBackToDefaultCenter = React.useCallback(() => {
    mapRef?.current?.setZoom((zoom || defaultMapZoom) as number)
    mapRef?.current?.panTo((center || defaultMapCenter) as google.maps.LatLng)
  }, [zoom, mapRef, center]) //eslint-disable-line

  const handleRectangleSelect = React.useCallback(() => {
    setDrawingMode(
      !drawingMode ? google.maps.drawing.OverlayType.RECTANGLE : undefined
    )
  }, []) //eslint-disable-line

  const handleOnRectangleClick = React.useCallback(
    (e: google.maps.MapMouseEvent) => {
      setRectangle(null)
      setDrawingMode(undefined)
      onRectangleClick && onRectangleClick(e)
    },
    [onRectangleClick]
  ) //eslint-disable-line

  const handleOnPlaceSelected = React.useCallback(
    (place: google.maps.places.PlaceResult) => {
      if (place.geometry) {
        mapRef?.current?.fitBounds(
          new google.maps.LatLngBounds(place?.geometry?.viewport)
        )
        setTimeout(() => setMapCenter(place?.geometry?.location?.toJSON()), 500)
      }
    },
    [mapRef]
  ) //eslint-disable-line

  const handleLayerType = React.useCallback((layerType: MapLayerType) => {
    setLayerType(layerType)
  }, [])

  React.useEffect(() => {
    if (!mapRef?.current) {
      return
    }
    if (activeMarker?.lat && activeMarker?.lon) {
      const _center = {
        lat: activeMarker.lat,
        lng: activeMarker.lon,
      }
      smoothMapZoom(
        (mapOptions.maxZoom || defaultGoogleMapOptions.maxZoom) as number,
        mapRef?.current?.panTo(_center)
      )
      setTimeout(() => setMapCenter(_center), 500)
    }
  }, [activeMarker, mapRef]) //eslint-disable-line

  React.useEffect(() => {
    center && center.lat && center.lng && setMapCenter(center)
  }, [JSON.stringify(center)]) //eslint-disable-line

  React.useEffect(() => {
    zoom && setMapZoom(zoom)
  }, [zoom])

  const renderPlugins = React.useMemo(
    () =>
      [
        {
          name: 'zoomControl',
          component: (
            <ZoomControl
              zoomIn={handleMapZoomIn}
              zoomOut={handleMapZoomOut}
              {...(mapOptions.zoomControlOptions as any)}
            />
          ),
        },
        {
          name: 'backControl',
          component: (
            <BackControl
              onClick={handleBackToDefaultCenter}
              {...(mapOptions.backControlOptions as any)}
            />
          ),
        },
        {
          name: 'backRectangleSelectControl',
          component: (
            <>
              <DrawingManager
                drawingMode={drawingMode}
                options={drawingManagerOptions}
                onRectangleComplete={handleRectangleComplete}
              />
              <Rectangle
                bounds={rectangle?.getBounds() || undefined}
                options={rectangleOptions}
                onClick={handleOnRectangleClick}
              />
              <BackRectangleSelectControl
                isDrawingMode={
                  drawingMode ===
                  window?.google?.maps?.drawing?.OverlayType?.RECTANGLE
                }
                back={handleBackToDefaultCenter}
                select={handleRectangleSelect}
                {...mapOptions.backRectangleSelectControlOptions}
              />
            </>
          ),
        },
        {
          name: 'placeSearchControl',
          component: (
            <PlaceSearchControl
              onPlaceSelected={handleOnPlaceSelected}
              {...mapOptions.placeSearchControlOptions}
            />
          ),
        },
        {
          name: 'filterControl',
          component: (
            <FilterControl
              onClick={onFilterControlClick}
              {...mapOptions.filterControlOptions}
            />
          ),
        },
        {
          name: 'layerControl',
          component: (
            <LayerControl
              onClick={onLayerControlClick}
              {...mapOptions.layerControlOptions}
            />
          ),
        },
        {
          name: 'satelliteControl',
          component: (
            <SatelliteControl
              layerType={layerType}
              onClick={handleLayerType}
              center={mapCenter}
              zoom={mapZoom as number}
              {...mapOptions.satelliteControlOption}
            />
          ),
        },
      ]
        .filter(plugin => pathOr(false, [plugin.name], mapOptions))
        .map(plugin =>
          React.cloneElement(plugin?.component, { key: plugin.name })
        ),
    [mapOptions]
  ) //eslint-disable-line

  if (loading || !isLoaded) {
    return renderLoading ? renderLoading : <div>loading</div>
  }

  if (loadError) {
    return <div>Map cannot be loaded right now.</div>
  }

  return (
    <GoogleMap
      id={containerId}
      zoom={mapZoom}
      center={mapCenter}
      onLoad={handleMapLoaded}
      onClick={handleClickMap}
      onZoomChanged={handleMapZoomChanged}
      onDragEnd={handleMapDragEnd}
      mapContainerStyle={mergeRight(defaultMapStyle, mapContainerStyle)}
      options={{
        ...mapOptions,
        zoomControl: false, // 改用客製化的zoomControl
        mapTypeId: layerType,
      }}
      {...others}>
      {renderPlugins}
      {children}
    </GoogleMap>
  )
}

const rectangleOptions = {
  ...theme.n.map.rectangleOptions,
}

const drawingManagerOptions = {
  drawingControl: false,
  rectangleOptions,
}

export default React.forwardRef<GoogleMap, GoogleMapComponentProps>(
  InternalGoogleMap
)
