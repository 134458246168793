import { Device } from '@services/device'
import React from 'react'
import { MapType } from '../../map.types'
import maplibregl, { Marker as MarkerType, Map } from 'maplibre-gl'
import { markerIcon } from '@shared/innmaxMap/components/Marker'
import { DeviceStatus } from '@services/constants'
import cx from 'classnames'
import { useMapLibre } from '../Provider'
import { DeleteWindow } from '@shared/innmaxMap/components/Window'
import Tooltip from '../Tooltip'
import Popup from '../Popup'

interface MarkerComponentProps {
  item: Device
  focus?: boolean
  tooltip?: boolean
  deletable?: boolean
  draggable?: boolean
  selected?: boolean
  children?: React.ReactNode
  mapType?: MapType
  onLeftClick?: (d: Device) => void
  onRightClick?: (d: Device) => void
  onMouseOver?: (d: Device) => void
  onMouseOut?: (d: Device) => void
  // onDrag?: (e: LeafletMouseEvent) => void
  onDragEnd?: (e: Device) => void
  onDelete?: (d: Device) => void
}

const Marker = React.memo((props: MarkerComponentProps) => {
  const {
    item,
    mapType,
    selected,
    focus,
    onLeftClick,
    onRightClick,
    deletable,
    draggable,
    onDragEnd,
    onDelete,
    tooltip = true,
  } = props

  const {
    map,
    projectRatioPointsToLngLat,
    projectToRatioPoints,
    imageMapBounds,
  } = useMapLibre()

  const [marker, setMarker] = React.useState<MarkerType | null>(null)
  const [visibleDeletePopup, setVisibleDeletePopup] = React.useState(false)

  const getClassName = ({
    item,
    selected,
    focus,
  }: {
    item: Device
    selected?: boolean
    focus?: boolean
  }) =>
    cx(
      item?.deviceType,
      item?.deviceStatus === DeviceStatus.ALARM
        ? 'alarm'
        : item?.deviceStatus === DeviceStatus.REPAIR
        ? 'repair'
        : 'normal',
      item?.subDeviceCount
        ? item?.isSubAlarm
          ? 'has-child-alarm'
          : 'has-child'
        : '',
      item?.brightnessType,
      { selected: selected },
      { 'icon-animation': focus },
      'marker-wrapper'
    )

  // handle focus
  React.useEffect(() => {
    marker?.toggleClassName('icon-animation')
  }, [focus])

  // handle selected
  React.useEffect(() => {
    marker?.toggleClassName('selected')
  }, [selected])

  React.useEffect(() => {
    marker?.setDraggable(draggable)
  }, [draggable])

  const generateMarkerElement = ({ item }: { item: Device }) => {
    if (!item) {
      return null
    }
    const markerImg = markerIcon(item)
    if (!markerImg) {
      return null
    }
    const el = document.createElement('div')
    el.style.width = '28px'
    el.style.height = '28px'
    el.style.cursor = 'pointer'
    el.setAttribute(
      'data-device-status',
      item.subDeviceCount && item.isSubAlarm
        ? DeviceStatus.ALARM + ''
        : item.deviceStatus + ''
    )

    const imgEl = document.createElement('img')
    imgEl.style.padding = '3px'
    imgEl.src = markerImg

    el.appendChild(imgEl)

    return el
  }

  React.useEffect(() => {
    if (!marker || !map) {
      return
    }
    const handleClick = (e: MouseEvent) => {
      switch (e.button) {
        case 0:
          onLeftClick && onLeftClick(item)
          break
        default:
          break
      }
    }

    const handleContextmenu = () => {
      onRightClick && onRightClick(item)

      if (!deletable) {
        return
      }
      setVisibleDeletePopup(true)
    }

    const markerElement = marker.getElement()

    markerElement.addEventListener('click', handleClick)
    markerElement.addEventListener('contextmenu', handleContextmenu)

    return () => {
      markerElement.removeEventListener('click', handleClick)
      markerElement.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [deletable, item, map, marker, onLeftClick, onRightClick])

  React.useEffect(() => {
    if (!marker || !map) {
      return
    }
    const handleDragEnd = () => {
      if (!onDragEnd) {
        return
      }
      if (mapType === MapType.GoogleMap) {
        onDragEnd({
          ...item,
          lat: Number(marker.getLngLat().lat.toFixed(15)),
          lon: Number(marker.getLngLat().lng.toFixed(15)),
        })
      }
      if (mapType === MapType.Upload && imageMapBounds) {
        const imgBounds = new maplibregl.LngLatBounds(
          imageMapBounds[1],
          imageMapBounds[0]
        )
        console.log('122', imgBounds.contains(marker.getLngLat()))
        if (!imgBounds.contains(marker.getLngLat())) {
          if (Math.abs(marker.getLngLat().lat) > imgBounds.getEast()) {
            marker.setLngLat([marker.getLngLat().lng, imgBounds.getEast()])
          }
          if (Math.abs(marker.getLngLat().lat) > imgBounds.getWest()) {
            marker.setLngLat([marker.getLngLat().lng, imgBounds.getWest()])
          }
          if (Math.abs(marker.getLngLat().lng) > imgBounds.getNorth()) {
            marker.setLngLat([imgBounds.getNorth(), marker.getLngLat().lat])
          }
          if (Math.abs(marker.getLngLat().lng) > imgBounds.getSouth()) {
            marker.setLngLat([imgBounds.getSouth(), marker.getLngLat().lat])
          }
        }

        const point = projectToRatioPoints({
          x: map.project(marker.getLngLat()).x,
          y: map.project(marker.getLngLat()).y,
        })
        onDragEnd({
          ...item,
          x: point.x,
          y: point.y,
        })
      }
    }
    marker.on('dragend', handleDragEnd)
    return () => {
      marker.off('dragend', handleDragEnd)
    }
  }, [item, map, marker, onDragEnd, mapType, imageMapBounds])

  React.useEffect(() => {
    if (!item || !map) {
      return
    }
    const _markerElement = generateMarkerElement({ item })
    if (!_markerElement) {
      console.error('[mapLibre error], 無對應的 Marker')
      return
    }
    const _marker = new maplibregl.Marker({
      element: _markerElement,
      draggable,
    })

    setMarker(_marker)

    if (mapType === MapType.GoogleMap && item.lat && item.lon) {
      _marker.setLngLat({
        lat: item.lat,
        lon: item.lon,
      })
    }
    if (mapType === MapType.Upload && item.x && item.y) {
      const lngLat = projectRatioPointsToLngLat({
        x: item.x,
        y: item.y,
      })
      _marker.setLngLat(lngLat)
    }

    getClassName({ item, selected, focus })
      .split(' ')
      .forEach(cx => _marker.addClassName(cx))

    _marker.addTo(map)

    return () => {
      _marker.remove()
    }
  }, [map, item])

  return (
    <>
      {tooltip && (
        <Tooltip>
          <span>{item?.displayName || item?.deviceDisplayName}</span>
        </Tooltip>
      )}
      {visibleDeletePopup && (
        <Popup marker={marker} visible>
          <DeleteWindow
            data={item}
            onCancel={() => setVisibleDeletePopup(false)}
            deleteMarker={() => onDelete && onDelete(item)}
          />
        </Popup>
      )}
    </>
  )
})

export default Marker
