import { SVGProps } from 'react'

const BellSVG = ({ color = '#fff', ...props }: SVGProps<any>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g
      fill={color}
      fillRule="nonzero"
      stroke={color}
      strokeWidth=".2">
      <path d="M5.57 12.238A5.273 5.273 0 0 1 4.428 15l-.078.105c-.576.857.026 2.026.95 2.026h13.402c.963 0 1.575-1.269.872-2.13a5.274 5.274 0 0 1-1.144-2.763l-.374-3.443c-.353-3.24-2.848-5.671-5.82-5.794L12 2.996c-3.074 0-5.693 2.473-6.055 5.8l-.374 3.442zM12 1.3c3.854 0 7.08 3.024 7.64 7.035l.034.27.374 3.442c.066.604.28 1.174.615 1.655l.151.2c1.542 1.888.332 4.925-2.113 4.925H5.299c-2.387 0-3.596-2.894-2.219-4.788l.257-.337c.335-.48.55-1.051.615-1.655l.374-3.443C4.777 4.46 8.06 1.3 12 1.3z"/>
      <path d="M16.02 19.488c.159-.439-.055-.927-.476-1.092a.805.805 0 0 0-1.049.497l1.526.595zm-6.515-.595a.805.805 0 0 0-1.049-.497c-.421.165-.635.653-.477 1.092l1.526-.595zm4.99 0c-.35.973-1.317 1.71-2.495 1.71V22.3c1.83 0 3.423-1.15 4.02-2.812l-1.525-.595zM12 20.603c-1.178 0-2.145-.737-2.495-1.71l-1.526.595A4.268 4.268 0 0 0 12 22.3v-1.696z"/>
    </g>
  </svg>
)

export default BellSVG
