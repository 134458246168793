import React from 'react'
import { useMutation, useQuery, UseQueryOptions } from 'react-query'
import { ifElse, pathOr, isNil, head, propOr } from 'ramda'
import {
  QueryVariables,
  useMakeMutation,
  request,
  MutationOptions,
} from '@shared/innmaxLib/services'
import { mapIndexed } from '@shared/innmaxLib/utils/webHelper'
import type {
  Content,
  ContentBody,
  ContentDetail,
  Marquee,
  Media,
} from './displayscreen.types'
import { DisplayScreenSync } from '@services/device'
const { compose } = require('ramda')

export const useContentList = ({
  variables = {},
  ...options
}: QueryVariables = {}) => {
  const { data, ...others } = useQuery(['/content/list', variables], {
    ...options,
    cacheTime: 0,
    select: (res: any) => {
      const content = compose(
        mapIndexed((x: Content, index: number) => ({
          ...x,
          index: index + 1,
        })),
        pathOr([], ['data'])
      )(res) as any
      return { content, total: content.length }
    },
  })

  return {
    ...others,
    dataSource: data,
    loading: others.isLoading,
  }
}

export const useContentDetail = (id?: number) => {
  const { data, ...others } = useQuery(['/content/{id}/preview', { id }], {
    enabled: !!id,
    cacheTime: 0,
  })

  const dataSource = React.useMemo(() => {
    return compose(
      ifElse(
        isNil,
        () => undefined,
        (x: ContentDetail) => {
          const marquees = mapIndexed(
            (x: Marquee, index: number) => ({
              ...x,
              index: index + 1,
              id: index + 1,
            }),
            pathOr([], ['marquees'], x)
          )

          const medias = mapIndexed((x: Media, index: number) => {
            return {
              ...x,
              index: index + 1,
              id: index + 1,
              type: compose(
                pathOr(undefined, ['type']),
                head,
                pathOr([], ['sources'])
              )(x), //MODE type
            }
          }, pathOr([], ['medias'], x))

          const wholePointMedia = {
            ...pathOr([], ['wholePointMedia'], x),
            type: compose(
              pathOr(undefined, ['type']),
              head,
              pathOr([], ['wholePointMedia', 'sources'])
            )(x), //MODE type
          }

          return { ...x, marquees, medias, wholePointMedia }
        }
      ),
      pathOr(undefined, ['data'])
    )(data)
  }, [data])

  return {
    ...others,
    data: dataSource as ContentDetail | undefined,
  }
}

export const createContent = (values: Partial<ContentBody>) =>
  request('/content', { method: 'POST', body: values })

export const useNewContent = (options: MutationOptions = {}) => {
  return useMakeMutation(createContent, options)
}

export const editContent = (values: Partial<ContentBody>) =>
  request('/content/{id}', { method: 'PUT', body: values })

export const useEditContent = (options: MutationOptions = {}) => {
  return useMakeMutation(editContent, options)
}

export const deleteContent = (id: number) =>
  request(`/content/${id}`, { method: 'DELETE' })

export const useDeleteContent = (options: MutationOptions = {}) => {
  return useMakeMutation(deleteContent, options)
}

export const useCmdContentPreview = (options: MutationOptions = {}) => {
  return useMakeMutation(
    (values: Partial<{ cmd: string }>) =>
      request('/content/cmd/preview', {
        method: 'POST',
        body: values,
        displayError: false,
        displayMessage: false,
      }),
    options
  )
}

export const useDisplayScreenDetail = ({
  variables = {},
  ...options
}: QueryVariables = {}) => {
  const { data, ...others } = useQuery(
    ['/device/displayscreen/{id}', variables],
    {
      ...options,
      select: (res: any) => {
        return pathOr({}, ['data'], res)
      },
      enabled: !!variables?.id,
    }
  )

  return {
    ...others,
    dataSource: data as DisplayScreenSync | null,
    loading: others.isLoading,
  }
}
