import { SVGProps } from 'react'

const BackSVG = ({ color = '#4A4A4A', ...props }: SVGProps<any>) => (
  <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M21.744 13.3H6.387a.787.787 0 1 0 0 1.575h15.357a.787.787 0 1 0 0-1.575z"
        stroke="#4A4A4A"
        fill="#4A4A4A"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default BackSVG
