import React from 'react'
import { filter, invoker, is, concat, __ } from 'ramda'
import type { ColumnProps, TableProps } from 'antd/lib/table'
import type { FormInstance } from 'antd/lib/form'
import { uuid } from '@shared/innmaxLib/utils/webHelper'
import BaseTable from './Table'
const { compose, pathOr } = require('ramda')

type EditableTableProps<T> = Omit<TableProps<T>, 'onChange'> & {
  value?: any
  form: FormInstance
  onChange?(changedValue: any): void
  renderColumns?: (value: any) => ColumnProps<T>[]
  field: string | string[]
}

export type RowAddHandler = (initialValue?: any) => () => any
export type RowDeleteHandler = (id: React.Key) => () => any

function EditableTable<T = any>(
  {
    form,
    onChange,
    value,
    columns,
    renderColumns,
    field,
    ...props
  }: EditableTableProps<T>,
  ref: any
) {
  const _field = is(Array, field) ? field : [field]

  const triggerChange = (changedValue: T[]) => {
    if (onChange) {
      onChange(changedValue)
    }
  }

  const onRowAdd = (initialValue = {}) => () => {
    triggerChange(
      compose(
        concat(__, [
          {
            id: uuid(),
            ...initialValue,
          },
        ]),
        pathOr([], _field),
        invoker(0, 'getFieldsValue')()
      )(form)
    )
  }

  const onRowDelete = (id: string) => () => {
    triggerChange(
      compose(
        filter((x: any) => x.id !== id),
        pathOr([], _field),
        invoker(0, 'getFieldsValue')()
      )(form)
    )
  }

  return (
    <BaseTable
      {...props}
      ref={ref}
      rowKey="id"
      pagination={false}
      dataSource={value as any}
      columns={
        renderColumns ? renderColumns({ onRowAdd, onRowDelete }) : columns
      }
    />
  )
}

export default React.forwardRef(EditableTable)
