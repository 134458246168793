import React from 'react'
import { SVGProps } from 'react'

const SignoutSVG = ({ color = '#0074A8', ...props }: SVGProps<any>) => (
  <i className="icon-signout" {...props}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="4"
        y="2"
        width="12"
        height="16"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <circle
        cx="12.5"
        cy="10.5"
        r="1.5"
        fill={color}/>
    </svg>
  </i>
)

export default SignoutSVG
