import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import styled, { css } from 'styled-components'
import FilterIcon from './FilterIcon'
import { Map } from 'maplibre-gl'
import ReactDOM from 'react-dom/client'
import { IControlClass } from '../type'

export type FilterControlProps = {
  styles?: React.CSSProperties
  onClick?: () => void
  active?: boolean
  css?: any
}

function FilterControlFC({
  styles = {},
  onClick,
  active,
  css: containerCSS,
}: FilterControlProps) {
  const { t } = useTranslation()

  const ref = React.useRef<any>()

  return (
    <div style={styles}>
      <StyledControl
        id="filter-control"
        className="leaflet-control leaflet-bar">
        <button
          ref={ref}
          title={t('dashboard:search device')}
          onClick={onClick}
          className={cx({ active: active })}>
          <FilterIcon color="#fff" />
        </button>
      </StyledControl>
    </div>
  )
}

const StyledControl = styled.div`
  display: flex;
  flex-direction: row;
  user-select: none;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  width: 32px;
  min-height: 32px;
  margin-top: unset !important;
  margin-right: unset !important;
  border: 0 !important;
  background-clip: unset !important;

  button {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    min-height: 32px;
    color: #fff;
    background-color: #0074a8;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 30%);
    position: absolute;
    right: 0;

    &.active,
    &:hover {
      background-color: #ffe288 !important;
      color: #0074a8;

      svg g {
        stroke: #0074a8;
      }
    }
  }
`

export class FilterControl implements IControlClass {
  _map: Map | undefined
  _container?: HTMLDivElement
  _reactRoot?: ReactDOM.Root
  _props?: FilterControlProps

  constructor(props?: FilterControlProps) {
    this._props = props
  }

  onAdd(map: Map) {
    this._map = map
    this._container = document.createElement('div')
    this._container.className = 'maplibregl-ctrl'
    this._reactRoot = ReactDOM.createRoot(this._container)
    this._reactRoot.render(<FilterControlFC {...this._props} />)

    return this._container
  }

  updateProps(props?: FilterControlProps) {
    this._reactRoot?.render(<FilterControlFC {...props} />)
  }

  onRemove() {
    this._container?.parentNode?.removeChild(this._container)
    this._map = undefined
    this._reactRoot = undefined
  }
}

export default React.memo(FilterControlFC)
