import styled from 'styled-components'

export const TabPane = styled.div`
  display: flex;
  margin-left: -24px;
  margin-top: -24px;
  border-top-left-radius: 8px;

  .tab__item {
    width: 240px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    border-top: 6px solid #c0c0c0;
    font-size: 16px;
    font-weight: 500;
    color: #c0c0c0;
    cursor: pointer;
    transition: 0.3s;

    &:first-child {
      border-top-left-radius: 8px;
    }

    &:hover {
      background: #f4f3f3;
    }

    &.is--active,
    &:hover {
      border-top-color: ${p => p.theme.primary};
      color: ${p => p.theme.primary};
    }
  }
`

export const TabContainer = styled.div`
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  min-height: calc(100vh - 112px);
`
