import React from 'react'
import { SVGProps } from 'react'

const FilterSVG = ({ color = '#fff', ...props }: SVGProps<any>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.9929 7.04532C20.9929 7.27032 20.9179 7.49766 20.7679 7.68751L12.7781 17.6203C12.5835 17.8617 12.2906 18.0023 11.9788 18.0023C11.6695 18.0023 11.3741 17.8617 11.182 17.6203L3.2343 7.72969C2.8804 7.28907 2.95071 6.64454 3.39133 6.29063C3.83196 5.93672 4.47649 6.00704 4.8304 6.44766L11.9788 15.3445L19.1718 6.40313C19.5257 5.96251 20.1702 5.89219 20.6109 6.2461C20.8616 6.45001 20.9929 6.74532 20.9929 7.04532Z"
      fill={color}
    />
    <mask
      id="mask0_80_798"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="3"
      y="6"
      width="18"
      height="13">
      <path
        d="M20.9929 7.04532C20.9929 7.27032 20.9179 7.49766 20.7679 7.68751L12.7781 17.6203C12.5835 17.8617 12.2906 18.0023 11.9788 18.0023C11.6695 18.0023 11.3741 17.8617 11.182 17.6203L3.2343 7.72969C2.8804 7.28907 2.95071 6.64454 3.39133 6.29063C3.83196 5.93672 4.47649 6.00704 4.8304 6.44766L11.9788 15.3445L19.1718 6.40313C19.5257 5.96251 20.1702 5.89219 20.6109 6.2461C20.8616 6.45001 20.9929 6.74532 20.9929 7.04532Z"
        fill={color}
      />
    </mask>
    <g mask="url(#mask0_80_798)"></g>
  </svg>
)

export default FilterSVG
