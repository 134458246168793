import React, { SVGProps } from 'react'
const Tree = (props: SVGProps<any>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 84 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.5775 66.6149C47.5883 66.6151 47.5991 66.6151 47.6099 66.6151H58.6244C69.3632 66.6151 80 56.6333 80 45.1598C80 34.4579 70.4123 25.0537 60.7529 23.8374C60.1358 20.7057 58.8624 17.8129 57.1047 15.3405C53.9247 10.3463 48.8628 7 42.6067 7C42.464 7 42.3216 7.00177 42.1795 7.00528C42.0374 7.00177 41.895 7 41.7523 7C35.4962 7 30.4343 10.3463 27.2543 15.3405C25.4966 17.8129 24.2232 20.7057 23.6061 23.8374C13.9466 25.0537 5 34.458 5 45.16C5 56.6335 14.9958 66.6151 25.7346 66.6151H36.7491C36.7918 66.6151 36.8343 66.6141 36.8765 66.612C36.9174 66.6139 36.9585 66.6149 36.9999 66.6149H47.5775Z"
      fill="#FFCC2B"
    />
    <path
      d="M33.8462 40.7173L41.6564 48.6945"
      stroke="#4A4A4A"
      strokeWidth="5.12819"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.4665 40.7173L41.6562 48.6945"
      stroke="#4A4A4A"
      strokeWidth="5.12819"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.6563 24.7628V76.0446"
      stroke="#FFCC2B"
      strokeWidth="5.12819"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.5 25V61"
      stroke="#4A4A4A"
      strokeWidth="5.12819"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default Tree
