import { SVGProps } from 'react'
import cx from 'classnames'

const FailSVG = ({ className, ...props }: SVGProps<any>) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="20" 
    height="20" 
    viewBox="0 0 20 20"
    className={cx('icon-error', className)}
    {...props}>
    <g 
      fill="none" 
      fillRule="evenodd">
        <circle 
          cx="10" 
          cy="10" 
          r="10" 
          fill="#FFC247"/>
        <path 
          fill="#FFF" 
          fillRule="nonzero" 
          stroke="#FFF" 
          strokeWidth=".7" 
          d="M10 11.673c-.432 0-.781-.218-.781-.487V5.16c0-.27.35-.487.781-.487.432 0 .781.218.781.487v6.026c0 .27-.35.487-.781.487zm-.879 3.148a.879.879 0 1 0 1.758 0 .879.879 0 0 0-1.758 0z"/>
    </g>
  </svg>
)

export default FailSVG
