import React from 'react'
import cx from 'classnames'
import styled, { css } from 'styled-components'

export const RequiredMark = ({ className }: { className?: string }) => (
  <span
    className={cx('text-danger ml-2', className)}
    style={{ position: 'relative', top: 3 }}>
    *
  </span>
)

type Props = {
  fontWeight?: string | number
}

const BaseColumn = styled.div<Props>`
  padding: 7px 8px 7px 16px;
  font-size: 14px;
  border-bottom: none;
  word-break: break-all;

  :nth-child(odd) {
    color: ${p => p.theme.block.title};
  }

  :nth-child(even) {
    color: ${p => p.theme.block.text};
  }

  &.is--light-grey {
    background: ${p => p.theme.block.content};
  }

  ${p =>
    p.fontWeight &&
    css`
      font-weight: ${p.fontWeight};
    `}
`

const LightGreyColumn = styled(BaseColumn)`
  display: flex;
  align-items: center;
  width: 120px;
  border-right: 1px solid none;
  background: ${p => p.theme.block.content};
`

interface ColumnProps {
  className?: string
  style?: React.CSSProperties
  required?: boolean
  fontWeight?: string | number
  children?: React.ReactNode
}

class Column extends React.Component<ColumnProps, any> {
  public static LightGrey: typeof LightGreyColumnComponent
  public static White: typeof WhiteColumnComponent

  public render() {
    return <BaseColumn {...this.props}>{this.props.children}</BaseColumn>
  }
}

const WhiteColumn = styled(BaseColumn)`
  display: flex;
  align-items: center;
  width: 120px;
  background: #fff;
  border-right: 1px solid transparent;
`

class WhiteColumnComponent extends React.Component<ColumnProps, any> {
  public render() {
    const { required, ...props } = this.props
    return (
      <WhiteColumn {...props}>
        {this.props.children}
        {required && <RequiredMark />}
      </WhiteColumn>
    )
  }
}

class LightGreyColumnComponent extends React.Component<ColumnProps, any> {
  public render() {
    const { required, ...props } = this.props
    return (
      <LightGreyColumn {...props}>
        {this.props.children}
        {required && <RequiredMark />}
      </LightGreyColumn>
    )
  }
}

Column.White = WhiteColumnComponent
Column.LightGrey = LightGreyColumnComponent

export default Column
