import { createGlobalStyle, ThemeProps, css } from 'styled-components'
import env from '@shared/env'

import theme from '.'
import { DeviceMarkerColor } from '@services/constants'

export const cluster = css`
  .cluster {
    width: 40px;
    height: 40px;
    display: flex;
    top: 0;
    position: absolute;
    background: url(${env.imageUrlPrefix}/cluster/cluster-normal.svg) no-repeat
      center;
    z-index: -1;
    background-size: 100%;
    border: 1.5px solid #fff;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: #fff;

    &.alarm {
      background-image: url(${env.imageUrlPrefix}/cluster/cluster-alarm.svg);
    }

    &.repair {
      background-image: url(${env.imageUrlPrefix}/cluster/cluster-repair.svg);
    }
  }

  .cluster img ~ div::after {
    width: 40px;
    height: 40px;
    content: '';
    display: block;
    top: 0;
    position: absolute;
    background: url(${env.imageUrlPrefix}/cluster/cluster-normal.svg) no-repeat
      center;
    z-index: -1;
    background-size: 100%;
    border: 1.5px solid #fff;
    border-radius: 50%;
  }

  .cluster.alarm,
  .cluster img[alt='alarm'] ~ div::after,
  .cluster img[alt='警報'] ~ div::after {
    background-image: url(${env.imageUrlPrefix}/cluster/cluster-alarm.svg);
  }

  .cluster.repair,
  .cluster img[alt='repair'] ~ div::after,
  .cluster img[alt='維修'] ~ div::after {
    background-image: url(${env.imageUrlPrefix}/cluster/cluster-repair.svg);
  }
`

export const markers = css`
  .marker-wrapper {
    width: 24px;
    height: 24px;
    border-radius: 0 50% 50% 50%;
    transform: rotate(-135deg);
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 75%);
    border: solid 1px #fff;

    img {
      width: 18px;
      height: 18px;
      margin: 2px;
      visibility: visible;
      padding: 3px;
      transform: rotate(135deg);
    }

    &.icon-animation {
      animation: markerbounce 0.9s;
      animation-iteration-count: infinite;
    }

    @keyframes markerbounce {
      0% {
        margin-top: 0px;
        animation-timing-function: ease-in;
      }

      50% {
        margin-top: -14px;
        animation-timing-function: ease-out;
      }

      100% {
        margin-top: 0px;
        animation-timing-function: ease-in;
      }
    }

    ${Object.keys(DeviceMarkerColor).map((device: string) =>
      Object.keys(DeviceMarkerColor[device]).map(
        status =>
          `
            &.${device}.${status} {
              background-image: ${(DeviceMarkerColor as any)[device][status]};

              &.has-child::after,
              &.has-child-alarm::after {
                content: "";
                width: 8px;
                height: 8px;
                background-color: #6dd400;
                border-radius: 50%;
                border: 1px solid rgb(255, 255, 255);
                position: absolute;
                right: 6px;
                top: 19px;
              }

              &.has-child-alarm::after {
                background-color: #ff342b;
              }
            }
          `
      )
    )}
  }
`

export const googleMap = css`
  .gm-style .style-info-box {
    width: auto !important;

    > img:first-child {
      display: none;
    }
  }

  .gm-style-iw-tc {
    display: none;
  }

  .gm-style-cc {
    display: none;
  }

  .gm-style .gm-style-iw-c {
    padding: 0 !important;
    border-radius: 0 !important;
    background-color: transparent;
    box-shadow: unset;
  }

  .gm-ui-hover-effect {
    visibility: hidden !important;
  }

  .gm-style a[href^="https://maps.google.com/maps"]
  {
    display: none !important;
  }

  .gm-style .gm-style-iw-d {
    overflow: unset !important;
  }

  .gm-style .gm-style-iw-c {
    padding-top: 32px;
    padding-left: 0 !important;
  }

  .gm-style .gm-style-iw > button > img {
    width: 18px !important;
    height: 18px !important;
    position: relative;
    right: 10px;
    top: 5px;
  }

  .gm-style .gm-style-iw-t::after {
    display: none;
  }

  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    .gm-svpc {
      width: 35px !important;
      height: 35px !important;
      border-radius: 4px !important;
      transform: scaleX(-1) !important;
      margin-top: -16px !important;
      margin-left: 17px !important;

      &:hover {
        background-color: #d1e0e7 !important;
      }
    }

    .gmnoprint > div {
      height: auto !important;
      border-radius: 4px !important;
      box-shadow: 0 2px 6px 0 rgb(0 0 0 / 30%) !important;
    }

    .gmnoprint > div > div {
      width: 100% !important;
      margin: 0 !important;
    }
  }

  img[src="https://maps.gstatic.com/mapfiles/api-3/images/drag-cross_hdpi.png"]
  {
    display: none;
  }
`

export const iconStyle = css`
  .tb-icon-lock {
    color: rgba(74, 74, 74, 0.5) !important;
    cursor: pointer;
    :hover {
      color: ${p => p.theme.icon.primaryHover} !important;
    }
  }

  svg[class*='tb-icon-'] {
    cursor: pointer;
    display: inline-block;

    :hover {
      use {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.tb-icon-location:hover {
      > g > path:nth-child(2) {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.tb-icon-edit:hover,
    &.tb-icon-minus-circle:hover,
    &.tb-icon-circle-add:hover {
      > g > g {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }
  }
`
export const buttonStyle = css`
  .global-btn--danger {
    color: ${p => p.theme.btn.danger};
    background: transparent;
    border: 1px solid ${p => p.theme.btn.danger};
    box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    text-shadow: unset;
    font-size: 16px;
    font-weight: 500;
    padding: unset;

    &.sm {
      font-size: 14px !important;
      font-weight: 500 !important;
      padding: 0px 7px !important;
      height: 24px;
    }

    &:hover,
    &:focus,
    &:active {
      color: ${p => p.theme.btn.danger};
      border: 1px solid ${p => p.theme.btn.danger};
      background: ${p => p.theme.btn.dangerHover};
    }
  }

  .global-btn--primary {
    color: ${p => p.theme.btn.text};
    background: ${p => p.theme.btn.bg};
    border: 1px solid ${p => p.theme.btn.bg};
    box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    padding: unset;

    &.sm {
      font-size: 14px !important;
      font-weight: 500 !important;
      padding: 0px 7px !important;
      height: 24px;
    }

    &:hover,
    &:focus,
    &:active {
      color: ${p => p.theme.btn.text};
      border: 1px solid ${p => p.theme.btn.bgHover};
      background: ${p => p.theme.btn.bgHover};
    }
  }

  .global-btn--ghost {
    color: ${p => p.theme.btn.bg};
    background-color: transparent;
    border: 1px solid ${p => p.theme.btn.ghostHover};
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    padding: unset;

    &.sm {
      font-size: 14px !important;
      font-weight: 500 !important;
      padding: 0px 7px !important;
      height: 24px;
    }

    &:hover,
    &:focus,
    &:active {
      color: ${p => p.theme.btn.ghostHover};
      border: 1px solid ${p => p.theme.btn.ghostHover};
    }
  }
`
export const modalStyle = css`
  &.ant-modal--is-border-radius {
    .ant-modal-content {
      border-radius: 8px !important;
    }
  }
`

export const antStyle = css`
  .anticon svg {
    vertical-align: -0.5px;
  }

  .g-upload-error {
    .ant-message-notice-content {
      width: 200px;
      border-radius: 24px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
      background-color: #fff1b8;
      .ant-message-custom-content {
        display: flex;
        align-items: center;
      }
    }
  }

  .g-response-mssage {
    .ant-message-notice-content {
      border-radius: 24px;
      box-shadow: none;
      background-color: transparent;
    }
  }

  .g-dropdown {
    .ant-dropdown-menu {
      padding: 0px;
    }
    .ant-dropdown-menu-item {
      font-size: 14px !important;
      color: ${({ theme }) => theme.darkGrey} !important;

      &:hover {
        background: ${p => p.theme.table.borderBottomHover} !important;
        color: #fff !important;
      }
    }
  }

  .blue-selected-style {
    .ant-select-item-option-selected {
      background-color: ${({ theme }) => theme.blue} !important;
      color: #fff !important;
      font-size: bold;
    }
  }
`

const antMobileStyle = css`
  :root:root {
    --adm-color-primary: ${p => p.theme.n.blue500};
  }
  --adm-color-text-secondary: ${p => p.theme.n.gray400};
  .adm-form {
    --adm-color-background: transparent;
    --border-bottom: 0px !important;
  }
  .adm-input {
    --background-color: #fff !important;
    padding: 5px 8px !important;
    --font-size: 14px !important;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }
  .adm-center-popup {
    --background-color: #fff;
    --max-width: 100vw;
  }
  .adm-list-item-content-main,
  .adm-form-item-child-inner {
    max-width: 100%;
  }
  .adm-text-area {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 0px 5px;
  }
  .adm-input-disabled {
    opacity: 1 !important;
    --background-color: #f2f2f2 !important;
    --color: #c0c0c0 !important;
  }
`

const googleTranslateStyle = css`
  body {
    top: 0px !important;
  }

  .skiptranslate {
    display: none !important;
  }

  #goog-gt-tt {
    display: none !important;
    top: 0px !important;
  }

  .goog-tooltip skiptranslate {
    display: none !important;
    top: 0px !important;
  }

  .activity-root {
    display: none !important;
  }

  .status-message {
    display: none !important;
  }

  .started-activity-container {
    display: none !important;
  }

  .VIpgJd-ZVi9od-aZ2wEe-OiiCO.VIpgJd-ZVi9od-aZ2wEe-OiiCO-ti6hGc {
    display: none !important;
  }
`

const hideflvControls = css`
  .flvplayer-container
    .flvplayer-player
    .flvplayer-controls
    .flvplayer-controls-bottom {
    display: none !important;
  }
`

const mapLibreStyle = css`
  .maplibregl-innmax-popup {
    &.left-center {
      translate: calc(50%);
    }
    .maplibregl-popup-tip {
      display: none;
    }
    .maplibregl-popup-content {
      padding: 0;
    }
  }
  .maplibregl-innmax-tooltip {
    .maplibregl-popup-tip {
      border-top-color: #fff1b8 !important;
    }
    .maplibregl-popup-content {
      background: #fff1b8 !important;
      padding: 5px 10px;
      border: 1px solid transparent !important;
      box-shadow: none !important;
    }
  }
`

const GlobalStyles = createGlobalStyle<ThemeProps<typeof theme>>`
  html, body {
    position: relative;
    font-size:16px;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
      'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-variant: tabular-nums;
    line-height: 1.5;
    font-feature-settings: "tnum","tnum";
    -webkit-font-feature-settings: "tnum";
    -webkit-font-smoothing: subpixel-antialiased;
  }

  #root {
    position: relative;
  }

  ${googleMap}
  ${markers}
  ${cluster}

  ${iconStyle}
  ${buttonStyle}
  ${modalStyle}
  ${antStyle}
  ${antMobileStyle}
  ${googleTranslateStyle}
  ${hideflvControls}
  ${mapLibreStyle}
`

export default GlobalStyles
