import { SVGProps } from 'react'

const HumiditySVG = (props: SVGProps<any>) => (
  <svg
    viewBox="0 0 96 96"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path id="a" d="M.416.247H19.13V41.08H.416z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h96v96H0z" />
      <path
        d="M43.54 89.164c-2.58 1.052-5.423 1.633-8.459 1.633-16.661 0-27.507-17.535-20.053-32.448L35.08 18.23l2.796 5.58-16.5 32.988c-7.454 14.913 3.382 32.465 20.054 32.465.712 0 1.408-.037 2.11-.1"
        fill="#FFF"
      />
      <path
        d="M37.877 23.811 55.145 58.35c6.099 12.19-.047 26.151-11.605 30.815-.701.063-1.397.1-2.11.1-16.671 0-27.507-17.552-20.053-32.465l16.5-32.988zm6.796 34.676c-1.371.885-2.13 2.874-1.136 4.345.293.44.55.906.78 1.382.257.675.45 1.371.576 2.083.068.717.073 1.435.005 2.157-.094.539-.225 1.078-.398 1.602-.539 1.575.613 3.538 2.215 3.905 1.758.397 3.334-.53 3.905-2.22 1.345-3.947.727-8.647-1.602-12.113-.466-.69-1.078-1.23-1.9-1.455-.76-.21-1.77-.12-2.445.314z"
        fill="#FFD63C"
      />
      <g transform="translate(57.125 5.272)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M19.129 39.542a12.62 12.62 0 0 1-6.083 1.539c-9.375 0-15.484-9.873-11.275-18.253L13.046.246l2.795 5.58-7.736 15.458c-4.151 8.317 1.79 18.07 11.024 18.258"
          fill="#FFF"
          mask="url(#b)"
        />
      </g>
      <path
        d="M72.966 11.099 81.462 28.1c3.188 6.376.43 13.6-5.208 16.714-9.234-.188-15.175-9.94-11.024-18.258L72.966 11.1zm3.614 17.275c-.623 0-1.366.277-1.795.743-.445.487-.775 1.12-.744 1.8.032.226.058.446.09.67.078.283.22.535.382.77.115.152.193.325.282.492.037.105.063.22.09.325.004.115.004.225 0 .335a3.055 3.055 0 0 1-.116.429c-.01.042-.02.078-.026.12l-.005.01v-.004c-.032.136-.063.261-.068.397-.079.34-.063.676.041 1.016.163.586.618 1.225 1.168 1.518.591.309 1.303.466 1.958.256l.607-.256c.382-.225.685-.534.91-.91.152-.362.32-.708.425-1.084.073-.273.104-.55.14-.828.043-.293.08-.575.074-.868 0-.393-.068-.796-.126-1.178-.172-1.136-.764-2.141-1.491-3.01a1.94 1.94 0 0 0-.817-.524 1.91 1.91 0 0 0-.979-.22z"
        fill="#FFD63C"
      />
    </g>
  </svg>
)

export default HumiditySVG
