import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { slice } from 'ramda'
import { ColumnProps } from 'antd/lib/table'
import { ReportTable } from '@shared/Table'

import { ILinkRate } from '@services/report'
import ReportTitle from '../CommonTitle'
import type { ICommonProps } from '../type'

export const pageSize = 10
function MaintenanceReport({ queryVariables, dataSource, loading, ...props }: ICommonProps) {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = React.useState(1)

  const columns: ColumnProps<ILinkRate>[] = [
    {
      dataIndex: 'createAtStr',
      title: t('repair:report date'),
      width: 160,
      render: v => <span className="text-xs">{v}</span>
    },
    {
      title: t('repair:code'),
      dataIndex: 'ticketNo',
      width: 120,
    },
    {
      title: t('report:device display name'),
      dataIndex: 'deviceDisplayName',
    },
    {
      title: t('repair:device type'),
      dataIndex: 'deviceTypeStr',
    },
    {
      dataIndex: 'sessionName',
      title: t('light:sessionName'),
      width: 120,
    },
    {
      dataIndex: 'repairerName',
      title: '維修廠商',
    },
    {
      dataIndex: 'reportData',
      title: '報修原因',
      render: v => v.reason
    },
    {
      dataIndex: 'repairTimeSecStr',
      title: '維修時間',
    },
    {
      title: t('repair:end date'),
      dataIndex: 'closedAtStr',
      render: v => <span className="text-xs">{v}</span>
    },
    {
      dataIndex: 'reviewData',
      title: '結案原因',
      render: v => v?.reason
    },
    {
      dataIndex: 'reportData',
      title: '備註',
      render: v => v.note
    },
  ]

  return (
    <Row className="mt-42">
      <ReportTitle total={dataSource.content.length} {...props} />
      <Col span={24}>
        <ReportTable
          rowKey="id"
          className="report-page"
          dataSource={{
            ...(dataSource as any),
            page: currentPage,
            content: slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize,
              dataSource.content
            ),
          }}
          loading={loading}
          columns={columns}
          pagination={{
            showSizeChanger: false,
            total: dataSource?.content.length || 1,
            pageSize,
          }}
          onChange={(p: any) => setCurrentPage(p.current || 1)}
        />
      </Col>
    </Row>
  )
}

export default React.memo(MaintenanceReport)
