import { SVGProps } from 'react'

const Menu = ({ color = '#0074A8', ...props }: SVGProps<any>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 12h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0-6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Menu
