const Report = ({ ...props }) => {
  return (
    <i className="icon-report" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20">
        <path
          fill="#878787"
          d="M17.314 2c.379 0 .686.307.686.686 0 .351-.265.641-.606.68l-.08.005h-.686v11.223c0 .345-.253.63-.584.678l-.101.008-3.957-.001 1.91 1.495c.298.234.35.665.116.963-.215.275-.599.341-.891.166l-.071-.049L10 15.465l-3.05 2.39c-.275.215-.663.187-.905-.054l-.057-.064c-.216-.275-.188-.664.052-.905l.065-.058 1.908-1.495H4.057c-.344 0-.629-.253-.678-.583l-.007-.102L3.37 3.371h-.685c-.379 0-.686-.307-.686-.685 0-.352.265-.642.606-.681L2.686 2h14.628zm-2.057 1.371H4.742v9.012l1.713-2.6c.178-.27.519-.376.815-.263l.08.036 1.293.688.697-3.453c.104-.52.726-.72 1.114-.389l.062.06 1.374 1.492 1.105-1.071c.251-.244.64-.257.906-.044l.064.059c.243.25.256.64.043.905l-.058.064-1.61 1.562c-.255.248-.653.256-.918.033l-.064-.06-.94-1.022-.612 3.038c-.085.422-.528.655-.917.505l-.077-.035-1.556-.829-1.876 2.848 9.877.001V3.371z"/>
      </svg>
    </i>
  )
}

export default Report
