import moment from 'moment'
import { INTERVAL_TYPE } from '.'

export const dateTimeUnit = (intervalType: INTERVAL_TYPE = INTERVAL_TYPE.YEAR) => (
  intervalType === INTERVAL_TYPE.YEAR ? 'month' : 'day'
)

export const dateTimeFormat = (intervalType: INTERVAL_TYPE = INTERVAL_TYPE.YEAR, datetime: moment.Moment | number) => (
  intervalType === INTERVAL_TYPE.YEAR ? moment(datetime).format('YYYY.MM') :
  intervalType === INTERVAL_TYPE.MONTH ? moment(datetime).format('MM.DD') :
  moment(datetime).format('YYYY.MM.DD')
)

export const startDateTime = (intervalType: INTERVAL_TYPE = INTERVAL_TYPE.YEAR, datetime?: moment.Moment | number) => (
  intervalType === INTERVAL_TYPE.YEAR ? moment(datetime).startOf('year').valueOf() :
  intervalType === INTERVAL_TYPE.MONTH ? moment(datetime).startOf('month').valueOf() :
  intervalType === INTERVAL_TYPE.WEEK ? moment(datetime).startOf('week').valueOf() :
  moment(datetime).startOf('day').valueOf()
)

export const endDateTime = (intervalType: INTERVAL_TYPE = INTERVAL_TYPE.YEAR, datetime?: moment.Moment | number) => (
  intervalType === INTERVAL_TYPE.YEAR ? moment(datetime).endOf('year').valueOf() :
  intervalType === INTERVAL_TYPE.MONTH ? moment(datetime).endOf('month').valueOf() :
  intervalType === INTERVAL_TYPE.WEEK ? moment(datetime).endOf('week').valueOf() :
  moment(datetime).endOf('day').valueOf()
)

export const conditionDate = (intervalType: INTERVAL_TYPE = INTERVAL_TYPE.YEAR, datetime?: moment.Moment | number) => (
  intervalType === INTERVAL_TYPE.YEAR ? moment(datetime).startOf('year').format('YYYY') :
  intervalType === INTERVAL_TYPE.MONTH ? moment(datetime).startOf('month').format('YYYY/MM') :
  intervalType === INTERVAL_TYPE.WEEK ? moment(datetime).startOf('week').valueOf() :
  moment(datetime).startOf('day').format('YYYY/MM')
)

export const generateDatetime = (intervalType: INTERVAL_TYPE, start: number | moment.Moment, end: number | moment.Moment) => {
  const datetime: any = []
  const unit: moment.unitOfTime.StartOf = intervalType === INTERVAL_TYPE.YEAR ? 'month' : 'day'
  for (
    let i = moment(end).startOf(unit);
    moment(i)?.diff(moment(start), unit) >= 0;
    i = moment(i).add(-1, unit)?.startOf(unit)
  ) {
    datetime[moment(i).valueOf()] = [
      moment(i).valueOf(),
      0,
      moment(i).format(dateTimeFormat(intervalType, i))
    ]
  }

  return datetime
}

export default INTERVAL_TYPE