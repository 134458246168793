import { append, includes, mergeDeepRight, propOr } from 'ramda'
import { QueryKey, UseQueryOptions, useQuery } from 'react-query'

class PersistQueryKeyStorage {
  private _persistMap: Record<string, QueryKey[]> = {}

  public setQueryKey = (persistKeys: number[], queryKey: QueryKey) => {
    persistKeys.forEach(persistKey => {
      let queryKeys: QueryKey[] = propOr([], persistKey + '', this._persistMap)
      if (!includes(queryKey, queryKeys)) {
        queryKeys = append(queryKey, queryKeys)
      }

      this._persistMap = mergeDeepRight(this._persistMap, {
        [persistKey]: queryKeys,
      }) as Record<string, QueryKey[]>
    })
  }

  public getQueryKey = (persistKey: number): QueryKey[] => {
    return propOr([], persistKey + '', this._persistMap)
  }
}

export const persistQueryKeyStorage = new PersistQueryKeyStorage()

/**
 *
 * example:
 *  usePersistQuery(['apiUrl', {queryVariables}], [-51], options)
 *
 * @param queryKey
 * @param refetchedErrorCodes  接到指定 error codes 時，會觸發 refetch
 * @param queryFun
 * @param options
 * @returns
 */
export function usePersistQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData
>(
  queryKey: QueryKey,
  options: UseQueryOptions<TQueryFnData, TError, TData> & {
    refetchedErrorCodes: number[]
  }
) {
  const { refetchedErrorCodes, ...others } = options
  persistQueryKeyStorage.setQueryKey(refetchedErrorCodes, queryKey)
  return useQuery<TQueryFnData, TError, TData>(
    queryKey,
    mergeDeepRight<
      UseQueryOptions<TQueryFnData, TError, TData>,
      UseQueryOptions<TQueryFnData, TError, TData>
    >(others, {
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }) as UseQueryOptions<TQueryFnData, TError, TData>
  )
}
