import { Select, SelectProps } from '@shared/Form/Select'
import { useGroups, IGroup } from '@services/schedule'
import { map, compose } from 'ramda'

export default function GroupSelect({ ...props }: SelectProps) {
  const { data, isLoading } = useGroups()

  return (
    <Select allowClear loading={isLoading} {...props}>
      {compose(
        map((x: IGroup) => (
          <Select.Option key={x.id} value={x.id}>
            {x.lightGroupName}
          </Select.Option>
        ))
      )(data)}
    </Select>
  )
}
