import { SVGProps } from 'react'

const FilterSVG = ({ color = '#4A4A4A', ...props }: SVGProps<any>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g
        transform="translate(3 3)"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.75">
        <path d="M0 8.575h9.8m4.9 0h2.45M0 2.45h2.45m4.895 0h9.805M0 14.7h3.675m4.9 0h8.575"/>
        <circle cx="4.9" cy="2.45" r="2.45"/>
        <circle cx="12.25" cy="8.575" r="2.45"/>
        <circle cx="6.125" cy="14.7" r="2.45"/>
      </g>
    </g>
  </svg>
)

export default FilterSVG
