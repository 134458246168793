import { SVGProps } from 'react'
import cx from 'classnames'

const NextSVG = ({ className, ...props }: SVGProps<any>) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="16" 
    height="16" 
    viewBox="0 0 16 16">
    <g 
      fill="none" 
      fillRule="evenodd">
      <g>
        <g>
          <g>
            <path 
              d="M0 0H16V16H0z" 
              transform="translate(-1417.000000, -836.000000) translate(1410.000000, 816.000000) translate(7.000000, 20.000000)"/>
            <g 
              fill="#0074A8" 
              fillRule="nonzero">
              <path d="M.697 11.995c.15 0 .301-.05.428-.15L7.747 6.52c.16-.13.255-.325.255-.533 0-.206-.094-.403-.255-.531L1.153.156C.86-.08.43-.033.193.261c-.235.294-.188.723.105.96L6.23 5.987.269 10.78c-.294.236-.34.666-.105.96.136.167.333.254.533.254z" transform="translate(-1417.000000, -836.000000) translate(1410.000000, 816.000000) translate(7.000000, 20.000000) translate(4.000000, 2.000000)"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default NextSVG
