import { pathOr } from 'ramda'
import history from '@shared/innmaxLib/routes/history'
import { useAuth, UserPermission, ActionTypes } from '@services/auth'

export default function useRouteAuth() {
  const { user } = useAuth()

  const pathname = history.location.pathname === '/' ? '/dashboard' : history.location.pathname

  const routeAuth = pathOr(
    {},
    ['authByUrl', pathname],
    user
  ) as UserPermission

  return [routeAuth, (actionType: ActionTypes) => routeAuth[actionType]] as [
    UserPermission,
    (act: string) => boolean
  ]

  // return [routeAuth, (act: ActionTypes) => routeAuth[act]] as [
  //   UserPermission,
  //   (act: string) => boolean
  // ]
}
