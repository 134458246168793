import React from 'react'
import i18n from 'i18next'
import { pathOr, equals, is, split, last } from 'ramda'
import { Upload, Modal } from 'antd'
import ReactModal from 'react-modal'
import { rgba } from 'polished'
import { UploadChangeParam, RcFile, UploadProps } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import styled from 'styled-components'
import envStore from '@shared/env'
import { toBase64, uuid } from '@shared/innmaxLib/utils/webHelper'
import { isMobile } from '@shared/innmaxLib/utils/browser-detect'
import Box from '../../Box'
import { EyeOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Message } from '@shared/innmaxUI'
const { match, compose } = require('ramda')

export type UploadedFile = UploadFile & {
  file?: RcFile
}

type Props = UploadProps & {
  value?: string | object | null
  readOnly?: boolean
  onPreview?: (image: any) => any
  field: string
  render?: () => React.ReactNode
}

type State = {
  fileList: UploadedFile[]
  previwVisible: boolean
  value: any
  base64Url: null | string
  loading: boolean
}

const StyledModal = styled(Modal)`
  animation-duration: 0s !important;
  transition: none !important;

  &.ant-modal {
    .ant-modal-content {
      background: transparent;
    }

    .ant-modal-close-x {
      margin-right: 5px;
      margin-top: -23px;
      color: #fff;
      font-size: 28px;
    }
  }
`

export class ImageUpload extends React.PureComponent<Props, State> {
  public state = {
    fileList: this.props.value
      ? ([{ uid: uuid(), url: this.props.value }] as any)
      : [],
    value: this.props.value || null,
    previwVisible: false,
    base64Url: null,
    loading: false,
  }

  loadImage = () => {
    const { value } = this.props

    this.setState({ value })
  }

  componentDidMount() {
    this.loadImage()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { value } = this.props

    if (!equals(prevProps.value, value)) {
      // this.setState({ value })
      this.loadImage()
    }
  }

  handleRemove = (evt?: React.MouseEvent<HTMLElement>) => {
    if (evt) {
      evt.stopPropagation()
      evt.preventDefault()
    }

    if (!this.props.readOnly) {
      this.setState({ value: null, base64Url: null }, () =>
        this.triggerChange(null)
      )
    }
  }

  handlePreview = (evt?: React.MouseEvent<HTMLElement>) => {
    if (evt) {
      evt.preventDefault()
      evt.stopPropagation()
    }

    const { value, onPreview } = this.props

    if (onPreview) {
      onPreview(
        pathOr<any>(
          value ? `${envStore.apiBaseUrl}${value}` : null,
          ['url'],
          value
        )
      )
    } else {
      this.setState(prev => ({ ...prev, previwVisible: !prev.previwVisible }))
    }
  }

  customRequest = ({ file }: UploadChangeParam<File>) => {
    toBase64(file).then(url => {
      let newFile = {
        uid: uuid(),
        url,
        name: file.name,
        status: 'done',
        file,
      }

      this.setState(
        state => ({ value: newFile as any }),
        () => this.triggerChange(this.state.value)
      )
    })
  }

  triggerChange = (changedValue: any) => {
    // Should provide an event to pass value to Form.
    const { onChange } = this.props
    if (onChange) {
      onChange(changedValue)
    }
  }

  // NOTE: 必須要給空的事件處理，不然會報錯
  onChange = () => {}

  handlePreviewRemove = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault()
    evt.stopPropagation()

    this.handleRemove()
    this.handlePreview()
  }

  renderImg = (children: React.ReactNode) => {
    if (!is(String, this.props.value)) {
      return children
    }

    return (
      <a
        download={compose(pathOr('', []), last, split('/'))(this.props.value)}
        href={pathOr('', ['props', 'src'], children)}
        css={{ cursor: 'default' }}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
        }}>
        {children}
      </a>
    )
  }

  render() {
    const {
      props: { readOnly = false, render },
      state: { fileList, previwVisible, value, base64Url },
    } = this

    const url = pathOr<any>(base64Url, ['url'], value)

    const isMobileDevice = isMobile()
    return (
      <>
        {!isMobileDevice && previwVisible && url && (
          <StyledModal
            visible
            maskStyle={{ background: rgba(0, 0, 0, 0.9) }}
            width="100%"
            style={{ top: 30 }}
            bodyStyle={{
              background: 'transparent',
              display: 'flex',
              justifyContent: 'center',
            }}
            footer={null}
            onCancel={this.handlePreview}>
            {this.renderImg(
              <img
                alt="example"
                style={{
                  maxWidth: '100%',
                  width: 'auto',
                  display: 'block',
                  boxShadow:
                    '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
                }}
                src={url}
              />
            )}
          </StyledModal>
        )}
        {isMobileDevice && previwVisible && url && (
          <ReactModal
            isOpen
            ariaHideApp={false}
            onRequestClose={this.handlePreview as any}
            style={{
              overlay: { background: '#000', zIndex: 1001 },
              content: {
                background: '#000',
                border: 'none',
                top: 10,
                left: 0,
                right: 0,
                bottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
              },
            }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              color="#fff"
              className="pr-16 pl-22 mb-128">
              <Box
                className="text-500"
                fontSize="17px"
                position="relative"
                margin="0 auto">
                {i18n.t('common:photo preview')}
              </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              {this.renderImg(
                <img
                  alt="example"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                    maxHeight: 254,
                    boxShadow:
                      '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
                  }}
                  src={url}
                />
              )}
            </Box>
            <Box position="absolute" bottom="0" width="100%" display="flex">
              <Box
                className="bg-darkGrey text-white text-largest text-500"
                height="56px"
                width="50%"
                display="flex"
                alignItems="center"
                mr="1px"
                justifyContent="center"
                onClick={this.handlePreview}>
                {i18n.t('common:cancel')}
              </Box>
              <Box
                className="bg-darkGrey text-white text-largest text-500"
                height="56px"
                width="50%"
                display="flex"
                alignItems="center"
                onClick={this.handlePreviewRemove}
                justifyContent="center">
                {i18n.t('common:delete')}
              </Box>
            </Box>
          </ReactModal>
        )}
        <UploadComponent
          {...this.props}
          accept="image/*"
          listType="picture-card"
          fileList={fileList}
          openFileDialogOnClick={!readOnly}
          multiple={false}
          showUploadList={false}
          onChange={this.onChange}
          beforeUpload={beforeUpload}
          customRequest={this.customRequest as any}>
          {url ? (
            <Box position="relative">
              {!isMobileDevice &&
                this.renderImg(
                  <Box
                    className="image-upload__overlay"
                    position="absolute"
                    top={0}
                    right={0}
                    width="100%"
                    height="100%"
                    color="#fff"
                    fontSize="16px">
                    <EyeOutlined
                      type="eye"
                      style={{
                        position: 'relative',
                        top: '35%',
                        marginRight: 10,
                      }}
                      onClick={this.handlePreview}
                    />
                    <DeleteOutlined
                      type="delete"
                      style={{
                        position: 'relative',
                        top: '35%',
                      }}
                      onClick={this.handleRemove}
                    />
                  </Box>
                )}
              <img
                src={url}
                alt="avatar"
                style={{ height: 75, width: 75 }}
                onClick={this.handlePreview}
              />
            </Box>
          ) : render ? (
            render()
          ) : (
            <div>
              {!readOnly && (
                <PlusOutlined type={'plus'} css={{ fontSize: 18 }} />
              )}
              <div className="ant-upload-text">
                {readOnly
                  ? i18n.t('common:not upload photo')
                  : i18n.t('common:upload photo')}
              </div>
            </div>
          )}
        </UploadComponent>
      </>
    )
  }
}

const StyledUpload = styled(Upload)`
  animation: none !important;
  transition: none !important;

  .ant-upload.ant-upload-select-picture-card {
    width: 93px;
    height: 93px;
  }

  &.ant-upload-picture-card-wrapper {
    width: auto;
  }

  &.is--xs.ant-upload-picture-card-wrapper {
    width: 100%;
    height: 64px;
  }

  &.is--sm .ant-upload.ant-upload-select-picture-card,
  &.is--xs .ant-upload.ant-upload-select-picture-card {
    background: transparent;
    border: 1px solid ${p => p.theme.primary};
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;

    .ant-upload {
      color: ${p => p.theme.primary};
      padding: 0;
    }
  }

  &.is--sm .ant-upload.ant-upload-select-picture-card {
    width: 64px;
    height: 64px;

    img {
      width: 61px !important;
      height: 61px !important;
    }
  }

  &.is--xs .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 100%;

    img {
      width: 68px !important;
      height: 55px !important;
    }
  }

  .ant-upload-list-picture-card .ant-upload-list-item:hover,
  .ant-upload.ant-upload-select-picture-card:hover {
    background: ${p => p.theme.lightGrey};
    border-color: ${p => p.theme.primary};
  }

  .ant-upload-list-picture-card .ant-upload-list-item,
  .ant-upload.ant-upload-select-picture-card {
    background: ${p => p.theme.lightGrey};
    border-color: none;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    background: rgba(216, 216, 216, 0);
    border-color: none;
  }

  .ant-upload-list-picture-card
    .ant-upload-list-item:hover
    .ant-upload-list-item-info::before {
    opacity: 0;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-info::before,
  .ant-upload-list-item-info {
    animation: none !important;
    transition: none !important;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-actions {
    .anticon {
      color: ${p => p.theme.primary};
    }

    .anticon:hover {
      color: ${p => p.theme.secondary};
    }
  }

  .image-upload__overlay {
    transition: all 0.2s ease-in;
    opacity: 0;
  }

  .image-upload__overlay:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.6);
  }
`

class UploadComponent extends React.Component<
  UploadProps & {
    forwardRef?: React.Ref<any>
    ref?: any
  },
  any
> {
  public static displayName = 'Upload'

  render() {
    return <StyledUpload {...this.props}>{this.props.children}</StyledUpload>
  }
}

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    Message({ content: 'You can only upload JPG/PNG file!', type: 'error' })
  }
  const isLt2M = file.size / 1024 / 1024 < 100
  if (!isLt2M) {
    Message({ content: 'Image must smaller than 2MB!', type: 'error' })
  }
  return isJpgOrPng && isLt2M
}

export default React.memo(ImageUpload)
