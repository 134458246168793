import React from 'react'
import styled from 'styled-components'
import ReactDOMServer from 'react-dom/server'
import cx from 'classnames'
import { point, divIcon, LeafletMouseEvent, LeafletEvent, LatLngLiteral } from 'leaflet'
import { useMap, Popup, Marker as MarkerComponent } from 'react-leaflet'

import useVisible from '@shared/innmaxLib/hooks/useVisible'
import { Device } from '@services/device'
import { markerIcon } from '../../components/Marker'
import { DeviceStatus } from '@services/constants'
import { DeleteWindow } from '../../components/Window'
import Tooltip from './Tooltip'

interface MarkerComponentProps {
  item: Device
  focus?: boolean
  tooltip?: boolean
  deletable?: boolean
  draggable?: boolean
  selected?: boolean
  children?: React.ReactNode

  onLeftClick?: (d: Device) => void
  onRightClick?: (d: Device) => void
  onMouseOver?: (d: Device) => void
  onMouseOut?: (d: Device) => void
  onDrag?: (e: LeafletMouseEvent) => void
  onDragEnd?: (e: Device) => void
  onDelete?: (d: Device) => void
}

export default function Marker ({
  children,
  item,
  focus,
  tooltip = true,
  deletable = false,
  draggable = false,
  selected = false,

  onLeftClick,
  onRightClick,
  onMouseOver,
  onMouseOut,
  onDrag,
  onDragEnd,
  onDelete,
  ...others
}: MarkerComponentProps) {
  const markerRef = React.useRef<any>()

  const [markerPosition, setMarkerPosition] = React.useState<LatLngLiteral>()

  const [zindex, setZindex] = React.useState(0)

  const tooltipVisible = useVisible()

  const deleteWindow = useVisible()

  const map = useMap()

  const eventHandlers = React.useMemo(() => ({
    contextmenu() {
      deletable && deleteWindow.toggle()
      onRightClick && onRightClick(item)
    },
    click() {
      onLeftClick && onLeftClick(item)
    },
    mouseover() {
      onMouseOver && onMouseOver(item)
      tooltipVisible.open()
    },
    mouseout() {
      onMouseOut && onMouseOut(item)
      tooltipVisible.close()
    },
    drag(e: LeafletEvent) {
      setMarkerPosition((e as LeafletMouseEvent).latlng)
      onDrag && onDrag(e as LeafletMouseEvent)
      setZindex(new Date().getTime())
    },
    dragend(e: any) {
      setMarkerPosition(markerRef.current._latlng)
      onDragEnd && onDragEnd({
        ...item,
        lat: markerRef.current._latlng.lat.toFixed(15),
        lon: markerRef.current._latlng.lng.toFixed(15),
        x: Math.round(map.project(markerRef.current._latlng, 0).x),
        y: Math.round(map.project(markerRef.current._latlng, 0).y),
      })
    }
  }), []) //eslint-disable-line

  const className = React.useMemo(() => (
    cx(
      item?.deviceType,
      (
        item?.deviceStatus === DeviceStatus.ALARM ? 'alarm' :
        item?.deviceStatus === DeviceStatus.REPAIR ? 'repair' :
        'normal'
      ),
      (
        item?.subDeviceCount ? (
          item?.isSubAlarm ? 'has-child-alarm' : 'has-child'
        ) : ''
      ),
      item?.brightnessType,
      { 'selected': selected },
      { 'icon-animation': focus },
      'marker-wrapper',
    )
  ), [item, selected, focus]) //eslint-disable-line

  const icon = React.useMemo(() => {
    const markerImg = markerIcon(item)
    if (markerImg) {
      return divIcon({
        iconSize: [33.94, 33.94],
        iconAnchor: [28 / 2, 28],
        popupAnchor: [120, 140],
        className: "",
        html: ReactDOMServer.renderToString(
          <div className={className}>
            <img style={{ padding: 3 }} src={markerImg} alt="" />
          </div>
        )
      })
    }
  }, [item, className]) //eslint-disable-line

  React.useEffect(() => {
    map && item?.x && item?.y && setMarkerPosition(
      map.unproject({ x: item?.x, y: item?.y } as any, 0)
    )
  }, [item, map])

  if (!markerPosition?.lat || !markerPosition?.lng) {
    return <div/>
  }

  return (
    <>
      {
        deleteWindow.visible && (
          <StylePopup
            position={markerPosition}
            offset={[100, 24]}
            eventHandlers={{
              remove: deleteWindow.toggle,
            }}>
            <DeleteWindow
              data={item}
              onCancel={deleteWindow.toggle}
              deleteMarker={() => onDelete && onDelete(item)}
            />
          </StylePopup>
        )
      }

      <MarkerComponent
        ref={markerRef}
        position={markerPosition}
        eventHandlers={eventHandlers}
        draggable={draggable}
        icon={icon}
        zIndexOffset={zindex}>
        {
          tooltipVisible.visible && (
            <Tooltip
              className="skip-toggle-dialog"
              permanent
              direction="top"
              offset={point(-2, -35)}>
              {item?.displayName || item?.deviceDisplayName}
            </Tooltip>
          )
        }
      </MarkerComponent>
    </>
  )
}

const StylePopup = styled(Popup)`
  .leaflet-popup-content {
    margin: 0px;
  }

  .leaflet-popup-content-wrapper {
    box-shadow: none;
    padding: 0px;
    background-color: transparent;
  }

  .leaflet-popup-tip-container {
    display: none;
  }

  .leaflet-popup-close-button {
    display: none;
  }
`