import { SVGProps } from 'react'

const ZoomInSVG = ({ color = '#4A4A4A', ...props }: SVGProps<any>) => (
  <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M21.69 13.213h-6.968V6.42a.787.787 0 1 0-1.575 0v6.793H6.333a.787.787 0 1 0 0 1.575h6.814v6.814a.787.787 0 1 0 1.575 0v-6.814h6.967a.787.787 0 1 0 0-1.575z"
        fill="#4A4A4A"
        fillRule="nonzero"
        stroke="#4A4A4A"
      />
    </g>
  </svg>
)

export default ZoomInSVG
