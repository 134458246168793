import React from 'react'
import { useRecoilState } from 'recoil'
import { MapActiveMarkersState, MapActiveMarkerState } from '../map.state'

import { Device } from '@services/device'

type ContextType = {
  map: google.maps.Map | undefined
  isLoaded: boolean
  isStreetMode: boolean
  setIsStreetMode: (visible: boolean) => void
  handleMapLoad: (map: google.maps.Map | undefined) => void
  handleActiveMarkers: (devices: Device[]) => void
  handleActiveMarker: (device: Device | undefined) => void
  smoothMapZoom: (
    targetZoom: number,
    callback?: any | undefined,
    commandedZoom?: number
  ) => void
}

const MapContext = React.createContext<ContextType>({
  map: undefined,
  isLoaded: false,
  isStreetMode: false,
  setIsStreetMode: (visible: boolean) => ({}),
  handleMapLoad: (map: google.maps.Map | undefined) => ({}),
  handleActiveMarkers: (devices: Device[]) => {},
  handleActiveMarker: (device: Device | undefined) => {},
  smoothMapZoom: (
    targetZoom: number,
    callback?: any | undefined,
    commandedZoom?: number
  ) => ({}),
})

export function GoogleMapProvider (props: any) {
  const [map, setMap] = React.useState<google.maps.Map | undefined>()

  const [isLoaded, setIsLoaded] = React.useState(false)

  const [isStreetMode, setIsStreetMode] = React.useState(false)

  const [, handleActiveMarkers] = useRecoilState(MapActiveMarkersState)

  const [, handleActiveMarker] = useRecoilState(MapActiveMarkerState)

  const handleMapLoad = React.useCallback((map: google.maps.Map | undefined) => {
    setMap(map)
    setIsLoaded(map ? true : false)
  }, [])

  const smoothMapZoom = React.useCallback((
    targetZoom: number,
    callback?: any | undefined,
    commandedZoom?: number
  ) => {
    if (!map) {
      return
    }
    let zoomListener
    const currentZoom = commandedZoom || map.getZoom() || 10
    if (currentZoom === targetZoom) {
      setTimeout(() => callback && callback())
      zoomListener && google.maps.event.removeListener(zoomListener)
    } else {
      const _zoom = currentZoom + (targetZoom > currentZoom ? 1 : -1)
      zoomListener = google.maps.event.addListenerOnce(
        map, 'zoom_changed', () => smoothMapZoom(targetZoom, undefined, _zoom)
      )
      setTimeout(() => map.setZoom(_zoom), 160)
    }
  }, [map])

  const value = React.useMemo(() => {
    return {
      map,
      isLoaded,
      isStreetMode,
      setIsStreetMode,
      handleMapLoad,
      smoothMapZoom,
      handleActiveMarkers,
      handleActiveMarker,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, isLoaded, isStreetMode, handleMapLoad])

  return <MapContext.Provider value={value} {...props} />
}

export function useGoogleMap() {
  const context = React.useContext(MapContext)
  if (!context) {
    throw new Error('useGoogleMap must be within a MapProvider')
  }

  return context
}

export { MapActiveMarkersState, MapActiveMarkerState } from '../map.state'

export default GoogleMapProvider