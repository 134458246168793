import InternalGoogleMap from './GoogleMap'
import {
  useGoogleMap,
  GoogleMapProvider,
  MapActiveMarkersState,
  MapActiveMarkerState,
} from './GoogleMapProvider'
import Marker from './Marker'
import AreaMarker from './AreaMarker'
import Clusterer from './Clusterer'

type InternalGoogleMapType = typeof InternalGoogleMap

interface GoogleMapInterface extends InternalGoogleMapType {
  Provider: typeof GoogleMapProvider
  Marker: typeof Marker
  AreaMarker: typeof AreaMarker
  Clusterer: typeof Clusterer
  useGoogleMap: typeof useGoogleMap
}

const GoogleMap = InternalGoogleMap as GoogleMapInterface

GoogleMap.Provider = GoogleMapProvider
GoogleMap.Marker = Marker
GoogleMap.AreaMarker = AreaMarker
GoogleMap.Clusterer = Clusterer
GoogleMap.useGoogleMap = useGoogleMap

export {
  MapActiveMarkersState,
  MapActiveMarkerState,
}

export default GoogleMap
