import maplibregl, { Marker, Popup as IPopup } from 'maplibre-gl'
import React from 'react'
import { useMapLibre } from '../Provider'
import ReactDOM from 'react-dom/client'
import { useMapLibreMarker } from '../Marker/MarkerProvider'

type IPopupProps = {
  marker?: Marker | null
  visible?: boolean
  onClose?: () => void
  onOpen?: () => void
}

const Popup = React.memo((props: React.PropsWithChildren<IPopupProps>) => {
  const { marker } = useMapLibreMarker()
  const { children, visible = false, onClose, onOpen } = props

  const { map } = useMapLibre()

  const [popup, setPopup] = React.useState<IPopup | null>(null)

  React.useEffect(() => {
    if (!popup) {
      return
    }
    if (!visible) {
      popup.remove()
      return
    }

    if (!marker || !map) {
      return
    }
    popup
      .setOffset({
        center: [marker.getElement().getBoundingClientRect().width / 2 + 15, 0],
      } as any)
      .setLngLat(marker.getLngLat())
      .addTo(map)
  }, [visible, popup, marker, map])

  React.useEffect(() => {
    if (!popup) {
      return
    }
    const handleOpen = () => {
      onOpen && onOpen()
    }
    const handleClose = () => {
      onClose && onClose()
    }
    popup.on('open', handleOpen)
    popup.on('close', handleClose)

    return () => {
      popup.off('open', handleOpen)
      popup.off('close', handleClose)
    }
  }, [onClose, onOpen, popup])

  React.useEffect(() => {
    const _popup = new maplibregl.Popup({
      className: 'maplibregl-innmax-popup left-center',
      closeButton: false,
      anchor: 'center',
    })

    if (typeof children === 'string') {
      _popup.setText(children)
    } else {
      const root = document.createElement('div')
      ReactDOM.createRoot(root).render(children)
      _popup.setDOMContent(root)
    }

    setPopup(_popup)

    return () => {
      _popup.remove()
    }
  }, [children])

  return <></>
})

export default Popup
