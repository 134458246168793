const Brightness = ({ color = '#fff', ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g fill={color} fillRule="nonzero">
        <path d="M8 0a.469.469 0 0 0-.469.469v1a.469.469 0 0 0 .938 0v-1A.469.469 0 0 0 8 0zM8 14.063a.469.469 0 0 0-.469.468v1a.469.469 0 0 0 .938 0v-1A.469.469 0 0 0 8 14.062zM3.735 3.072l-.707-.707a.469.469 0 0 0-.663.663l.707.707a.467.467 0 0 0 .663 0 .469.469 0 0 0 0-.663zM13.635 12.972l-.707-.707a.469.469 0 0 0-.663.663l.707.707a.467.467 0 0 0 .663 0 .469.469 0 0 0 0-.663zM1.469 7.531h-1a.469.469 0 0 0 0 .938h1a.469.469 0 0 0 0-.938zM15.531 7.531h-1a.469.469 0 0 0 0 .938h1a.469.469 0 0 0 0-.938zM3.735 12.265a.469.469 0 0 0-.663 0l-.707.707a.469.469 0 0 0 .663.663l.707-.707a.469.469 0 0 0 0-.663zM13.635 2.365a.469.469 0 0 0-.663 0l-.707.707a.469.469 0 0 0 .663.663l.707-.707a.469.469 0 0 0 0-.663zM8 3.031A4.974 4.974 0 0 0 3.031 8 4.974 4.974 0 0 0 8 12.969 4.974 4.974 0 0 0 12.969 8 4.974 4.974 0 0 0 8 3.031zm.469 8.973V3.996A4.037 4.037 0 0 1 12.03 8c0 2.064-1.56 3.77-3.562 4.004z"/>
      </g>
    </svg>
  )
}

export default Brightness
