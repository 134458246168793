import React from 'react'
import styled from 'styled-components'
import { Select } from 'antd'
import { SelectValue, SelectProps as AntSelectProps } from 'antd/lib/select'

interface StyledSelectProps<T = SelectValue> extends AntSelectProps<T> {
  forwardRef?: React.Ref<any>
  ref?: any
}

export interface SelectProps<T = SelectValue> extends StyledSelectProps<T> {
  render?: (values: any) => any
  useForm?: boolean
}

const StyledSelect: any = styled(Select)`
  &.ant-select {
    .ant-select-selector {
      border-radius: 4px;
    }

    .ant-select-selector:hover,
    .ant-select-selector:active,
    .ant-select-selector:focus {
      border-color: ${p => p.theme.primary};
    }

    .ant-select-selector {
      border-color: ${p => p.theme.gray88};
    }

    .ant-select-selector:active,
    .ant-select-selector:focus {
      box-shadow: none;
    }

    .ant-select-arrow-icon {
      position: relative;
      top: -1px;
      font-size: 14px;
    }

    &.selection__multiple-items--hide
      .ant-select-selection--multiple
      .ant-select-selection__choice__disabled {
      display: none;
    }

    &.selection__schedule-items {
      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: ${p => p.theme.info};
        color: #fff !important;
      }
      .ant-select-item {
      }
    }
  }
`

export default class SelectComponent<T = any> extends React.Component<
  StyledSelectProps<T>,
  any
> {
  public static Option = Select.Option

  public static OptionGroup = Select.OptGroup

  public static displayName = 'Select'

  render() {
    const { forwardRef, ...others } = this.props
    return (
      <StyledSelect ref={forwardRef} {...others}>
        {this.props.children}
      </StyledSelect>
    )
  }
}

export { SelectComponent as Select }
