import styled from 'styled-components'
import { Slider } from 'antd'
import { SliderSingleProps } from 'antd/lib/slider'

const StyledSlider = styled((props: SliderSingleProps) => (
  <Slider {...props} />
))`
  &.ant-slider {
    .ant-slider-rail,
    .ant-slider-track,
    .ant-slider-step {
      height: 8px;
    }

    .ant-slider-step {
      border: 1.5px solid ${p => p.theme.slider};
      border-radius: 4px;
    }

    .ant-slider-handle {
      width: 20px;
      height: 20px;
      background: ${p => p.theme.slider};
      border-color: ${p => p.theme.slider};
    }

    .ant-slider-track {
      border-color: ${p => p.theme.slider};
      background: ${p => p.theme.sliderBg};
    }

    &.is--small.ant-slider-disabled .ant-slider-handle {
      border-color: ${p => p.theme.slider} !important;
    }

    &.is--small {
      margin-top: 0;
      margin-bottom: 0;

      :hover .ant-slider-handle:not(.ant-tooltip-open) {
        border-color: ${p => p.theme.slider} !important;
      }

      .ant-slider-handle:focus {
        box-shadow: 0 0 0 5px rgb(192 151 63 / 12%);
      }

      .ant-slider-rail,
      .ant-slider-track,
      .ant-slider-step {
        height: 4px;
      }

      .ant-slider-step {
        border: none;
      }

      .ant-slider-handle {
        top: 6px;
        width: 10px;
        height: 10px;
      }
    }
  }
`

export default StyledSlider
