import React, { SVGProps } from 'react'
const EnergySaving = (props: SVGProps<any>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.008 27.0897C11.4114 25.771 9.64467 21.3462 10.2206 18.9899C11.7629 12.6858 17.8659 12.0847 20.4688 7.5C24.4086 10.3332 25.9006 16.1432 24.1347 21.3626C22.9447 24.8761 19.5847 26.0483 17.7867 26.4348"
      fill="#FFCC2B"
    />
    <path
      d="M15.008 27.0897C11.4114 25.771 9.64467 21.3462 10.2206 18.9899C11.7629 12.6858 17.8659 12.0847 20.4688 7.5C24.4086 10.3332 25.9006 16.1432 24.1347 21.3626C22.9447 24.8761 19.5847 26.0483 17.7867 26.4348"
      stroke="#FFCC2B"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9099 32.5001C13.202 24.8085 17.2209 18.9664 19.5013 13.9451"
      stroke="#FFCC2B"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6293 22.9228C16.5443 19.5858 18.2744 16.6466 19.5013 13.9451"
      stroke="#003248"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1522 11.0179H4.02174C3.18098 11.0179 2.5 11.687 2.5 12.5131V27.4641C2.5 28.2902 3.18098 28.9592 4.02174 28.9592H12.3913"
      stroke="#FFCC2B"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7174 28.9594H32.1739C33.0147 28.9594 33.6957 28.2903 33.6957 27.4643V12.5132C33.6957 11.6871 33.0147 11.0181 32.1739 11.0181H26.087"
      stroke="#FFCC2B"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.6956 15.5034H35.9783C36.819 15.5034 37.5 16.1725 37.5 16.9985V22.979C37.5 23.805 36.819 24.4741 35.9783 24.4741H33.6956"
      stroke="#FFCC2B"
      strokeWidth="1.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default EnergySaving
