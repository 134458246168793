import { useLoadScript } from '@react-google-maps/api'
import { useTranslation } from 'react-i18next'
import env from '@shared/env'

export default function useLoadGoogleMap() {
  const { i18n } = useTranslation()

  return useLoadScript({
    ...scriptOptions,
    language: i18n.language || 'zh-TW'
  })
}

const scriptOptions = {
  googleMapsApiKey: env.googleMapsApiKey,
  libraries: ['places', 'drawing', 'visualization'] as (
    | 'places'
    | 'drawing'
    | 'visualization'
    | 'geometry'
  )[],
  region: 'zh-TW',
}
