import React from 'react'
import Marker, { IEnhanceMarkerRef, IMapLibreMarkerProps } from './Marker'
import { Device } from '@services/device'
import cx from 'classnames'
import { DeviceStatus } from '@services/constants'
import styled, { css } from 'styled-components'
import { imageUrlPrefix } from '@shared/env'
import { ISession } from '@services/session'
import { length, pathOr } from 'ramda'
import { toThousandSeparator } from '@shared/innmaxLib/utils/webHelper'
import { Badge } from '@shared/innmaxUI'
import theme from '@theme'

interface ISessionMarkerProps
  extends Omit<IMapLibreMarkerProps, 'onDelete' | 'onDragEnd' | 'onLeftClick'> {
  item?: ISession
  onDelete?: (d: ISession) => void
  onDragEnd?: (d: ISession) => void
  onLeftClick: (d: ISession) => void
  size?: 'small' | 'large'
}

const SessionMarker = React.memo((props: ISessionMarkerProps) => {
  const {
    item,
    focus,
    selected,
    onDelete,
    onDragEnd,
    onLeftClick,
    size,
    ...others
  } = props

  const markerRef = React.useRef<IEnhanceMarkerRef>(null)

  const status: DeviceStatus = React.useMemo(() => {
    return item?.devices?.filter(d => d.deviceStatus === DeviceStatus.ALARM)
      .length
      ? DeviceStatus.ALARM
      : item?.devices?.filter(d => d.deviceStatus === DeviceStatus.REPAIR)
          .length
      ? DeviceStatus.REPAIR
      : DeviceStatus.NORMAL
  }, [item])

  const iconImage = React.useMemo(() => {
    switch (status) {
      case DeviceStatus.ALARM:
        return `${imageUrlPrefix}/cluster/cluster-alarm${
          size === 'small' ? '-small' : ''
        }.svg`
      case DeviceStatus.REPAIR:
        return `${imageUrlPrefix}/cluster/cluster-repair${
          size === 'small' ? '-small' : ''
        }.svg`
      case DeviceStatus.NORMAL:
        return `${imageUrlPrefix}/cluster/cluster-normal${
          size === 'small' ? '-small' : ''
        }.svg`
    }
  }, [status, size])

  React.useEffect(() => {
    markerRef.current?.setPosition({
      lat: item?.lat,
      lon: item?.lon,
      x: item?.x,
      y: item?.y,
    })
  }, [item])

  return Marker.Utils.isValidMarkerPosition(item) ? (
    <Marker
      {...others}
      ref={markerRef}
      tooltipContent={<span>{item?.name}</span>}
      onDelete={() => onDelete && onDelete(item)}
      onLeftClick={() => {
        onLeftClick && onLeftClick(item)
      }}
      defaultPosition={{
        lat: item?.lat,
        lon: item?.lon,
        x: item?.x,
        y: item?.y,
      }}
      onDragEnd={position => {
        onDragEnd &&
          onDragEnd({
            ...item,
            ...position,
          } as ISession)
      }}>
      <Marker.MarkerIconDiv>
        <StyledMarkerWrapper
          size={size}
          style={{ backgroundImage: `url(${iconImage})` }}>
          <div className="area truncate">{item.name}</div>
          {pathOr([], ['devices'], item) && (
            <>
              <div
                className={cx(
                  'total',
                  DeviceStatus[status].toLocaleLowerCase()
                )}>
                {toThousandSeparator(pathOr([], ['devices'], item).length)}
              </div>
              <div className="status">
                <Badge status={DeviceStatus.ALARM} dotsize={8} border />
                <span>
                  {toThousandSeparator(
                    length(
                      pathOr<Device[]>([], ['devices'], item).filter(
                        d => d?.deviceStatus === DeviceStatus.ALARM
                      )
                    ) || 0
                  )}
                </span>
              </div>
              <div className="status">
                <Badge status={DeviceStatus.REPAIR} dotsize={8} border />
                <span>
                  {toThousandSeparator(
                    length(
                      pathOr<Device[]>([], ['devices'], item).filter(
                        d => d.deviceStatus === DeviceStatus.REPAIR
                      )
                    )
                  )}
                </span>
              </div>
            </>
          )}
        </StyledMarkerWrapper>
      </Marker.MarkerIconDiv>
    </Marker>
  ) : (
    <div></div>
  )
})

export default SessionMarker

const StyledMarkerWrapper = styled.div<{ size: any }>`
  width: 180px;
  height: 180px;
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15px;

  ${p =>
    p.size === 'small' &&
    css`
      width: 108px;
      height: 108px;
      padding-top: 8px;
      line-height: 17px;
    `};

  .area {
    width: 55%;
    border-radius: 13px;
    background-color: ${theme.light};
    color: ${theme.n.gray400};
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 5px;
    padding: 0 10px;

    ${p =>
      p.size === 'small' &&
      css`
        width: 60%;
        font-size: 12px;
        background-color: unset;
        margin-bottom: 2px;
      `};
  }

  .total {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 5px;
    color: ${theme.light};

    &.alarm,
    &.repair {
      color: ${theme.n.yellow400};
    }

    ${p =>
      p.size === 'small' &&
      css`
        font-size: 13px;
        margin-bottom: 2px;
        color: ${theme.light};
      `};
  }

  .status {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: ${theme.light};
    margin-bottom: 3px;

    span:first-child {
      margin-right: 2px;
    }

    ${p =>
      p.size === 'small' &&
      css`
        font-size: 13px;
        margin-bottom: 2px;
      `};
  }
`
