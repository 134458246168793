import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select, SelectProps } from '@shared/Form/Select'
import { useCameraShape } from '@services/option'

export const CameraTypeSelect = (props: SelectProps) => {
  const { t } = useTranslation()

  const { dataSource } = useCameraShape()

  return (
    <Select allowClear placeholder={t('device:select cctv')} {...props}>
      {(dataSource?.content || []).map(({ code }) => (
        <Select.Option key={code} value={code}>
          {code}
        </Select.Option>
      ))}
    </Select>
  )
}

export default CameraTypeSelect
