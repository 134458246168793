const AttachedWeatherstation = ({ color = '#fff', ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.869 6.546c-.081 0-.162.004-.243.01l.003-.08c0-1.92-1.51-3.476-3.372-3.476-1.708 0-3.118 1.308-3.34 3.005a1.771 1.771 0 0 0-.307-.026c-1.026 0-1.858.857-1.858 1.914 0 .058.003.114.008.17a2.336 2.336 0 0 0-.352-.028C2.078 8.035 1 9.147 1 10.517 1 11.89 2.078 13 3.408 13h9.461C14.599 13 16 11.555 16 9.773c0-1.782-1.402-3.227-3.131-3.227" 
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default AttachedWeatherstation
