import { SVGProps } from 'react'

const RefreshSVG = ({ color, ...props }: SVGProps<any>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g
      fill={color}
      fillRule="nonzero"
      stroke={color}
      strokeWidth=".2">
      <path d="M1.845 14.92a.835.835 0 0 0 .671-.339l.368-.49c.551 3.354 2.84 6.224 6.089 7.382 1 .356 2.026.527 3.04.527 3.032 0 5.95-1.536 7.697-4.24a.883.883 0 0 0-.237-1.204.833.833 0 0 0-1.174.244c-1.903 2.949-5.51 4.196-8.77 3.032-2.705-.964-4.592-3.382-4.995-6.19l.725.573a.832.832 0 0 0 1.186-.159.883.883 0 0 0-.154-1.217l-2.006-1.586c-.42-.332-1.02-.52-1.4-.01l-1.709 2.28a.883.883 0 0 0 .154 1.217.83.83 0 0 0 .515.18zM4.527 8.444A.835.835 0 0 0 5.701 8.2c1.903-2.947 5.513-4.195 8.77-3.031 2.705.964 4.592 3.381 4.995 6.189l-.725-.573a.83.83 0 0 0-1.186.16.883.883 0 0 0 .154 1.217l2.006 1.585c.429.338 1.013.527 1.4.01l1.709-2.279a.883.883 0 0 0-.154-1.218.83.83 0 0 0-1.186.16l-.368.49c-.551-3.354-2.84-6.224-6.089-7.382C11.033 2.105 6.62 3.63 4.29 7.239a.884.884 0 0 0 .237 1.205z" />
    </g>
  </svg>
)

export default RefreshSVG
