import { TooltipProps } from 'antd'
import React from 'react'

export interface TreeContextType {
  expandIds: number[]
  setExpandIds: (id: number[]) => void
  maxLayoutLevel: number
  onEdit: (payload?: Record<string, any>) => void
  onAdd: (parentPayload?: Record<string, any>) => void
  onSelect: (payload?: Record<string, any>) => void
  currentItem?: Record<string, any>
  className?: string
  showAddNodeButton?: boolean
  showSelectedItem?: boolean
  showSessionNo?: boolean
  hideLayerImageIcon?: boolean
  onTooltipProps?: (payload?: Record<string, any>) => TooltipProps
}

const TreeContext = React.createContext<TreeContextType>({
  expandIds: [],
  setExpandIds: (id: number[]) => {},
  maxLayoutLevel: Infinity,
  onEdit: (payload?: Record<string, any>) => {},
  onAdd: (parentPayload?: Record<string, any>) => {},
  onSelect: (payload?: Record<string, any>) => {},
  currentItem: undefined,
})

export default TreeContext
