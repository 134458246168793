import { useMutation } from 'react-query'
import Store from 'store'
import { forEach, pathOr, compose } from 'ramda'
import { request } from '@shared/innmaxLib/services'
import message from '@shared/innmaxUI/Message'
import { useAuth } from './useAuth'
import type { MenuAuth, User, UserPermission } from './auth.types'
import { QueryVariables } from '@shared/innmaxLib/services'

const convertData = (auth: MenuAuth) => {
  return {
    id: auth.id,
    name: auth.subName,
    url: auth.webPath,
    icon: auth.icon,
    code: auth.code,
    mainKey: auth.mainKey,
    subKey: auth.subKey,
    isSubMenu: auth.isSub ? auth.isSub : false,
    deletable: auth.authDelete,
    creatable: auth.authAdd,
    updatable: auth.authEdit,
    readable: auth.authView,
    parentId: auth.id,
    parentName: auth.name,
  } as UserPermission
}

export const setUserAuth = (auth: MenuAuth, permission: UserPermission) => {
  if (permission) {
    const child = convertData(auth)

    return {
      ...permission,
      children: [...permission.children, child],
    }
  }

  return {
    ...auth,
    url: auth.isSub ? auth.code : auth.webPath, //submenu need unique id
    isSubMenu: auth.isSub ? auth.isSub : false,
    deletable: auth.authDelete,
    creatable: auth.authAdd,
    updatable: auth.authEdit,
    readable: auth.authView,
    ...(auth.isSub
      ? {
          children: [
            { ...convertData(auth), parentId: auth.id, parentName: auth.name },
          ],
        }
      : { children: [] }),
  } as UserPermission
}

export const loginAPI = (body: User) => {
  return request('/login', {
    body,
    method: 'POST',
    displayMessage: false,
  })
}

export const useLogin = (options: QueryVariables = {}) => {
  const { setIsAuthenticated, setUser } = useAuth()

  const { mutate, isLoading, ...rest } = useMutation(loginAPI, {
    onError: (error: Error) => {
      console.error(error)
    },
    onSuccess: (response: any) => {
      if (!response) {
        return
      }

      if (response?.errorMessage) {
        message({ content: response?.errorMessage, type: 'error' })
        return
      }

      let authById: { [key: string]: UserPermission } = {}
      let authByUrl: { [key: string]: UserPermission } = {}

      compose(
        forEach((x: MenuAuth) => {
          authById[x.name] = setUserAuth(x, authById[x.name])
          authByUrl[x.webPath] = setUserAuth(x, authByUrl[x.webPath])
        }),
        pathOr([], ['authDetails'])
      )(response)

      const funcList = Object.keys(authById).map(i => authById[i])

      Store.set('sctc_token', response.token)
      Store.set('sctc_user', { ...response, authByUrl, funcList })

      setIsAuthenticated(true)

      setUser({
        ...response,
        authById,
        authByUrl,
        funcList,
      })

      options.onSuccess && options.onSuccess(response)
    },
  })

  return {
    ...rest,
    mutate,
    loading: isLoading,
    login: mutate,
  }
}

export default useLogin
