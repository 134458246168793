import { useQuery, UseQueryOptions } from 'react-query'
import { path } from 'ramda'
import { useRequest, MutationOptions, Mutation } from '@shared/innmaxLib/services'
import { DeviceType } from '@services/constants'

export const useXlsxFormat = (
  query: {
    [key: string]: any
  },
  options: any
) => {
  const { deviceType } = query
  const { loading, mutate } = useRequest(
    `/device/${deviceType}/import/template`,
    {
      ...options,
      method: 'GET',
    },
  )

  return [loading, mutate] as Mutation
}

export const useCvsFormat = (
  query: {
    [key: string]: any
  },
  options?: UseQueryOptions
) => {
  const { data, ...rest } = useQuery(['/device/{deviceType}/import/template', query], {
    cacheTime: 0,
    select: res => path(['data'], res),
    ...options,
  })

  return {
    ...rest,
    data: data as string,
    loading: rest.isLoading,
  }
}

interface UseImportDeviceOptions extends MutationOptions {
  deviceType: DeviceType
}

export const useImportDevice = (options: UseImportDeviceOptions) => {
  const { deviceType, ...rest } = options
  const { loading, mutate } = useRequest(`/device/${deviceType}/import`, {
    ...rest,
    method: 'POST',
    headers: {},
  })

  const handler = (data: { file: File }) => {
    const formData = new FormData()
    formData.append('file', data.file)
    return mutate(formData)
  }

  return [loading, handler] as Mutation
}
