import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { slice } from 'ramda'
import { ColumnProps } from 'antd/lib/table'
import { ReportTable } from '@shared/Table'

import { useAlarmRpt } from '@services/report'
import ReportTitle from '../CommonTitle'
import type { IAlarmProps } from '../type'

export const pageSize = 10
function AlarmReport({ queryVariables, ...props }: IAlarmProps) {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = React.useState(1)

  const { dataSource, isLoading } = useAlarmRpt(queryVariables)

  const columns: ColumnProps<any>[] = [
    {
      dataIndex: 'createAtStr',
      title: t('report:time'),
      align: 'center',
      width: 220,
    },
    {
      dataIndex: 'deviceTypeStr',
      title: t('report:device type'),
      align: 'center',
      width: 120,
    },
    {
      dataIndex: 'displayName',
      title: t('report:device display name'),
      align: 'center',
      width: 220,
    },
    {
      dataIndex: 'sessionName',
      title: t('report:sessionName'),
      align: 'center',
      width: 120,
    },
    {
      dataIndex: 'alarmMessage',
      title: t('report:alarm message'),
    },
  ]

  return (
    <Row className="mt-42">
      <ReportTitle total={dataSource.content.length} {...props} />
      <Col span={24}>
        <ReportTable
          rowKey="id"
          dataSource={{
            ...(dataSource as any),
            page: currentPage,
            content: slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize,
              dataSource.content
            ),
          }}
          loading={isLoading}
          columns={columns}
          pagination={{
            showSizeChanger: false,
            total: dataSource?.content.length || 1,
            pageSize,
          }}
          onChange={(p: any) => setCurrentPage(p.current || 1)}
        />
      </Col>
    </Row>
  )
}

export default React.memo(AlarmReport)
