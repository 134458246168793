import styled from 'styled-components'
import { Button } from '@shared/innmaxUI/Form'

const ErrorFallbackWrapper = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: 50px;
`

type FallbackComponentProps = {
  resetErrorBoundary: any
  error: Error
}

const ErrorFallback = ({
  resetErrorBoundary,
  error,
}: FallbackComponentProps) => (
  <ErrorFallbackWrapper>
    <h1>Something went wrong.</h1>
    <p>{error && error.toString()}</p>
    <Button onClick={resetErrorBoundary}>Try again</Button>
  </ErrorFallbackWrapper>
)

export default ErrorFallback
