const Layer = ({ color = '#fff', ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h16v16H0z"/>
        <path
          d="M8 9.993c-.665 0-1.33-.168-1.937-.503L1.691 7.074A2.006 2.006 0 0 1 .667 5.307 2.005 2.005 0 0 1 1.7 3.545l4.383-2.388a3.988 3.988 0 0 1 3.832 0l4.383 2.388a2.005 2.005 0 0 1 1.034 1.762c.002.74-.39 1.417-1.024 1.767L9.936 9.49A3.998 3.998 0 0 1 8 9.993zm0-8.146a2.88 2.88 0 0 0-1.38.353L2.237 4.588a.811.811 0 0 0-.424.722c-.001.308.156.579.42.724L6.605 8.45a2.869 2.869 0 0 0 2.79 0l4.372-2.416a.811.811 0 0 0 .42-.724.811.811 0 0 0-.424-.722L9.38 2.2A2.88 2.88 0 0 0 8 1.847zm1.936 12.984 5.095-2.815a.6.6 0 0 0 .234-.799.565.565 0 0 0-.775-.241l-5.095 2.815a2.87 2.87 0 0 1-2.792-.001l-5.095-2.786a.565.565 0 0 0-.774.245.6.6 0 0 0 .237.798l5.093 2.784A3.998 3.998 0 0 0 8 15.333c.665 0 1.33-.167 1.936-.502zm0-2.656 5.095-2.814a.6.6 0 0 0 .234-.8.565.565 0 0 0-.775-.24l-5.095 2.814a2.87 2.87 0 0 1-2.792-.001L1.508 8.349a.565.565 0 0 0-.774.244.6.6 0 0 0 .237.798l5.093 2.784A3.998 3.998 0 0 0 8 12.678c.665 0 1.33-.168 1.936-.503z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Layer
