import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import styled from 'styled-components'
import { createPortal } from 'react-dom'
import { useGoogleMap } from '@react-google-maps/api'
import BackIcon from './BackIcon'
import SelectorIcon from './SelectorIcon'

// Currently supported only for GoogleMap

export type BackRectangleSelectControlProps = {
  position?: google.maps.ControlPosition
  styles?: React.CSSProperties
  isDrawingMode?: boolean
  showDrawingMode?: boolean
  enableRectangleSelect?: boolean
  back: () => void
  select: () => void
}

function BackRectangleSelectControl({
  position = window.google.maps.ControlPosition.RIGHT_BOTTOM,
  styles = { margin: '24px 40px 24px 0' },
  isDrawingMode = false,
  showDrawingMode = true,
  enableRectangleSelect = true,
  back,
  select,
}: BackRectangleSelectControlProps) {
  const { t } = useTranslation()

  const map: google.maps.Map | null = useGoogleMap()

  const [container] = React.useState(document.createElement('div'))

  React.useEffect(() => {
    const controlsContainer: any = map?.controls[position]
    controlsContainer.push(container)
    return () => {
      const index = controlsContainer.indexOf(container)
      if (index !== -1) {
        controlsContainer.removeAt(index)
      }
    }
  }, [map]) // eslint-disable-line

  React.useEffect(() => {
    for (const [key, value] of Object.entries(styles as any)) {
      ;(container.style as any)[key] = value
    }
  }, [styles]) // eslint-disable-line

  return createPortal(
    <StyledControl>
      <button title={t('dashboard:back to default position')} onClick={back}>
        <BackIcon />
      </button>
      {
        enableRectangleSelect && (
          <>
            <StyledLine />
            {showDrawingMode && (
              <button
                title={t('dashboard:select')}
                className={cx({ active: isDrawingMode })}
                onClick={select}>
                <SelectorIcon />
              </button>
            )}
          </>
        )
      }
    </StyledControl>,
    container
  )
}

const StyledControl = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 4px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  width: 28px;
  overflow: hidden;

  button {
    &.active {
      background-color: #ffe288;
    }

    &:hover {
      background-color: #d1e0e7;
    }
  }
`

const StyledLine = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0;
  height: 1px;
  background-color: rgb(230, 230, 230);
  top: 0px;
`

export default React.memo(BackRectangleSelectControl)