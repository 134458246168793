import React from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { path } from 'ramda'
import type { DataSource } from '@shared/innmaxLib/services'
import { QueryVariables } from '@shared/innmaxLib/services'
import { formatDate, isNil } from '@shared/utils/webHelper'

import { IAlarm, IAlarmCounter } from './report.types'
import { useConvertIAlarm, IQueryProps, PAGE_SIZE, TYPE, xAxisName } from '@services/report'

export const useMaintenanceChart = (querys: { [key: string]: any }, options?: QueryVariables) => {
  const { data, ...others } = useQuery(
    ['/report/alarmCounterReport', { ...querys, pageNum: undefined, pageSize: undefined }],
    {
      enabled: !isNil(querys),
      ...options,
    }
  )

  const dataSource = React.useMemo(() => {
    if (isNil(data)) {
      return { totalPages: 0, content: [] }
    }

    const { reportType } = querys

    const content = path(['data'], data) as IAlarmCounter[]

    const xAxis = content.map(i => ({ y: i.alarmCount, name: i.dateStr }))

    let xLabels: (string | number)[] = []

    switch (reportType) {
      case TYPE.YEAR:
        xLabels = content.map((i: any) => {
          const m = new Date(i.dateStr).getMonth()
          return xAxisName(m)
        })
        break
      case TYPE.MONTH:
        xLabels = content.map((i: any) => `${new Date(i.dateStr).getDate()}日`)
        break
      case TYPE.DAY:
        xLabels = content.map((i: any) => formatDate(new Date(i.dateStr).valueOf()))
        break
      default:
    }

    const len = content.length

    return {
      xAxis,
      xAxisLabels: xLabels,
      total: len,
      totalPages: Math.ceil(len / PAGE_SIZE),
      content,
    }
  }, [data, querys])

  return {
    dataSource,
    ...others,
  }
}

export const useMaintenanceRpt = (
  query?: IQueryProps,
  options?: UseQueryOptions<DataSource<IAlarm>>
) => {
  const { data, ...others } = useQuery(
    ['/report/alarmReport', { ...query, pageNum: undefined, pageSize: undefined }],
    {
      ...options,
      enabled: !!query?.reportType,
      cacheTime: 0,
    }
  )

  const { dataSource } = useConvertIAlarm(data)

  return {
    ...others,
    dataSource,
  }
}

export const useExportMaintenanceRpt = (query?: IQueryProps, options?: QueryVariables) => {
  const [enabled, setIsEnabled] = React.useState(false)

  const { isLoading } = useQuery(
    [
      '/report/alarmReport/export',
      { deep: true, ...query, pageNum: undefined, pageSize: undefined },
    ],
    {
      ...options,
      enabled,
      onSuccess: (response: any) => {
        if (options?.onSuccess) {
          options.onSuccess(response)
        }
        setIsEnabled(false)
      },
      onError: error => {
        console.log(error)
        setIsEnabled(false)
      },
    }
  )

  return [
    isLoading,
    () => {
      setIsEnabled(true)
    },
  ] as [boolean, () => void]
}
