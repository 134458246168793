import { path } from 'ramda'
import { useQuery, UseQueryOptions } from 'react-query'
import { QueryVariables } from '@shared/innmaxLib/services'

/**
 *  控制器類型清單
 */
export const usePowerControllerType = (options?: UseQueryOptions) => {
  const { data, ...rest } = useQuery(['/ctrler/types/powersupplier'], {
    cacheTime: 0,
    select: res => path(['data'], res),
    ...options,
  })

  return {
    ...rest,
    data: data as string[],
    loading: rest.isLoading,
  }
}

export const useDailyTotalPower = (
  { variables }: QueryVariables = {},
  options?: UseQueryOptions
) => {
  const { data, ...rest } = useQuery(
    ['/device/powersupplier/report/dailyTotalPower', variables],
    {
      cacheTime: 0,
      enabled: !!variables?.reportType && !!variables?.start && !!variables?.end,
      select: res => path(['data'], res),
      ...options,
    }
  )

  return {
    ...rest,
    data: data as string[],
    loading: rest.isLoading,
  }
}