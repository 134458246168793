import styled from 'styled-components'
import { Form } from 'antd'

const StyledForm = styled(Form)`
  .ant-form-item {
    font-size: 16px;
    color: ${p => p.theme.darkGrey};

    .ant-form-item-label {
      text-align: left;
      > label {
        font-size: 14px;
        color: ${p => p.theme.darkGrey};
      }
    }
  }

  .ant-form-item-label > label::after {
    content: '';
  }
`

export default StyledForm
