import React from 'react'
import cx from 'classnames'
import { TimePicker } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker'
import { RefProps } from '@lib/types'
import { ConfigProvider } from 'antd'
import zhTW from 'antd/lib/locale/zh_TW'

type Props = RangePickerProps & RefProps

const TimeRangePicker = React.forwardRef<typeof TimePicker.RangePicker, Props>(
  ({ className = '', ...props }, ref) => (
    <ConfigProvider locale={zhTW}>
      <TimePicker.RangePicker
        locale={zhTW.DatePicker}
        forwardRef={ref}
        className={cx('c-datepicker', className)}
        {...props}
      />
    </ConfigProvider>
  )
)

export default TimeRangePicker
