import React from 'react'
import { LngLatLike, Map, Marker } from 'maplibre-gl'

type IMapLibreMarkerContext = {
  marker: Marker | null
  setMarker: (marker: Marker) => void
}

const MapLibreMarkerContext = React.createContext<IMapLibreMarkerContext>({
  marker: null,
  setMarker: () => {},
})

function MapLibreMarkerProvider(
  props: React.PropsWithChildren<{ marker: Marker | null }>
) {
  const { marker: markerProp } = props

  const [marker, setMarker] = React.useState<Marker | null>(markerProp)

  React.useEffect(() => {
    setMarker(markerProp)
  }, [markerProp])

  const value = React.useMemo<IMapLibreMarkerContext>(() => {
    return {
      marker,
      setMarker,
    }
  }, [marker])

  return <MapLibreMarkerContext.Provider value={value} {...props} />
}

export function useMapLibreMarker() {
  const context = React.useContext(MapLibreMarkerContext)
  if (!context) {
    throw new Error('useMapLibreMarker must be within a MapLibreMarkerProvider')
  }

  return context
}

export default MapLibreMarkerProvider
