import React from 'react'

interface NodePathIconProps {
  last?: boolean
  className?: string
  isExpand?: boolean
  hasChildren?: boolean
}

const NodePathIcon = React.memo<NodePathIconProps>(
  (props: NodePathIconProps) => {
    const { last, className = 'hover:', isExpand, hasChildren } = props

    return (
      <svg
        width="30"
        height="40"
        style={{ minWidth: '30px' }}
        className={className}>
        <path
          d={last ? 'M2 0 V20 H19' : `M2 0 V40 M2 20 H19`}
          style={{
            fill: 'transparent',
            stroke: '#c0c0c0',
            strokeWidth: 1,
            shapeRendering: 'crispEdges',
          }}
        />

        {hasChildren ? (
          <>
            <rect
              x="19"
              y="16"
              rx="0"
              ry="0"
              width="8"
              height="8"
              style={{
                fill: 'transparent',
                stroke: '#c0c0c0',
                strokeWidth: 1,
                shapeRendering: 'crispEdges',
              }}
            />
            {!isExpand ? (
              <path
                d="M23 18 V22.5"
                style={{
                  fill: 'transparent',
                  stroke: '#c0c0c0',
                  strokeWidth: 1,
                  shapeRendering: 'crispEdges',
                }}
              />
            ) : (
              <path
                d="M23.5 25 V40"
                style={{
                  fill: 'transparent',
                  stroke: '#c0c0c0',
                  strokeWidth: 0.5,
                  shapeRendering: 'crispEdges',
                }}
              />
            )}
            <path
              d="M20.5 20 H25"
              style={{
                fill: 'transparent',
                stroke: '#c0c0c0',
                strokeWidth: 1,
                shapeRendering: 'crispEdges',
              }}
            />
          </>
        ) : (
          <circle
            className="circle-marker"
            cx="23"
            cy="20"
            r="4"
            style={{
              fill: '#fff',
              stroke: '#c0c0c0',
              strokeWidth: 1,
              shapeRendering: 'geometricPrecision',
            }}
          />
        )}
      </svg>
    )
  }
)

export default NodePathIcon
