import i18n from 'i18next'
export type SearchMonthProps = { label: number; value: string; name: string }

export const PAGE_SIZE = 31

export enum TAB {
  LIST = 0,
  LINE_CHART = 1,
  BAR_CHART = 2,
}

export enum INTERVAL_TYPE {
  YEAR = 4,
  MONTH = 3,
  WEEK = 1,
  DAY = 1
}

export const TYPE = {
  YEAR: INTERVAL_TYPE.YEAR,
  MONTH: INTERVAL_TYPE.MONTH,
  DAY: INTERVAL_TYPE.DAY
}

export const MONTH_PERIOD = {
  EARLY: 1,
  MID: 2,
  LATE: 3,
}

export const MonthPeriodStr = {
  [MONTH_PERIOD.EARLY]: i18n.t('report:early'),
  [MONTH_PERIOD.MID]: i18n.t('report:mid'),
  [MONTH_PERIOD.LATE]: i18n.t('report:late'),
}

export let ReportSearchType = {
  [TYPE.YEAR]: i18n.t('report:yr report'),
  [TYPE.MONTH]: i18n.t('report:month report'),
  [TYPE.DAY]: i18n.t('report:days'),
}

export let searchMonthSelectItems: SearchMonthProps[] = [
  { label: 1, value: '01', name: i18n.t('report:Jun') },
  { label: 2, value: '02', name: i18n.t('report:Feb') },
  { label: 3, value: '03', name: i18n.t('report:March') },
  { label: 4, value: '04', name: i18n.t('report:Apr') },
  { label: 5, value: '05', name: i18n.t('report:May') },
  { label: 6, value: '06', name: i18n.t('report:June') },
  { label: 7, value: '07', name: i18n.t('report:Jly') },
  { label: 8, value: '08', name: i18n.t('report:Aug') },
  { label: 9, value: '09', name: i18n.t('report:Sep') },
  { label: 10, value: '10', name: i18n.t('report:Oct') },
  { label: 11, value: '11', name: i18n.t('report:Nov') },
  { label: 12, value: '12', name: i18n.t('report:Dec') },
]

i18n.on('languageChanged', function (lng) {
  ReportSearchType = {
    [TYPE.YEAR]: i18n.t('report:yr report'),
    [TYPE.MONTH]: i18n.t('report:month report'),
    [TYPE.DAY]: i18n.t('report:days'),
  }

  searchMonthSelectItems = [
    { label: 1, value: '01', name: i18n.t('report:Jun') },
    { label: 2, value: '02', name: i18n.t('report:Feb') },
    { label: 3, value: '03', name: i18n.t('report:March') },
    { label: 4, value: '04', name: i18n.t('report:Apr') },
    { label: 5, value: '05', name: i18n.t('report:May') },
    { label: 6, value: '06', name: i18n.t('report:June') },
    { label: 7, value: '07', name: i18n.t('report:Jly') },
    { label: 8, value: '08', name: i18n.t('report:Aug') },
    { label: 9, value: '09', name: i18n.t('report:Sep') },
    { label: 10, value: '10', name: i18n.t('report:Oct') },
    { label: 11, value: '11', name: i18n.t('report:Nov') },
    { label: 12, value: '12', name: i18n.t('report:Dec') },
  ]
})
