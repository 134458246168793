import { Select, SelectProps } from '@shared/Form/Select'
import { SupportedDeviceType } from '@services/constants'
import { useTranslation } from 'react-i18next'

type Props = {
  showAll?: boolean
} & SelectProps

export default function ReportDeviceSelect({ showAll = false, ...props }: Props) {
  const { t } = useTranslation()

  return (
    <Select allowClear placeholder={t('report:select device')} {...props}>
      {showAll && (
        <Select.Option key="all" value="">
          {t('common:all')}
        </Select.Option>
      )}
      {Object.keys(SupportedDeviceType)
        .filter(d => ['LOCATION', 'ATTACHED', 'LAMP_CTLER', 'TALKIE', 'BOX'].indexOf(d) === -1)
        .map(x => (
          <Select.Option key={x} value={(SupportedDeviceType as any)[x]}>
            {t(`device:select-${(SupportedDeviceType as any)[x]}`)}
          </Select.Option>
        ))}
    </Select>
  )
}
