import envStore from '@shared/env'
import cx from 'classnames'
import {
  append,
  compose,
  dropLast,
  equals,
  findIndex,
  ifElse,
  includes,
  remove,
  is,
  range,
  isNil,
} from 'ramda'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import NodePathIcon from './NodePathIcon'
import TreeContext from './TreeContext'
import Icon, { EditOutlined } from '@ant-design/icons'
import { IconLocation } from '@shared/icons'
import { IconDownLevel } from '@shared/icons/DownLevel'
import { IconMap } from '@shared/icons'
import theme from '@theme'
import Tooltip from '@shared/innmaxUI/Tooltip'
import { MAP_DASHBOARD } from 'src/routes'
import { IconMapHome } from '@shared/icons'

interface LayoutTreeNodeStyledProps {
  layoutLevel?: number
  type?: 'EMPTY' | 'NORMAL'
  showParentExpandNodePath?: boolean
}

export interface LayoutTreeNodeProps extends LayoutTreeNodeStyledProps {
  id: number
  title: string
  children?: LayoutTreeNodeProps[]
  beforeIcon?: React.ReactNode | 'NodePath'
  onEmptyCanceled?: (e: React.MouseEvent<HTMLSpanElement>) => void
  payload?: Record<string, any>
  onEmptyNodeClick?: () => void
  lastNode?: boolean
  hasLayerImage?: boolean
  hasPermission?: boolean
  afterAddon?: React.ReactNode
  customTools?: React.ReactNode
}

const ChildrenTreeDivStyled = styled.div`
  position: relative;

  background-color: #f8f8f8;
  box-shadow: inset 0px 7px 6px -6px rgba(0, 0, 0, 0.1),
    inset 0px -7px 6px -6px rgba(0, 0, 0, 0.1);
`

const Tools = styled.div`
  visibility: hidden;
  text-decoration: underline;
  white-space: nowrap;
  > .icon {
    opacity: 0.5;
    margin: 0 5px;
    padding: 5px;
    & svg {
      height: 15px;
    }
  }
`

const LayoutTreeNodeStyled = styled.div<LayoutTreeNodeStyledProps>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;

  > .info {
    padding: 0px 6px;
    font-weight: 500;
    color: ${({ layoutLevel }) =>
      layoutLevel === 1 ? theme.primary : theme.treeview.color};
    /*
    ${({ type }) =>
      type === 'EMPTY'
        ? css`
            color: ${theme.btn.linkColor};
            font-weight: normal;
            text-decoration: underline;
          `
        : css`
            font-weight: 500;
            color: ${theme.treeview.color};
          `}
    */

    .disabled {
      cursor: not-allowed !important;
      opacity: 0.65;
    }

    .title {
      padding: 8px 0px;
    }

    .sessionNo {
      font-weight: 200;
    }
  }

  &.active {
    background-color: ${p => p.theme.n.blue400};

    > .info {
      color: ${p => p.theme.light};
    }
  }

  ${({ layoutLevel = 0, showParentExpandNodePath = false }) =>
    showParentExpandNodePath
      ? css`
          &::before {
            content: ' ';
            position: absolute;
            height: 100%;
            width: 0.5px;
            background-color: rgb(192, 192, 192);
            top: 0;

            left: ${`${(layoutLevel - 2) * 15 + 25}px`};
          }
        `
      : css``}

  &:hover {
    background-color: rgba(255, 205, 41, 0.2);
    ${Tools} {
      visibility: visible;
    }
    > .info {
      color: ${({ layoutLevel }) =>
        layoutLevel === 1 ? theme.primary : theme.treeview.color};
    }
  }

  ${({ layoutLevel = 1 }) =>
    layoutLevel <= 3
      ? css`
          &::before {
            content: ' ';
            border-left: 3px solid ${theme.primary};
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
          }
          &::after {
            content: ' ';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: #e4e4e4;
          }
        `
      : css``}
`

const LayoutTreeNode = React.memo<LayoutTreeNodeProps>(props => {
  const {
    id,
    title,
    children = [],
    layoutLevel = 1,
    beforeIcon,
    type = 'NORMAL',
    onEmptyCanceled,
    onEmptyNodeClick,
    payload,
    lastNode,
    hasLayerImage,
    hasPermission,
    afterAddon,
    customTools,
    ...others
  } = props

  const {
    expandIds,
    setExpandIds,
    maxLayoutLevel,
    onEdit,
    onAdd,
    className,
    showAddNodeButton,
    showSelectedItem,
    showSessionNo,
    onSelect,
    currentItem,
    hideLayerImageIcon,
    onTooltipProps,
  } = React.useContext(TreeContext)

  const history = useHistory()

  const [isHover, setHover] = React.useState(false)

  const removeEmptyNodes = React.useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation()
      setEmptyChildrenNodes(childrenNodes => dropLast(1, childrenNodes))
    },
    []
  )

  // 第二層資料才允許增加子層級
  const [emptyChildrenNodes, setEmptyChildrenNodes] = React.useState<
    LayoutTreeNodeProps[]
  >(
    children.length > 0 && layoutLevel > 1 && showAddNodeButton
      ? [
          {
            id: new Date().getTime(),
            title: '新增圖資',
            type: 'EMPTY',
            onEmptyCanceled: removeEmptyNodes,
            layoutLevel: layoutLevel + 1,
            onEmptyNodeClick: () => onAdd({ ...payload, layoutLevel }),
            hasPermission: true,
            lastNode: true,
          },
        ]
      : []
  )

  const isExpand = React.useMemo(() => includes(id, expandIds), [expandIds, id])

  const handleEdit = React.useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation()
      onEdit({ ...payload, layoutLevel })
    },
    [onEdit, payload, layoutLevel]
  )

  const handleTitleClick = React.useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      if (type === 'EMPTY') {
        e.stopPropagation()
        onEmptyNodeClick && onEmptyNodeClick()
      }
    },
    [type, onEmptyNodeClick]
  )

  const handleExpandStatusChange = React.useCallback(() => {
    // root
    if (layoutLevel === 1) {
      return
    }
    setExpandIds(
      ifElse(
        () => isExpand,
        compose(
          (index: number) => remove(index, 1, expandIds),
          findIndex(equals(id))
        ),
        append(id)
      )(expandIds)
    )
  }, [expandIds, id, isExpand, setExpandIds])

  const appendEmptyNodes = React.useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      onAdd({ ...payload, layoutLevel })
      setEmptyChildrenNodes(childrenNodes =>
        append(
          {
            id: new Date().getTime(),
            title: layoutLevel === 1 ? '新增圖層' : '新增圖資',
            type: 'EMPTY',
            onEmptyCanceled: removeEmptyNodes,
            layoutLevel: layoutLevel + 1,
            onEmptyNodeClick: () => onAdd({ ...payload, layoutLevel }),
            hasPermission: true,
            lastNode: true,
          },
          childrenNodes
        )
      )
    },
    [layoutLevel, removeEmptyNodes, onAdd, payload]
  )

  const handleAddChildNode = React.useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      if (emptyChildrenNodes.length === 0) {
        appendEmptyNodes(e)
        !isExpand && handleExpandStatusChange()
      } else {
        if (isExpand) {
          e.stopPropagation()
          onAdd({ ...payload, layoutLevel })
        } else {
          handleExpandStatusChange()
        }
      }
    },
    [
      appendEmptyNodes,
      onAdd,
      payload,
      emptyChildrenNodes.length,
      isExpand,
      handleExpandStatusChange,
      layoutLevel,
    ]
  )

  const childrenNodeElements = React.useMemo(() => {
    return children.map((treNodeProps, index) => {
      let isLastNode = children.length === index + 1
      // 若顯示新增圖層按鈕，且當前圖層不為最大層時，新增圖層按鈕為最後一層
      if (showAddNodeButton) {
        isLastNode = false
      }
      return (
        <LayoutTreeNode
          key={treNodeProps.id}
          layoutLevel={layoutLevel + 1}
          beforeIcon={layoutLevel > 2 && 'NodePath'}
          lastNode={isLastNode}
          showParentExpandNodePath={!lastNode && layoutLevel > 3}
          {...treNodeProps}
        />
      )
    })
  }, [children, layoutLevel, lastNode, showAddNodeButton])

  const emptyChildrenNodeElements = React.useMemo(() => {
    return emptyChildrenNodes.map(props => (
      <LayoutTreeNode
        {...props}
        key={props.id}
        beforeIcon={layoutLevel > 2 && 'NodePath'}
        showParentExpandNodePath={!lastNode && layoutLevel > 3}
      />
    ))
  }, [emptyChildrenNodes, lastNode, layoutLevel])

  const hasChildren = React.useMemo(
    () => children.length > 0 || emptyChildrenNodeElements.length > 0,
    [emptyChildrenNodeElements, children]
  )

  React.useEffect(() => {
    if (isExpand) {
      !hasChildren && handleExpandStatusChange()
    }
  }, [handleExpandStatusChange, isExpand, hasChildren])

  const ChildrenTreeWrapper = React.useMemo(
    () => (layoutLevel === 3 ? ChildrenTreeDivStyled : React.Fragment),
    [layoutLevel]
  )

  const isActived = showSelectedItem && currentItem?.id === payload?.id

  const getIconFileName = () => {
    if (isActived && !isHover) {
      if (!hasChildren) {
        return 'icons-map-setting-ic-openitem-noneitem'
      }
      if (isExpand) {
        return 'icons-map-setting-ic-openitem-active'
      }
      return 'icons-map-setting-ic-closeitem-active'
    }
    if (layoutLevel === 2) {
      if (!hasChildren) {
        return 'icons-map-setting-ic-noneitem-l-2'
      }
      if (isExpand) {
        return 'icons-map-setting-ic-openitem-l-2'
      }
      return 'icons-map-setting-ic-closeitem-l-2'
    }
    if (layoutLevel === 3) {
      if (!hasChildren) {
        return 'icons-map-setting-ic-noneitem'
      }
      if (isExpand) {
        return 'icons-map-setting-ic-openitem'
      }
      return 'icons-map-setting-ic-closeitem'
    }
    return ''
  }

  const tooltipProps = React.useMemo(() => {
    return onTooltipProps && onTooltipProps(payload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload])

  return (
    <>
      <LayoutTreeNodeStyled
        className={cx(className, {
          active: isActived,
        })}
        type={type}
        layoutLevel={layoutLevel}
        onClick={() => {
          hasChildren && handleExpandStatusChange()
        }}
        onMouseLeave={() => {
          setHover(false)
        }}
        onMouseEnter={() => {
          setHover(true)
        }}
        {...others}>
        {range(0, layoutLevel).map(level => (
          <div key={level} style={{ minWidth: '12px', maxWidth: '10px' }}></div>
        ))}
        {beforeIcon ? (
          is(String, beforeIcon) ? (
            <NodePathIcon
              last={lastNode}
              isExpand={isExpand}
              hasChildren={hasChildren}
            />
          ) : (
            beforeIcon
          )
        ) : layoutLevel === 1 ? (
          <IconMapHome
            color={
              !hasPermission ? 'rgb(0 116 168 / 54%)' :
              isActived && !isHover ? '#fff' : '#0074A8'
            }
          />
        ) : (
          <img
            width={12}
            src={`${envStore.imageUrlPrefix}/tree/${getIconFileName()}.png`}
            alt="device-nvr"
          />
        )}
        <Tooltip {...(isNil(tooltipProps) ? { open: false } : tooltipProps)}>
          <div style={{ maxWidth: '80%' }} className="info flex items-center truncate">
            <div
              className={
                cx(
                  'title flex-1 truncate',
                  { disabled: !hasPermission }
                )
              }
              onClick={e => {
                if (hasPermission) {
                  onSelect && onSelect(payload)
                  handleTitleClick(e)
                }
              }}>
              {title}
            </div>
            {showSessionNo && (
              <span className="ml-4 sessionNo">{payload?.sessionNo}</span>
            )}
            <div>
              {hasLayerImage && !hideLayerImageIcon && (
                <IconMap className="ml-6 map-icon" />
              )}
            </div>
          </div>
        </Tooltip>
        <Tools>
          {type === 'NORMAL' ? (
            <>
              <Tooltip placement="bottom" title="編輯">
                <EditOutlined className="icon" onClick={handleEdit} />
              </Tooltip>
              {layoutLevel < maxLayoutLevel && layoutLevel && (
                <Tooltip placement="bottom" title="新增">
                  <Icon
                    className="icon"
                    component={IconDownLevel}
                    onClick={handleAddChildNode}
                  />
                </Tooltip>
              )}
              <Tooltip placement="bottom" title="地圖">
                <Icon
                  className="icon"
                  component={IconLocation}
                  onClick={() => {
                    history.push(`${MAP_DASHBOARD}?session=${payload?.id}`)
                  }}
                />
              </Tooltip>
            </>
          ) : (
            // <span onClick={onEmptyCanceled}>取消</span>
            <></>
          )}
          {customTools}
        </Tools>
        {afterAddon}
      </LayoutTreeNodeStyled>
      {isExpand && (
        <ChildrenTreeWrapper>
          {childrenNodeElements}
          {emptyChildrenNodeElements}
        </ChildrenTreeWrapper>
      )}
    </>
  )
})

export default LayoutTreeNode
