import { useQuery, UseQueryOptions } from 'react-query'
import React from 'react'
import { path } from 'ramda'
import {
  request,
  useMakeMutation,
  convertData,
  DataSource,
} from '@shared/innmaxLib/services'
import type { MutationOptions } from '@shared/innmaxLib/services'
import type { IRepairer } from './repairer.types'
const { compose } = require('ramda')

export const useRepairers = (
  query?: any,
  options?: UseQueryOptions<DataSource<IRepairer>>
) => {
  const { data, ...others } = useQuery(
    ['/repairTicket/repairer/search', query],
    { ...options, cacheTime: 0 }
  )

  const dataSource = React.useMemo(
    () => convertData<IRepairer>({ field: ['data'] }, data),
    [data]
  )

  return {
    ...others,
    dataSource,
  }
}

export const useRepairer = (id?: React.Key) => {
  const { data, refetch, isLoading, ...others } = useQuery<any>(
    [`/repairTicket/repairer/{id}`, { id }],
    { enabled: !!id, cacheTime: 0 }
  )

  return {
    ...others,
    refetch,
    loading: isLoading,
    data: compose(path(['data']))(data),
  }
}

export const createRepairer = (values: Partial<IRepairer>) =>
  request('/repairTicket/repairer', { method: 'POST', body: values })

export const updateRepairer = ({ id, ...values }: Partial<IRepairer> = {}) =>
  request(`/repairTicket/repairer/${id}`, { method: 'PUT', body: values })

export const deleteRepairer = (id: React.Key) =>
  request(`/repairTicket/repairer/${id}`, { method: 'DELETE' })

export const useAddRepairer = (options: MutationOptions = {}) => {
  return useMakeMutation(createRepairer, options)
}

export const useUpdateRepairer = (options: MutationOptions = {}) => {
  return useMakeMutation(updateRepairer, options)
}

export const useDeleteRepairer = (options: MutationOptions = {}) => {
  return useMakeMutation(deleteRepairer, options)
}
