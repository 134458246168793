const AttachedPile = ({ color = '#fff', ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1 1)" fill={color} fillRule="evenodd">
        <path d="M8.315 0c.63 0 1.14.51 1.14 1.14v11.72c0 .63-.51 1.14-1.14 1.14H1.14C.51 14 0 13.49 0 12.86V1.14C0 .51.51 0 1.14 0h7.175zM7.088 3.243l-3.732.875L1.49 8.785l1.866-.292-1.244 4.083L7.4 6.743 4.6 7.057l2.488-3.814z"/>
        <path d="M12.167 4.083v3.553a2.85 2.85 0 0 1-2.85 2.85h-.825v-1.14h.825a1.71 1.71 0 0 0 1.71-1.71V4.083h1.14z" fillRule="nonzero"/>
        <rect x="10.636" y="1.167" width="2.364" height="3.5" rx=".57"/>
      </g>
    </svg>
  )
}

export default AttachedPile
