import { Tooltip } from 'antd'
import { TooltipProps } from 'antd/lib/tooltip'

const StyleTooltip = (props: TooltipProps) => {
  return (
    <Tooltip
      color="#fff1b8"
      overlayInnerStyle={{ color: '#4a4a4a' }}
      {...props}>
      {props.children}
    </Tooltip>
  )
}

export default StyleTooltip
