import { useTranslation } from 'react-i18next'
import React from 'react'
import { pathOr } from 'ramda'
import { convertData } from '@shared/innmaxLib/services'
import { momentToString } from '@shared/utils/webHelper'
import { useAllSessions } from '../session'
import type { IAlarm } from './report.types'

export const useConvertIAlarm = (res: any) => {
  const { byId } = useAllSessions()
  const { t } = useTranslation()

  const dataSource = React.useMemo(
    () =>
      convertData<IAlarm>({
        converter: x => {
          return {
            ...x,
            deviceTypeStr: x.deviceType && t(`device:${x.deviceType}`),
            createAtStr: momentToString(x.createAt, 'YYYY/MM/DD HH:mm:ss'),
            sessionName: pathOr(t('report:no area assigned'), [x.sessionId, 'name'], byId),
          }
        },
      })(res),
    [res]
  )

  return { dataSource }
}
