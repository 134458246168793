import { Select, SelectProps } from '@shared/Form/Select'
import { useTranslation } from 'react-i18next'

export default function RepairCloseReasonSelect({ ...props }: SelectProps) {
  const { t } = useTranslation()

  return (
    <Select
      allowClear
      //  loading={isLoading}
      {...props}>
      <Select.Option key={1} value={1}>
        {t('repair:repeat case')}
      </Select.Option>
    </Select>
  )
}
