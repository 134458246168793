import React from 'react'
import styled from 'styled-components'
import theme from '@theme'
import { Button } from 'antd'

type Props = {
  data?: any
  onCancel: () => void
  deleteMarker: any
}

export function DeleteWindow({
  data,
  onCancel,
  deleteMarker,
  ...others
}: Props) {
  const [currentDevice] = React.useState<any>(data)

  const handleDeleteMarker = React.useCallback(() => {
    deleteMarker && deleteMarker()
    onCancel && onCancel()
  }, [data]) //eslint-disable-line

  return (
    <InfoBoxWrapper theme={theme} {...others}>
      <StyledButton block onClick={handleDeleteMarker}>
        移除定位
      </StyledButton>
    </InfoBoxWrapper>
  )
}

const InfoBoxWrapper = styled.div`
  width: 160px;
  margin-left: 15px;
  padding: 4px 0;
  border-radius: 11px;
  background: ${p => theme.light};
  overflow: hidden;
  border-radius: 4px;
`

const StyledButton = styled(Button)`
  padding: 4px 7px;
  margin: 0;
  height: auto;
  text-align: left;
  font-size: 13px;
  border: 0;

  &:hover {
    background-color: ${p => theme.primary};
    color: ${p => theme.dark};
  }
`
