import React from 'react'

export type Props = {
  id?: string
  visible?: boolean
  onRequestOpen?: () => void
  onClose?: () => void
  data?: any
}

export default function useVisible<T>({
  id = '',
  visible = false,
  onRequestOpen,
  onClose,
  data,
}: Props = {}) {
  const [_data, setData] = React.useState<T>(data)
  const [_visible, setVisible] = React.useState(visible)

  const handleVisible = (data?: any) => {
    setVisible(prev => !prev)
    setData(data)

    if (onRequestOpen) {
      onRequestOpen()
    }
  }

  const handleClose = () => {
    setVisible(false)
    onClose && onClose()
  }

  const handleOpen = (data?: any) => {
    setVisible(true)
    setData(data)
  }

  return {
    id,
    data: _data,
    visible: _visible,
    toggle: handleVisible,
    open: handleOpen,
    close: handleClose,
  }
}
