import { pathOr, map, mergeAll } from 'ramda'
import moment from 'moment'
import { useMineInfo } from '@services/auth'
import { convertData } from '@shared/innmaxLib/services'
import type { ISession } from '.'
import envStore from '@shared/env'
const { compose } = require('ramda')

export const useConvertSessionData = (data: any) => {
  const { dataSource: mineInfo } = useMineInfo()

  const mySessions = pathOr<ISession[]>([], ['sessionInfos'], mineInfo)

  if (!data) {
    return {}
  }

  const dataSource = convertData<ISession>({
    converter: (x: any) => {
      return {
        ...x,
        imageFileUrl: `${envStore.uploadFileUrlPrefix}${x.imageFilePath}`,
        layerFileUrl: `${envStore.uploadFileUrlPrefix}${x.layerFilePath}`,
        createdAt: moment(x.createdAt),
        createdAtStr: moment(x.createdAt).format('YYYY/MM/DD HH:mm:ss'),
        hasPermission:
          mineInfo?.allSessionPermission ||
          mySessions.filter(s => s.id === x.id).length > 0,
      }
    },
  })(data)

  let _data = pathOr([], ['content'], dataSource)

  let byId: { [key: string]: ISession } = compose(
    mergeAll,
    map((x: ISession) => ({ [x.id]: x }))
  )(_data)

  return {
    byId,
    data: _data as ISession[],
    dataSource,
  }
}
