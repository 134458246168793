import React from 'react'
import { Select, SelectProps } from '@shared/Form/Select'
import { useDeviceInfos } from '@services/device'
import { DeviceType } from '@services/constants'
import { useTranslation } from 'react-i18next'

type Props = {
  deviceType?: DeviceType
  sessionId?: number
} & SelectProps

export default function DeviceDisplayNemeSelect({
  deviceType,
  sessionId,
  ...props
}: Props) {
  const { t } = useTranslation()

  const { dataSource, queryDeviceInfos, loading } = useDeviceInfos()

  React.useEffect(() => {
    queryDeviceInfos({ deviceType, sessionId, isSub: undefined })
  }, [deviceType, sessionId]) //eslint-disable-line

  return (
    <>
      <Select
        allowClear
        placeholder={t('camera:deviceName')}
        loading={loading}
        {...props}>
        {dataSource?.map((i: any) => (
          <Select.Option value={i.id}>{i.displayName}</Select.Option>
        ))}
      </Select>
    </>
  )
}
