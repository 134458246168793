const Wdetect = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#0074A8">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <path d="M8.333 14.667c2.394 0 4.334-1.934 4.334-4.32 0-1.59-1.445-4.594-4.334-9.014C5.444 5.753 4 8.758 4 10.348c0 2.385 1.94 4.319 4.333 4.319z" transform="translate(-323 -726) translate(184 596) translate(0 58) translate(8) translate(0 64) translate(12 7) translate(119 1)"/>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Wdetect
