import React from 'react'

export const IconDownLevel = React.memo(() => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <defs>
      <path
        id="trash"
        d="M14 17.586V8a3 3 0 0 0-3-3H4a1 1 0 1 1 0-2h7a5 5 0 0 1 5 5v9.586l3.293-3.293a1 1 0 0 1 1.414 1.414l-5 5a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 1.414-1.414L14 17.586z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <mask id="trash-mask" fill="#fff">
        <use xlinkHref="#trash" />
      </mask>
      <use fill="currentColor" fillRule="nonzero" xlinkHref="#trash" />
      <g fill="currentColor" mask="url(#trash-mask)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
))
