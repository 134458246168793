import { Select, SelectProps } from '@shared/Form/Select'
import { ISession, useAllSessions } from '@services/session'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { isNil, prepend } from 'ramda'
import { IconMapHome } from '@shared/icons'
import { CaretDownOutlined } from '@ant-design/icons'

export default function SessionTreeSelect({
  currentSessionId,
  ...props
}: SelectProps & {
  currentSessionId?: number | undefined
}) {
  const { data, byId } = useAllSessions()

  const { t } = useTranslation()

  const { topLevelSession, childrenSession } = React.useMemo(() => {
    const childrenSession = data.filter(
      s => s.parentSessionId === currentSessionId
    )

    const listSessionOptions = (
      sessionId: number | undefined,
      options: ISession[] = []
    ): ISession[] => {
      if (isNil(sessionId)) {
        return options
      }
      if (isNil(byId[sessionId])) {
        return options
      }
      let _session = byId[sessionId]

      const _options = prepend(_session, options)
      if (!isNil(_session?.parentSessionId)) {
        return listSessionOptions(_session.parentSessionId, _options)
      }
      return _options
    }

    const topLevelSession = listSessionOptions(currentSessionId)
    return {
      topLevelSession,
      childrenSession,
    }
  }, [byId, currentSessionId, data])

  const renderSelectOptionContent = (
    session: ISession,
    layerLevel: number,
    renderBeforeAddon?: (layerLevel: number) => React.ReactNode
  ) => {
    return (
      <Select.Option
        key={session.id}
        value={session.id}
        label={session.name}
        disabled={!session.hasPermission}>
        <span
          css={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: `${10 * (layerLevel - 1)}px`,
            fontWeight: layerLevel <= 3 ? 'bold' : 'normal',
          }}>
          {renderBeforeAddon && renderBeforeAddon(layerLevel)}
          {session.name}
        </span>
      </Select.Option>
    )
  }

  return (
    <Select
      allowClear
      popupClassName="blue-selected-style"
      optionLabelProp="label"
      placeholder={t('device:search sessionName')}
      {...props}>
      {topLevelSession.map((x, index) =>
        renderSelectOptionContent(x, index + 1, layerLevel => (
          <span className="mr-8">
            {layerLevel === 1 ? (
              <IconMapHome />
            ) : (
              <CaretDownOutlined style={{ fontSize: '12px' }} />
            )}
          </span>
        ))
      )}
      {childrenSession.map((x, index) =>
        renderSelectOptionContent(x, topLevelSession.length + 1, () => (
          <span className="mr-8">
            {index === 0 ? (
              <CaretDownOutlined style={{ fontSize: '12px' }} />
            ) : (
              // 只有第一個項目要顯示 icon，其餘 hidden 保留相同的寬度
              <CaretDownOutlined
                style={{ fontSize: '12px', visibility: 'hidden' }}
              />
            )}
          </span>
        ))
      )}
    </Select>
  )
}
