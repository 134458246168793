import { SVGProps } from 'react'
import cx from 'classnames'

const VolumnSVG = ({ className, ...props }: SVGProps<any>) => (
  <svg
    viewBox="0 0 96 96"
    xmlns="http://www.w3.org/2000/svg"
    className={cx('icon-volumn', className)}
    {...props}>
    <g
      fill="none"
      fillRule="evenodd">
      <path d="M0 0h96v96H0z"/>
      <path
        d="M49.779 22c-.956 0-1.782.344-2.48 1.031L28.957 34.603H14.526c-.956 0-1.783.344-2.48 1.03-.698.689-1.046 1.502-1.046 2.443v20.848c0 .941.349 1.756 1.046 2.443.697.688 1.524 1.031 2.48 1.031h14.431l18.344 11.57c.697.689 1.523 1.032 2.479 1.032.954 0 1.78-.344 2.478-1.031.697-.687 1.046-1.502 1.046-2.444v-46.05c0-.941-.349-1.756-1.046-2.444-.7-.686-1.525-1.031-2.48-1.031z" 
        fill="#FFD63C"/>
      <g
        stroke="#FFF"
        strokeLinecap="round"
        strokeWidth="5">
        <path d="M64.5 40.5c2.4 1.55 4 4.329 4 7.5 0 3.171-1.6 5.95-4 7.5"/>
        <path d="M65.5 25.5c9.831 2.95 17 12.13 17 23s-7.169 20.05-17 23"/>
      </g>
    </g>
  </svg>
)

export default VolumnSVG
