import { SVGProps } from 'react'

const AlertSVG = ({ className, ...props }: SVGProps<any>) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}>
    <defs>
      <linearGradient
        x1="7.724%"
        y1="32.176%"
        x2="95.704%"
        y2="104.077%"
        id="6m5kwzegfb">
        <stop stopColor="#FFD800" offset="0%" />
        <stop stopColor="#FFAC00" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="82.345%"
        y1="88.479%"
        x2="31.874%"
        y2="45.809%"
        id="vlumavcsoe">
        <stop stopColor="#FFF" offset="0%" />
        <stop stopColor="#FFF" stopOpacity="0" offset="100%" />
      </linearGradient>
      <path
        d="m42.164 4.524-20.47 35.452L1.227 75.428C-2.257 81.46 2.096 89 9.062 89H90.939c6.965 0 11.318-7.54 7.835-13.572L78.305 39.976 57.835 4.524C56.096 1.508 53.048 0 50 0c-3.047 0-6.094 1.508-7.836 4.524"
        id="9x6wln0zba"
      />
      <path
        d="M40.907 4.524c3.482-6.032 12.189-6.032 15.671 0l16.043 27.785C59.247 57.913 32.445 75.393 1.556 75.393c-.52 0-1.038-.01-1.556-.02l20.438-35.397"
        id="9a9pj1q24d"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 6)">
        <mask id="zk1pr36z1c" fill="#fff">
          <use xlinkHref="#9x6wln0zba" />
        </mask>
        <path
          d="m42.164 4.524-20.47 35.452L1.227 75.428C-2.257 81.46 2.096 89 9.062 89H90.939c6.965 0 11.318-7.54 7.835-13.572L78.305 39.976 57.835 4.524C56.096 1.508 53.048 0 50 0c-3.047 0-6.094 1.508-7.836 4.524"
          fill="url(#6m5kwzegfb)"
          mask="url(#zk1pr36z1c)"
        />
      </g>
      <path
        d="M9.78 85.537 50 15.877l40.22 69.66H9.78zm85.742-2.231L54.584 12.4A5.24 5.24 0 0 0 50 9.755a5.24 5.24 0 0 0-4.584 2.646L4.478 83.306a5.24 5.24 0 0 0 0 5.293 5.238 5.238 0 0 0 4.584 2.646h81.877a5.24 5.24 0 0 0 4.584-2.646 5.239 5.239 0 0 0 0-5.293z"
        fill="#111"
      />
      <path
        d="M55.997 39.049a5.997 5.997 0 1 0-11.994 0 5.997 5.997 0 0 0 11.994 0M53.22 67.398a3.22 3.22 0 1 0-6.44 0 3.22 3.22 0 0 0 6.44 0"
        fill="#111"
      />
      <path
        fill="#111"
        d="m44.003 39.049 2.838 28.972 6.338-.137 2.818-28.835zM53.884 77.545a3.884 3.884 0 1 0-7.768 0 3.884 3.884 0 0 0 7.768 0"
      />
      <g transform="translate(1.257 6)">
        <mask id="x7y0eowy9f" fill="#fff">
          <use xlinkHref="#9a9pj1q24d" />
        </mask>
        <path
          d="M1.556 75.393c30.889 0 57.69-17.48 71.065-43.084L56.578 4.524c-3.482-6.032-12.189-6.032-15.671 0l-20.47 35.452L0 75.373c.518.01 1.036.02 1.556.02"
          fill="url(#vlumavcsoe)"
          opacity=".5"
          mask="url(#x7y0eowy9f)"
        />
      </g>
    </g>
  </svg>
)

export default AlertSVG
