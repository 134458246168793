const Performance = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#878787" fillRule="nonzero" stroke="#878787" strokeWidth=".4">
          <g>
            <g>
              <g>
                <path
                  d="M17.875 3.25h-2.25V2.125C15.625 1.503 15.122 1 14.5 1h-9c-.622 0-1.125.503-1.125 1.125V3.25h-2.25C1.503 3.25 1 3.753 1 4.375V7.75c0 1.863 1.511 3.375 3.375 3.375h.471c.797 1.822 2.53 3.142 4.591 3.346v3.404h-2.25c-.31 0-.562.251-.562.563V19h6.75v-.563c0-.31-.252-.562-.563-.562h-2.25v-3.404c2.063-.204 3.795-1.524 4.592-3.346h.471C17.489 11.125 19 9.613 19 7.75V4.375c0-.622-.503-1.125-1.125-1.125zM4.375 10c-1.243 0-2.25-1.007-2.25-2.25V4.937c0-.31.252-.562.563-.562h1.687v4.5c0 .385.04.76.113 1.125h-.113zM10 13.375c-2.485 0-4.5-2.015-4.5-4.5V2.687c0-.31.252-.562.563-.562h7.875c.31 0 .562.251.562.563v6.187c0 2.485-2.015 4.5-4.5 4.5zm7.875-5.625c0 1.243-1.007 2.25-2.25 2.25h-.113c.074-.364.113-.74.113-1.125v-4.5h1.688c.31 0 .562.251.562.563V7.75z"
                  transform="translate(-10 -598) translate(0 120) translate(0 464) translate(10 14)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Performance
