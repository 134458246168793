import React from 'react'
import { LngLatLike, Map } from 'maplibre-gl'
import { MapType } from '../map.types'

export type IMapLibreMapMode = '2D' | '3D'

type IMapLibreContext = {
  mapType: MapType
  setMapType: (mapType: MapType) => void
  mapMode: IMapLibreMapMode
  setMapMode: (mapMode: IMapLibreMapMode) => void
  map: Map | null
  onLoad: (map: Map | null) => void
  imageMapBounds?: LngLatLike[]
  setImageMapBounds: (bounds: LngLatLike[]) => void
  projectToRatioPoints: (point: { x: number; y: number }) => {
    x: number
    y: number
  }
  projectRatioPointsToLngLat: (point: { x: number; y: number }) => LngLatLike
}

const MapLibreContext = React.createContext<IMapLibreContext>({
  mapType: MapType.GoogleMap,
  setMapType: () => {},
  mapMode: '2D',
  setMapMode: () => {},
  map: null,
  onLoad: () => {},
  setImageMapBounds: () => {},
  projectToRatioPoints: () => {
    return { x: 0, y: 0 }
  },
  projectRatioPointsToLngLat: () => [0, 0],
})

export function MapLibreProvider(props: any) {
  const [mapMode, setMapMode] = React.useState<IMapLibreMapMode>('2D')
  const [mapType, setMapType] = React.useState<MapType>(MapType.GoogleMap)
  const [map, setMap] = React.useState<Map | null>(null)
  const [imageMapBounds, setImageMapBounds] = React.useState<
    LngLatLike[] | undefined
  >(undefined)

  const value = React.useMemo<IMapLibreContext>(() => {
    const projectToRatioPoints = (point: { x: number; y: number }) => {
      if (!imageMapBounds || !map) {
        return { x: 0, y: 0 }
      }

      const rectangleTopLeft = map.project(imageMapBounds[0])
      const rectangleBottomRight = map.project(imageMapBounds[1])
      const rectWidth = rectangleBottomRight.x - rectangleTopLeft.x
      const rectHeight = rectangleBottomRight.y - rectangleTopLeft.y

      const needToProjectPoint = map.project(map.unproject([point.x, point.y]))

      const pointXInside = Math.max(
        rectangleTopLeft.x,
        Math.min(needToProjectPoint.x, rectangleBottomRight.x)
      )
      const pointYInside = Math.max(
        rectangleTopLeft.y,
        Math.min(needToProjectPoint.y, rectangleBottomRight.y)
      )

      const x = ((pointXInside - rectangleTopLeft.x) / rectWidth) * 100
      const y = ((pointYInside - rectangleTopLeft.y) / rectHeight) * 100

      return {
        x: Number(x.toFixed(12)),
        y: Number(y.toFixed(12)),
      }
    }

    const projectRatioPointsToLngLat = (point: { x: number; y: number }) => {
      if (!imageMapBounds || !map) {
        return [0, 0] as LngLatLike
      }

      const rectangleTopLeft = map.project(imageMapBounds[0])
      const rectangleBottomRight = map.project(imageMapBounds[1])
      const rectWidth = rectangleBottomRight.x - rectangleTopLeft.x
      const rectHeight = rectangleBottomRight.y - rectangleTopLeft.y

      let pointXInside = rectangleTopLeft.x + (point.x / 100) * rectWidth
      let pointYInside = rectangleTopLeft.y + (point.y / 100) * rectHeight
      if (pointYInside - rectangleTopLeft.y < 0) {
        pointYInside = rectangleTopLeft.y
      }
      if (pointYInside > rectangleTopLeft.y + rectHeight) {
        pointYInside = rectangleTopLeft.y + rectHeight
      }
      if (pointXInside - rectangleTopLeft.x < 0) {
        pointXInside = rectangleTopLeft.x
      }
      if (pointXInside > rectangleTopLeft.x + rectWidth) {
        pointXInside = rectangleTopLeft.x + rectWidth
      }

      return map.unproject([pointXInside, pointYInside])
    }

    return {
      mapMode,
      setMapMode,
      map,
      onLoad: setMap,
      imageMapBounds,
      setImageMapBounds,
      projectToRatioPoints,
      projectRatioPointsToLngLat,
      mapType,
      setMapType,
    }
  }, [mapMode, map, imageMapBounds, mapType])

  return <MapLibreContext.Provider value={value} {...props} />
}

export function useMapLibre() {
  const context = React.useContext(MapLibreContext)
  if (!context) {
    throw new Error('useMapLibre must be within a MapLibreProvider')
  }

  return context
}

export default MapLibreProvider
