import React, { SVGProps } from 'react'
const CarbonSave = (props: SVGProps<any>) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9429 51.0563C11.7896 51.0563 11.637 51.0534 11.4851 51.0476C10.7524 51.0198 10.0364 50.9253 9.34292 50.7701C4.00438 49.5751 0 44.7803 0 39.0594C0 33.4979 3.75299 28.7018 9.12925 27.3973L9.26345 27.3625L9.25479 27.0929C9.25046 27.019 9.25046 26.9494 9.25046 26.8798C9.25046 18.6746 15.895 12 24.0633 12C28.366 12 32.4567 13.8785 35.2747 17.1614L35.3786 17.2832L35.5084 17.1875C37.5646 15.6787 39.9973 14.8786 42.5512 14.8786C49.1352 14.8786 54.4941 20.2574 54.4941 26.8755C54.4941 26.9364 54.4941 26.9972 54.4855 27.0538V27.3016L54.624 27.3321C60.0565 28.5278 64 33.4588 64 39.0507C64 45.6645 58.6454 51.0476 52.0571 51.0476H14.5429H11.9429V51.0563ZM54.0651 29.8713C54.0654 29.8713 54.0657 29.8714 54.066 29.8715C58.2959 30.803 61.4 34.6693 61.4 39.0507C61.4 44.2402 57.1978 48.4476 52.0571 48.4476H11.9429H11.5388C6.58664 48.2341 2.6 44.1152 2.6 39.0594C2.6 34.6877 5.54171 30.9432 9.74232 29.924L9.76204 29.9192L9.78167 29.9141L9.91586 29.8793L11.9289 29.3575L11.8621 27.279L11.8534 27.0094L11.8523 26.9752L11.8507 26.9478C11.8507 26.947 11.8507 26.946 11.8507 26.9449L11.8505 26.922L11.8505 26.8798C11.8505 20.0995 17.342 14.6 24.0633 14.6C27.6048 14.6 30.9744 16.145 33.2995 18.8522C33.3003 18.8531 33.301 18.854 33.3018 18.8549L33.4007 18.9708L34.9718 20.8121L36.9206 19.3765L37.0466 19.2837C37.0474 19.2831 37.0481 19.2826 37.0488 19.2821C38.6599 18.1005 40.5533 17.4786 42.5512 17.4786C47.6643 17.4786 51.854 21.6428 51.8939 26.8012L51.8855 26.8558V27.0538V27.3016V29.3923L53.9275 29.841L54.0651 29.8713Z"
      fill="#FFCC2B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.8313 41.3434C32.0767 39.2335 29.2499 32.1539 30.1715 28.3838C32.6391 18.2973 42.4038 17.3356 46.5685 10C52.8722 14.5332 55.2595 23.8292 52.4339 32.1802C50.5299 37.8018 45.1539 39.6773 42.2772 40.2957"
      fill="#FFCC2B"
    />
    <path
      d="M37.8313 41.3434C32.0767 39.2335 29.2499 32.1539 30.1715 28.3838C32.6391 18.2973 42.4038 17.3356 46.5685 10C52.8722 14.5332 55.2595 23.8292 52.4339 32.1802C50.5299 37.8018 45.1539 39.6773 42.2772 40.2957"
      stroke="#FFCC2B"
      strokeWidth="2.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.2744 48.0001C36.9417 35.6935 43.3719 26.3461 47.0205 18.312"
      stroke="#FFCC2B"
      strokeWidth="2.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.8253 34.6764C40.2893 29.3372 43.0575 24.6345 45.0205 20.312"
      stroke="#003248"
      strokeWidth="2.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CarbonSave
