import React, { SVGProps } from 'react'
const Leaves = (props: SVGProps<any>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 46 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2679 56.2182C6.51838 52.6436 1.72921 40.6492 3.29054 34.2619C7.47126 17.173 24.0147 15.5438 31.0706 3.11572C41.7503 10.7959 45.7949 26.5453 41.0078 40.6938C37.782 50.218 28.674 53.3954 23.8002 54.4431"
      fill="#FFCC2B"
    />
    <path
      d="M16.2679 56.2182C6.51838 52.6436 1.72921 40.6492 3.29054 34.2619C7.47126 17.173 24.0147 15.5438 31.0706 3.11572C41.7503 10.7959 45.7949 26.5453 41.0078 40.6938C37.782 50.218 28.674 53.3954 23.8002 54.4431"
      stroke="#FFCC2B"
      strokeWidth="4.40495"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.7128 70.8844C11.3723 50.0345 22.2665 34.198 28.4479 20.5865"
      stroke="#FFCC2B"
      strokeWidth="4.40495"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.952 44.9228C20.4323 35.8771 25.1222 27.9098 28.4479 20.5865"
      stroke="#4A4A4A"
      strokeWidth="5.42148"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Leaves
