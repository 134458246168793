const AttachedCamera = ({ color = '#fff', ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.333 2v2.327L15 2v11l-4.667-2.328V13H1V2h9.333z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default AttachedCamera
