const Switchbox = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#0074A8">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path d="M14 10.222v2.963c0 .818-.663 1.482-1.481 1.482H3.48c-.818 0-1.481-.664-1.481-1.482v-2.963h4.5c0 .818.663 1.482 1.481 1.482h.038c.818 0 1.481-.664 1.481-1.482H14zm-1.481-8.889c.818 0 1.481.664 1.481 1.482V8.74H9.5V7.258c0-.77-.588-1.403-1.339-1.474l-.142-.007H7.98c-.818 0-1.481.663-1.481 1.481V8.74H2V2.816c0-.818.663-1.482 1.481-1.482h9.038z" transform="translate(-323 -758) translate(184 596) translate(0 58) translate(8) translate(0 96) translate(131 8)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Switchbox
