/**
 * 已報修(未派工)=reported
 * 維修中=repairing
 * 結案|完工結案=close|finish
 *
 */
export enum REPAIR_STATE {
  REPORTED = 'reported',
  REPAIRING = 'repairing',
  CLOSE = 'close',
  FINISH = 'finish',
}
