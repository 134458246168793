import { useRequest } from '@shared/innmaxLib/services'
import type { MutationOptions } from '@shared/innmaxLib/services'

export const useUpdateLampBrightness = (options?: MutationOptions) => {
  const { loading, data, mutate } = useRequest(
    '/ctrler/types/lamp/brightnessCtrl',
    {
      ...options,
      method: 'POST',
    }
  )

  return {
    data,
    isLoading: loading,
    mutate,
  }
}
