import packageInfo from '../desktop/package.json'

class EnvStore {
  pageSize = 10

  uploadFileUrlPrefix = process.env.REACT_APP_UPLOAD_FILE_URL_PREFIX

  get cspNonce(): string {
    return 'yjm6aQhtaGa+5+qeLtdeuw=='
  }
  get isGoogleTranslateDisabled(): boolean {
    return process.env.REACT_APP_DISABLED_GOOGLE_TRANSLATE === 'true'
  }
  get isGoogleReCaptchaDisabled(): boolean {
    return process.env.REACT_APP_DISABLED_GOOGLE_RECAPTCHA === 'true'
  }
  get apiBaseUrl(): string {
    return process.env.REACT_APP_API_ROOT || '/'
  }
  get isEnvDev(): boolean {
    return process.env.NODE_ENV === 'development'
  }
  get appBaseName(): string {
    return process.env.REACT_APP_BASE_NAME || ''
  }
  get googleMapsApiKey(): string {
    return (
      process.env.REACT_APP_GOOGLE_MAPS_KEY ||
      (this.isEnvDev
        ? 'AIzaSyD-ZETNFwBmkF7xcFu45OMUqttyVgNs2gc'
        : 'AIzaSyD-ZETNFwBmkF7xcFu45OMUqttyVgNs2gc')
    )
  }
  get googleReCAPTCHAKey(): string {
    return (
      process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ||
      '6LfVncQUAAAAACx7g4lBiP4_k_N0J7ZApSYcbnd8'
    )
  }
  get googleMapsGeocodingApiBaseUrl(): string {
    return 'https://maps.googleapis.com/maps/api/geocode'
  }
  get publicUrl(): string {
    return process.env.PUBLIC_URL?.replace('/mobile', '') || ''
  }
  get appUrl(): string {
    return `${window.location.protocol}//${window.location.host}${this.appBaseName}`
  }
  get canUseDOM(): boolean {
    return !!(
      typeof window !== 'undefined' &&
      window.document &&
      window.document.createElement
    )
  }
  get imageUrlPrefix(): string {
    return `${this.publicUrl.replace('/mobile', '')}/img`
  }
  get mqttServer(): any {
    return {
      url:
        process.env.REACT_APP_MQTT_SERVER_URL ||
        'ws://broker.mqttdashboard.com:8000/mqtt',
      username: process.env.REACT_APP_MQTT_SERVER_USERNAME || '',
      password: process.env.REACT_APP_MQTT_SERVER_PASSWORD || '',
    }
  }
  get minimumClusterSize(): number {
    let minClusterSize = process.env.REACT_APP_MINIMUN_CLUSTER_SIZE
    return minClusterSize ? Number(minClusterSize) : 16
  }
  get defaultClusterSize(): number {
    return Number(process.env.REACT_APP_DEFAULT_CLUSTER_SIZE) || 2
  }
  get clientUrl(): string {
    return this.isEnvDev
      ? 'http://localhost:3000/nid/auth'
      : 'http://59.120.53.132/CampusFCU/nid/auth'
  }
  set setPageSize(pageSize: number | undefined) {
    this.pageSize = pageSize || 50
  }
  get appVersion(): string {
    return packageInfo.version || '0.0.0'
  }
  get mapTypeSupported(): string[] {
    return process.env.REACT_APP_MAP_TYPE_SUPPORTED?.split(',') || ['GoogleMap']
  }
  get deviceTypeSupported(): string[] {
    return process.env.REACT_APP_DEVICES?.split(',') || ['']
  }
  get mobileUrl(): string | null {
    return process.env.REACT_APP_MOBILE_URL
      ? process.env.REACT_APP_MOBILE_URL
      : null
  }
}

export const envStore = new EnvStore()

export const endpoints = {
  apiBaseUrl: envStore.apiBaseUrl,
}

export const defaultPageSize = () => envStore.pageSize

export const imageUrlPrefix = envStore.imageUrlPrefix

export const mqttServer = envStore.mqttServer

export const publicUrl = envStore.publicUrl

export const clientUrl = envStore.clientUrl

export const uploadFileUrlPrefix = envStore.uploadFileUrlPrefix

export const mapTypeSupported = envStore.mapTypeSupported

export const deviceTypeSupported = envStore.deviceTypeSupported

export default envStore
