import React from 'react'
import {
  path,
  map,
  join,
  split,
  ifElse,
  not,
  isNil,
  length,
  pathOr,
  always,
} from 'ramda'
import * as Icons from '@shared/icons'
import { useAuth, UserPermission } from '@services/auth'
const { compose } = require('ramda')

export type RouteItem = {
  id: number
  title: string
  path: string
  component: typeof React.Component
  icon?: React.FunctionComponent
  disabled?: boolean
  param?: string
  children?: RouteItem[]
  hide?: RouteItem[]
  creatable?: boolean
  updatable?: boolean
  deletable?: boolean
  readable?: boolean
  isSubMenu: boolean
  code: string
  mainKey: string
  subKey: string
  parentName?: string
  isHideOnMenu?: boolean
  url: string
}

export interface Route extends RouteItem {
  children: Array<RouteItem>
  hide: Array<RouteItem>
  disabled: boolean
}

let setting = {
  creatable: true,
  deletable: true,
  isSubMenu: true,
  readable: true,
  updatable: true,
}

export function usePrepareRoutes() {
  const { user } = useAuth()

  let _user: any = {
    ...user,
    funcList: [
      ...pathOr([], ['funcList'], user),
      {
        icon: 'IconSetting',
        id: 90,
        isSub: false,
        isSubMenu: false,
        mainKey: '未加入菜單的頁面',
        memberRoleSeq: 1,
        name: '未加入菜單的頁面',
        children: [
          {
            ...setting,
            code: 'repair_query',
            icon: 'IconSetting',
            id: 10,
            isSubMenu: false,
            mainKey: 'device mgmt',
            name: '定位設定',
            parentId: 90,
            parentName: '未加入菜單的頁面',
            subKey: 'repair inquiry',
            url: '/device/charging-pile-management',
          },
        ]
      }
    ],
  }

  return (routes: { [key: string]: any }) => {
    // const __getHide = (path: any, component: any) => {
    //   const hide = pathOr({}, ['hideList', path], _user)
    //   return isEmpty(hide) ? [] : [{ ...hide, path, component }]
    // }

    const __getComponent = (p: any) => {
      return path([
        compose(
          join(''),
          map((s: string) => s.charAt(0).toUpperCase() + s.slice(1)),
          split('-'),
          ifElse(v => length(v) === 3, pathOr('', [2]), pathOr('', [1])),
          split('/')
        )(p),
      ])(routes)
    }

    const funcList = compose(
      map((x: UserPermission) => ({
        ...x,
        title: x.name,
        path: x.url,
        icon: ifElse(
          compose(not, isNil, path([x.icon])),
          () => (Icons as any)[x.icon],
          always(null)
        )(Icons),
        ...(x.isSubMenu
          ? {}
          : {
              component: __getComponent(pathOr('', ['url'], x)),
              // hide: __getHide(
              //   pathOr('', ['url'], x),
              //   __getComponent(pathOr('', ['url'], x))
              // ),
            }),
        children: compose(
          map((c: UserPermission) => {
            const component = __getComponent(pathOr('', ['url'], c))
            return {
              ...c,
              id: `${c.code}_${c.id}`,
              title: c.name,
              path: c.url,
              component,
              // hide: __getHide(c.url, component),
              isHideOnMenu: c.url ? true : false,
            }
          }),
          pathOr([], ['children'])
        )(x),
      })),
      pathOr([], ['funcList'])
    )(_user)

    return funcList
  }
}

export default usePrepareRoutes
