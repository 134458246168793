import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import styled from 'styled-components'
import { ControlPosition, DomEvent } from 'leaflet'
import FilterIcon from './FilterIcon'
import { POSITION_CLASSES } from '../PositionClasses'

export type SearchControlProps = {
  position?: ControlPosition
  styles?: React.CSSProperties
  onClick?: () => void
  active?: boolean
}

function SearchControl({
  position = 'topRight' as ControlPosition,
  styles = {},
  onClick,
  active,
}: SearchControlProps) {
  const { t } = useTranslation()

  const ref = React.useRef<any>()

  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright

  React.useEffect(() => {
    DomEvent?.disableClickPropagation(ref?.current)
  }, [])

  return (
    <div className={positionClass} style={styles}>
      <StyledControl
        id="filter-control"
        className="leaflet-control leaflet-bar">
        <button
          ref={ref}
          title={t('dashboard:search device')}
          onClick={onClick}
          className={cx({ active: active })}>
          <FilterIcon color="#fff" />
        </button>
      </StyledControl>
    </div>
  )
}

const StyledControl = styled.div`
  display: flex;
  flex-direction: row;
  user-select: none;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  width: 32px;
  min-height: 32px;
  margin-top: unset !important;
  margin-right: unset !important;
  border: 0 !important;
  background-clip: unset !important;

  button {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    min-height: 32px;
    color: #fff;
    background-color: #0074a8;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 30%);
    position: absolute;
    right: 0;

    &.active,
    &:hover {
      background-color: #ffe288;
      color: #0074a8;

      svg g {
        stroke: #0074a8;
      }
    }
  }
`

export default React.memo(SearchControl)
