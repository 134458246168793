import { useMutation } from 'react-query'
import { filter, ifElse, is, identity, pathOr, isNil, of } from 'ramda'
import { mapIndexed } from '@shared/innmaxLib/utils/webHelper'
import type { MutationOptions, Mutation } from './services.types'
const { compose } = require('ramda')

export type DataSource<T> = {
  content: T[]
  total: number
  page?: number
  pageSize?: number
  totalPages: number
  current?: number
}

type Config<T> = {
  field?: string | string[]
  converter?: (x: T, idx: number) => any
}

export function convertData<T>(config: Config<T>): (data: any) => DataSource<T>
export function convertData<T>(config: Config<T>, data: any): DataSource<T>
export function convertData<T>(
  { field = ['data'], converter = (_: T) => ({}) }: Config<T>,
  data?: { data: any[] }
) {
  let path = compose(
    filter(x => !isNil(x)),
    ifElse(is(String), of, identity)
  )(field)

  const _convertData = (_data: any) => {
    const dataSource: DataSource<T> = {
      content: mapIndexed(
        (x: T, idx: number) => ({
          ...x,
          ...converter(x, idx),
        }),
        pathOr([], path, _data)
      ),
      current: pathOr(0, ['page'], _data) + 1,
      total: pathOr(0, ['total'], _data),
      page: pathOr(0, ['page'], _data) + 1,
      pageSize: pathOr(10, ['size'], _data),
      totalPages: pathOr(0, ['totalPages'], _data),
    }

    return dataSource
  }

  if (!data) {
    return _convertData
  }

  return _convertData(data)
}

export const useMakeMutation = (
  request: (values: any) => Promise<any>,
  options: MutationOptions
) => {
  const { isLoading, mutate, mutateAsync } = useMutation(request, options)
  return [isLoading, mutate, mutateAsync] as Mutation
}
