import React from 'react'
import styled from 'styled-components'
import { Checkbox } from 'antd'
import { CheckboxProps } from 'antd/lib/checkbox'
// import { RefProps } from '@lib/types'

const StyledCheckbox = styled((props: CheckboxProps) => (
  <Checkbox {...props} />
))`
  .ant-checkbox {
    position: relative;
    top: 0.1px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${p => p.theme.checkbox.bg};
    border-color: ${p => p.theme.checkbox.border};
  }

  &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${p => p.theme.checkbox.border};
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    border: 2px solid ${p => p.theme.darkGrey};
    border-top: 0;
    border-left: 0;
  }

  .ant-checkbox-inner {
    border: 1px solid ${p => p.theme.checkbox.border};
  }

  &.is--normal {
    font-size: 14px !important;
    font-weight: normal !important;
  }
`

// interface CheckboxPropTypes extends RefProps, CheckboxProps {}

// const CheckboxComponent: React.ElementType<CheckboxProps> = React.forwardRef<
//   typeof StyledCheckbox,
//   CheckboxPropTypes
// >((props, ref) => <StyledCheckbox forwardRef={ref} {...props} />)

class CheckboxComponent extends React.Component<CheckboxProps> {
  public static Group = Checkbox.Group
  render() {
    return <StyledCheckbox {...this.props} />
  }
}

export default CheckboxComponent
