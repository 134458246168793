import { Func, FuncDefList, FuncSetting } from './user.types'
import { compose, forEach, pathOr, values } from 'ramda'
import i18n from 'i18next'
import { isNil } from '@shared/innmaxLib/utils/webHelper'
import { Role } from '../auth/auth.types'

export let acts: {
  CREATE: object
  READ: object
  UPDATE: object
  DELETE: object
} = {
  CREATE: {},
  READ: {},
  UPDATE: {},
  DELETE: {},
}

function initial() {
  acts = {
    CREATE: { value: 'add', name: i18n.t('common:add') },
    READ: { value: 'view', name: i18n.t('common:view') },
    UPDATE: { value: 'edit', name: i18n.t('common:modify') },
    DELETE: { value: 'delete', name: i18n.t('common:delete') },
  }
}

initial()

const convertToFuncSetting = (def: FuncDefList) => {
  return {
    id: def.id,
    title: def.subName,
    name: def.code,
    action: [
      def.viewAvailable ? { ...acts.READ, id: def.id } : null,
      def.editAvailable ? { ...acts.UPDATE, id: def.id } : null,
      def.addAvailable ? { ...acts.CREATE, id: def.id } : null,
      def.deleteAvailable ? { ...acts.DELETE, id: def.id } : null,
    ],
  } as FuncSetting
}

export const setFunc = (auth: FuncDefList, permission: Func) => {
  if (permission) {
    const child = convertToFuncSetting(auth)

    return {
      ...permission,
      children: [...permission.children, child],
    }
  }

  return {
    id: auth.code,
    title: auth.name,
    children: [convertToFuncSetting(auth)],
    webPath: auth.webPath
  } as Func
}

const convertAuthToFuncSetting = (x: any) => {
  return {
    id: x.id,
    title: x.subName,
    name: x.code,
    webPath: x.webPath,
    action: [
      isNil(x.authView)
        ? null
        : { ...acts.READ, auth: x.authView, id: x.authDefinitionSeq },
      isNil(x.authEdit)
        ? null
        : { ...acts.UPDATE, auth: x.authEdit, id: x.authDefinitionSeq },
      isNil(x.authAdd)
        ? null
        : { ...acts.CREATE, auth: x.authAdd, id: x.authDefinitionSeq },
      isNil(x.authDelete)
        ? null
        : { ...acts.DELETE, auth: x.authDelete, id: x.authDefinitionSeq },
    ],
  }
}

export const setAuthByFunc = (auth: any, permission: Func) => {
  if (permission) {
    const child = convertAuthToFuncSetting(auth)

    return {
      ...permission,
      children: [...permission.children, child],
    }
  }
  return {
    id: auth.code,
    title: auth.name,
    children: [convertAuthToFuncSetting(auth)],
    webPath: auth.webPath
  } as Func
}

export function useRolePermission(item: Role | undefined) {
  let funcById: { [key: string]: any } = {}
  if (item) {
    compose(
      forEach((x: any) => {
        funcById[x.name] = setAuthByFunc(x, funcById[x.name])
      }),
      pathOr([], ['authDetails'])
    )(item)
  }

  return {
    data: item,
    funcList: values(funcById),
    funcById,
  }
}
