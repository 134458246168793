import { SVGProps } from 'react'

const ToogleIcon = (props: SVGProps<any>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M8.66667 21L2.61444 17.9355C2.42991 17.8421 2.27469 17.6986 2.16617 17.521C2.05765 17.3433 2.00011 17.1386 2 16.9297V4.82025C2.0001 4.62856 2.04858 4.44008 2.14083 4.27268C2.23308 4.10529 2.36604 3.96453 2.5271 3.86378C2.68817 3.76303 2.87199 3.70561 3.06112 3.69699C3.25025 3.68836 3.43842 3.72881 3.60778 3.8145L8.66667 6.375M8.66667 21L15.3333 17.625M8.66667 21V6.375M8.66667 6.375L15.3333 3M15.3333 17.625L20.3922 20.1855C20.5616 20.2712 20.7498 20.3116 20.9389 20.303C21.128 20.2944 21.3118 20.237 21.4729 20.1362C21.634 20.0355 21.7669 19.8947 21.8592 19.7273C21.9514 19.5599 21.9999 19.3714 22 19.1797V7.07025C21.9999 6.86138 21.9424 6.65667 21.8338 6.47903C21.7253 6.3014 21.5701 6.15786 21.3856 6.0645L15.3333 3M15.3333 17.625V3"
      stroke="white"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default ToogleIcon
