/* eslint-disable react/jsx-pascal-case */
import * as React from 'react'

export interface WithChildrenProps {
  children: React.ReactNode
}

export type PackComponentType<
  T extends WithChildrenProps = WithChildrenProps
> = React.ElementType<T>

export function pack(
  children: React.ReactNode = null,
  ...components: PackComponentType[]
) {
  if (!components.length) {
    return children as JSX.Element
  }

  const [Component, ...rest] = components

  if (React.isValidElement(Component)) {
    return (
      <Component.type {...Component.props}>
        {pack(children, ...rest)}
      </Component.type>
    )
  }

  return <Component>{pack(children, ...rest)}</Component>
}

export default function createPack(...components: any[]) {
  return function PackComponent({ children }: WithChildrenProps) {
    return pack(children, ...components)
  }
}
