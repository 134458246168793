import { useTranslation } from 'react-i18next'
import React from 'react'
import { includes } from 'ramda'
import Select from '@shared/Form/Select'

import { useAllSessions } from '@services/session'

type Props = {
  showAllAreas?: boolean
  allSession?: boolean
  onChange?: (values: number[]) => void
  value?: number[]
  className?: string
}

const MultipleDistrict = React.memo<Props>(
  ({
    showAllAreas = false,
    allSession = false,
    onChange,
    value = [],
    ...props
  }) => {
    const { t } = useTranslation()

    const { data } = useAllSessions()

    const [optionsDisabled, setOptionsDisabled] = React.useState(allSession)

    const handleChange = React.useCallback(
      (value: number[]) => {
        if (includes(-1, value)) {
          setOptionsDisabled(true)
          onChange && onChange([-1])
        } else {
          setOptionsDisabled(false)
          onChange && onChange(value)
        }
      },
      [onChange]
    )

    return (
      <Select
        mode="multiple"
        placeholder={t('light:select dist')}
        allowClear
        onChange={handleChange}
        value={value}
        dropdownRender={menu => <div>{menu}</div>}
        {...props}>
        {showAllAreas && (
          <Select.Option key="all" value={-1}>
            {t('common:all area')}
          </Select.Option>
        )}
        {data.map((x: any) => (
          <Select.Option key={x.id} value={x.id} disabled={optionsDisabled}>
            {x.name}
          </Select.Option>
        ))}
      </Select>
    )
  }
)

export default MultipleDistrict
