import { SVGProps } from 'react'

const SelectorSVG = ({ color = '#4A4A4A', ...props }: SVGProps<any>) => (
  <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g stroke="#4A4A4A" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.4">
        <path d="M14 22H6v-8M14 6h8v8"/>
      </g>
    </g>
</svg>

)

export default SelectorSVG
