import React from 'react'
import LayoutTreeNode, { LayoutTreeNodeProps } from './LayoutTreeNode'
import TreeContext, { TreeContextType } from './TreeContext'
import { append, includes, isNil, not, path, pathOr } from 'ramda'
import { TooltipProps } from 'antd'

export interface TreeData extends LayoutTreeNodeProps {
  children?: TreeData[]
}

interface LayoutTreeProps {
  treeData?: TreeData[]
  maxLayoutLevel?: number
  onEdit?: (payload?: Record<string, any>) => void
  onAdd?: (parentPayload?: Record<string, any>) => void
  onSelect?: (payload?: Record<string, any>) => void
  defaultExpandIds?: number[]
  className?: string
  showAddNodeButton?: boolean
  showSelectedItem?: boolean
  showSessionNo?: boolean
  /**
   * 隱藏圖資 Icon
   */
  hideLayerImageIcon?: boolean
  onTooltipProps?: (payload?: Record<string, any>) => TooltipProps
  layoutTree?: LayoutTreeInstance
}

export default React.memo<LayoutTreeProps>(props => {
  const {
    treeData = [],
    maxLayoutLevel = Infinity,
    onEdit = payload => {},
    onAdd = parentPayload => {},
    onSelect = payload => {},
    defaultExpandIds = [],
    className = '',
    showAddNodeButton = false,
    showSelectedItem = false,
    showSessionNo = false,
    hideLayerImageIcon = false,
    onTooltipProps,
    layoutTree,
  } = props

  const [currentItem, setCurrentItem] = React.useState<
    Record<string, any> | undefined
  >()

  const [expandIds, setExpandIds] = React.useState<number[]>(defaultExpandIds)
  const [layoutTreeInstance] = useLayoutTree(layoutTree)

  React.useEffect(() => {
    if (layoutTreeInstance) {
      layoutTreeInstance.closeAndExpandIds = (ids: number[]) => {
        setExpandIds(ids)
      }
    }
  }, [layoutTreeInstance])

  const renderTrees = React.useCallback(
    (treeData: TreeData[]): React.ReactNode[] => {
      return treeData.map(tree => {
        return <LayoutTreeNode key={tree.id} {...tree} layoutLevel={1} />
      })
    },
    []
  )

  const handleOnSelect = React.useCallback(
    (value: Record<string, any> | undefined) => {
      setCurrentItem(value)
      onSelect && onSelect(value)
    },
    []
  ) // eslint-disable-line

  const treeNodes = React.useMemo((): React.ReactNode[] => {
    return renderTrees(treeData)
  }, [renderTrees, treeData])

  const contextValue = React.useMemo(
    (): TreeContextType => ({
      expandIds,
      setExpandIds,
      maxLayoutLevel,
      onEdit,
      onAdd,
      className,
      showAddNodeButton,
      showSelectedItem,
      showSessionNo,
      onSelect: handleOnSelect,
      currentItem,
      hideLayerImageIcon,
      onTooltipProps,
    }),
    [
      expandIds,
      maxLayoutLevel,
      onEdit,
      onAdd,
      className,
      showAddNodeButton,
      showSelectedItem,
      showSessionNo,
      handleOnSelect,
      currentItem,
      hideLayerImageIcon,
      onTooltipProps,
    ]
  )

  return (
    <TreeContext.Provider value={contextValue}>
      {treeNodes}
    </TreeContext.Provider>
  )
})

export type LayoutTreeInstance = {
  closeAndExpandIds: (ids: number[]) => void
}

export const useLayoutTree = (layoutTree?: LayoutTreeInstance) => {
  const tree = React.useRef<LayoutTreeInstance>()
  if (not(isNil(layoutTree))) {
    tree.current = layoutTree
  }
  if (isNil(tree.current)) {
    tree.current = {
      closeAndExpandIds: (ids: number[]) => {},
    }
  }

  return [tree.current]
}
