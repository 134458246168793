import { useQuery } from 'react-query'
import { useAuth } from '.'
import type { MenuAuth, UserPermission } from './auth.types'
import type { IUser } from '../user'
import { setUserAuth } from './useLogin'
import { forEach, pathOr, compose } from 'ramda'
import Store from 'store'

export const useMineInfo = () => {
  const { setIsAuthenticated, setUser, user } = useAuth()

  const { refetch, isLoading, data } = useQuery(['/account/mineInfo'], {
    cacheTime: Infinity,
    refetchOnMount: false,
    enabled: false,
    onSuccess: (response: any) => {
      if (!response) {
        return
      }

      let authById: { [key: string]: UserPermission } = {}
      let authByUrl: { [key: string]: UserPermission } = {}

      compose(
        forEach((x: MenuAuth) => {
          authById[x.name] = setUserAuth(x, authById[x.name])
          authByUrl[x.webPath] = setUserAuth(x, authByUrl[x.webPath])
        }),
        pathOr([], ['data', 'roleObj', 'authDetails'])
      )(response)

      const funcList = Object.keys(authById).map(i => authById[i])

      Store.set('sctc_user', {
        ...Store.get('sctc_user'),
        authByUrl,
        funcList,
      })

      setIsAuthenticated(true)

      setUser({
        ...user,
        authById,
        authByUrl,
        funcList,
      })
    },
  })

  return { refetch, isLoading, dataSource: pathOr<IUser | undefined>(undefined, ['data'], data) }
}
