/**
 * 	DAILYREPORT(1, "日報"),
	MONTHLYREPORT(3, "月報"),
	YEARLYREPORT(4, "年報");
 */
import { isNil, sum, pathOr } from 'ramda'
import i18n from 'i18next'
import { formatDate } from '@shared/utils/webHelper'
import { TYPE, searchMonthSelectItems, SearchMonthProps } from './report.enum'

export const searchYearSelectItems = ((startYear = 2019) => {
  const currentYear = new Date().getFullYear(),
    years = []
  startYear = startYear || 2019
  while (startYear <= currentYear) {
    years.push({
      label: startYear,
      value: startYear,
    })
    startYear++
  }
  return years
})()

// current month
export const getLastMonthValue = () => {
  const month = searchMonthSelectItems.find(
    i => i.label === new Date().getMonth() + 1
  ) as SearchMonthProps
  return month.value
}

export const xAxisLabels = searchMonthSelectItems.map(i => i.name)

export const xAxisName = (v: any) => {
  const month = searchMonthSelectItems.find(i => i.label === v + 1) as SearchMonthProps
  return month.name
}

export const getAverageData = (values: any) => {
  const avgList: any[] = []

  Array.from({ length: values.length }, (_, i) => {
    const range = values.slice(0, i + 1)
    const len = range.filter((i: any) => !isNil(i)).length

    const avg = len > 0 ? Number((sum(range.map((i: any) => i.y)) / len).toFixed(2)) : null

    const data = { name: values[i].name, y: avg ? avg : 0 }

    return avgList.push(data)
  })

  return avgList
}

export const getTitle = (values: any) => {
  const { reportType, sessionName, year, month } = values

  const [start, end]: any = reportType === TYPE.DAY ? pathOr([], ['date'], values) : []

  let title,
    xLabel = ''

  switch (reportType) {
    case TYPE.YEAR:
      xLabel += `${i18n.t('report:year', { num: year })} `
      break
    case TYPE.MONTH:
      const item = searchMonthSelectItems.find((i: SearchMonthProps) => i.value === month)
      xLabel += item?.name
      break
    case TYPE.DAY:
      xLabel += `${start ? formatDate(start) : ''} - ${end ? formatDate(end) : ''} `
      break
    default:
  }

  if (sessionName) {
    title = xLabel + sessionName
  }
  if (!sessionName) {
    title = xLabel + i18n.t('common:all area')
  }

  return { title, xLabel }
}

export const getLinkRateAverageData = (values: number[]) => {
  const avg: (number | null)[] = []

  Array.from({ length: values.length }, (_, i) => {
    const range = values.slice(0, i + 1)
    const len = range.filter(i => !isNil(i)).length
    const a = len > 0 ? sum(range) / len : null

    return avg.push(a)
  })

  return avg
}
