import React from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { path, length, isNil } from 'ramda'
import { useTranslation } from 'react-i18next'
import throttle from 'lodash.throttle'
import { useRequest, request, useMakeMutation, useLazyQuery } from '@shared/innmaxLib/services'
import type { QueryVariables, MutationOptions } from '@shared/innmaxLib/services'
import message from '@shared/innmaxLib/utils/message'

import { Device, ILight } from '@services/device'
import { useConvertILights, useConvertILightDetail } from './light.converter'
import type { ILightDetail } from './light.types'

export const useLights = (query?: any, options?: UseQueryOptions) => {
  const { data, ...others } = useQuery(['/device/streetlight/{pageNum}/{pageSize}', query], {
    cacheTime: 0,
    ...options,
  })

  const dataSource = useConvertILights(data)

  return {
    ...others,
    dataSource,
    loading: others.isLoading,
  }
}

export const useLight = (id?: React.Key) => {
  const { data, refetch, isLoading, ...others } = useQuery([`/device/streetlight/{id}`, { id }], {
    enabled: !!id,
    select: (res: any) => path(['data'], res) as ILightDetail,
  })

  const dataSource = useConvertILightDetail(data) as ILightDetail

  return {
    ...others,
    refetch,
    loading: isLoading,
    data: dataSource,
  }
}

export const useLazyQueryLight = ({ variables = {}, ...options }: QueryVariables = {}) => {
  const { data, refetch, isLoading, ...others } = useLazyQuery(
    [`/device/streetlight/{id}`, variables],
    {
      ...options,
      select: (res: any) => path(['data'], res) as ILightDetail,
    }
  )

  const dataSource = useConvertILightDetail(data as ILightDetail) as ILightDetail

  return {
    ...others,
    refetch: () => refetch({}),
    queryLight: refetch,
    loading: isLoading,
    data: dataSource,
  }
}

//控制器型號
export const useControllerType = () => {
  const { data, refetch, isLoading, ...others } = useQuery([`/ctrler/types/lamp`], {
    select: (res: any) => path(['data'], res) as { ctrlerType: string }[],
  })

  return {
    ...others,
    refetch,
    loading: isLoading,
    data,
  }
}

type UseQueryLightOptions = {
  item: Device | null
  onCompleted?: (data: ILight | null) => any
}

export function useQueryLight(options: UseQueryLightOptions) {
  const { item, onCompleted = data => data } = options

  React.useEffect(() => {
    if (item && item.parentDeviceSeq) {
      setIsReadOnly(true)
      queryLightInfo({ lightNo: item.parentDeviceSeq })
    } else {
      setIsReadOnly(false)
      setData(null)
      onCompleted(null)
    }
  }, [JSON.stringify(item)]) // eslint-disable-line

  const inputRef = React.useRef<HTMLInputElement>()

  const { t } = useTranslation()

  const [data, setData] = React.useState<Device | null>(null)

  const [readOnly, setIsReadOnly] = React.useState(false)

  const { loading, mutate } = useRequest('/device/streetlight/lookup', {
    lazy: true,
    throwable: true,
    method: 'GET',
  })

  const queryLightInfo = (lightNo: any) =>
    mutate(lightNo)
      .then(response => {
        setData(response?.data)
        onCompleted(response?.data)
        setIsReadOnly(!isNil(response?.data))
      })
      .catch(() => {
        inputRef.current?.focus()
        message({ content: t('light:cannot find light'), type: 'warning' })
      })
      .finally(() => inputRef.current?.focus())

  const handleLightIdChange = throttle(async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const id = path<string>(['target', 'value'], evt)

    if (!id) {
      setData(null)
      onCompleted(null)
      setIsReadOnly(false)

      return
    }

    inputRef.current?.blur()

    return queryLightInfo(id)
  }, 1500)

  const resetLightInfo = () => {
    queryLightInfo(item?.parentDeviceSeq)
  }

  return {
    loadingLight: loading,
    inputRef,
    data,
    readOnly,
    handleLightIdChange,
    address: data?.address || item?.address || '',
    sessionId: data?.sessionId,
    sessionName: data?.sessionName || '',
    lightSeq: data?.id,
    lat: data?.lat || item?.lat || '',
    lng: data?.lon || item?.lon || '',
    resetLightInfo,
  }
}

export const useInGroupMarkers = ({ variables = {}, ...options }: QueryVariables = {}) => {
  const { data, ...others } = useLazyQuery(['/device/streetlight/0/1000000', variables], {
    cacheTime: 0,
    ...options,
  })

  const dataSource = useConvertILights(data)

  return {
    ...others,
    dataSource,
    loading: others.isLoading,
    lightCount: length(dataSource.content),
  }
}

export const createLight = (values: Partial<ILightDetail>) =>
  request('/device/streetlight', { method: 'POST', body: values })

export const updateLight = ({ id, ...values }: Partial<ILightDetail> = {}) =>
  request(`/device/streetlight/${id}`, { method: 'PUT', body: values })

export const deleteLight = (id: React.Key) =>
  request(`/device/streetlight/${id}`, { method: 'DELETE' })

export const useAddLight = (options: MutationOptions = {}) => {
  return useMakeMutation(createLight, options)
}

export const useUpdateLight = (options: MutationOptions = {}) => {
  return useMakeMutation(updateLight, options)
}

export const useDeleteLight = (options: MutationOptions = {}) => {
  return useMakeMutation(deleteLight, options)
}
