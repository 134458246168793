const Equipment = ({ ...props }) => {
  return (
    <i className="icon-equipment" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20">
        <g
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round">
            <g stroke="#878787" strokeWidth="1.5">
              <path d="M18 2v12.198H2V2h16zm-2.74 15.751H4.74M18 10.531H2" transform="translate(-10 -278) translate(0 120) translate(0 144) translate(10 14)"/>
            </g>
        </g>
      </svg>
    </i>
  )
}

export default Equipment
