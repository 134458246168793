import { Select, SelectProps } from '@shared/Form/Select'
import { DeviceActive } from '@services/constants'
import { useTranslation } from 'react-i18next'

type Props = {
  showAll?: boolean
} & SelectProps

export default function ActiveStateSelect({ showAll = false, ...props }: Props) {
  const { t } = useTranslation()

  return (
    <Select allowClear {...props}>
      {showAll && (
        <Select.Option key="all" value="">
          {t('common:all')}
        </Select.Option>
      )}
      {Object.keys(DeviceActive)
        .filter(d => ['INACTIVE', 'ACTIVE'].indexOf(d) > -1)
        .map(x => (
          <Select.Option key={x} value={(DeviceActive as any)[x]}>
            {t(`light:ACTIVE_STATE_${(DeviceActive as any)[x]}`)}
          </Select.Option>
        ))}
    </Select>
  )
}
