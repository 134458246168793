const AttachedLight = ({ color = '#fff', ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.314 12.53v.573a.918.918 0 0 1-.798.898l-.146.52a.667.667 0 0 1-.647.479h-1.45a.667.667 0 0 1-.647-.48l-.142-.519a.922.922 0 0 1-.802-.902v-.572a.56.56 0 0 1 .572-.552h3.488c.317.004.572.25.572.556zM13 4.823a4.71 4.71 0 0 1-1.403 3.347 4.364 4.364 0 0 0-1.229 2.4.818.818 0 0 1-.818.673H6.446c-.405 0-.756-.282-.814-.669a4.412 4.412 0 0 0-1.237-2.408A4.715 4.715 0 0 1 3 4.874C2.971 2.19 5.185.02 7.967 0 10.744-.02 13 2.147 13 4.822zm-4.436-2.92A.554.554 0 0 0 8 1.357c-1.988 0-3.609 1.558-3.609 3.48 0 .298.25.543.564.543.31 0 .564-.241.564-.543 0-1.322 1.115-2.393 2.481-2.393a.552.552 0 0 0 .564-.544z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default AttachedLight
