export { MapType } from '@shared/innmaxMap/map/map.types'

export const googleMapOptions = {
  mapTypeControl: false,
  fullscreenControl: false,
  controlSize: 28,
  styles: [
    {
      featureType: 'all',
      stylers: [
        {
          saturation: 0,
        },
        {
          hue: '#e7ecf0',
        },
      ],
    },
    {
      featureType: 'road',
      stylers: [
        {
          saturation: -70,
        },
      ],
    },
    {
      featureType: 'transit',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          saturation: -60,
        },
      ],
    },
  ],
}

export const googleMapOptionsWithSimplified = {
  ...googleMapOptions,
}

export const leafletMapOptions = {}