import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select, SelectProps } from '@shared/Form/Select'
import { useCameraCtrlerType } from '@services/device'

export const CameraCtrlerType = (props: SelectProps) => {
  const { onChange, value, ...others } = props

  const { t } = useTranslation()

  const { dataSource } = useCameraCtrlerType()

  return (
    <Select
      allowClear
      placeholder={t('camera:selectCtrlerType')}
      onChange={(type, option) => {
        onChange && onChange(type, option)
      }}
      value={value}
      {...others}>
      {dataSource.map(d => (
        <Select.Option key={d.ctrlerType} value={d.ctrlerType}>
          {d.ctrlerType}
        </Select.Option>
      ))}
    </Select>
  )
}

export default CameraCtrlerType
