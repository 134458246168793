import { Select, SelectProps } from '@shared/Form/Select'
import { REPAIR_STATE } from '@services/repair'
import { useTranslation } from 'react-i18next'

export default function RepairStateSelect({ ...props }: SelectProps) {
  const { t } = useTranslation()

  return (
    <Select allowClear {...props}>
      <Select.Option value={REPAIR_STATE.REPORTED}>{t('repair:RS00')}</Select.Option>
      <Select.Option value={REPAIR_STATE.REPAIRING}>{t('repair:under maintenance')}</Select.Option>
      <Select.Option value={`${REPAIR_STATE.CLOSE},${REPAIR_STATE.FINISH}`}>
        {t('repair:RS03')}
      </Select.Option>
    </Select>
  )
}
