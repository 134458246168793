import React from 'react'
import styled, { css } from 'styled-components'
import { Button as AntButton } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import * as Icons from '@shared/icons'
import type { ActionTypes } from '@services/auth'
import useRouteAuth from '@shared/innmaxLib/routes/useRouteAuth'

export interface Props extends Omit<ButtonProps, 'icon' | 'type' | 'underline'> {
  children?: React.ReactNode
  icon?: string | React.ReactNode
  onClick?: (evt: React.MouseEvent<HTMLElement>) => any
  actionType?: ActionTypes
  theme?: object
  type?:
    | 'default'
    | 'primary'
    | 'danger'
    | 'link'
    | 'icon'
    | 'warning'
    | undefined
  underline?: boolean;
}

function ButtonComponent(
  { icon, children, loading, actionType, ...props }: Props,
  ref: React.ForwardRefRenderFunction<any>
) {
  const [, checkIsAuthenticated] = useRouteAuth()

  if (actionType && !checkIsAuthenticated(actionType)) {
    return null
  }

  return (
    <StyledButton {...props} loading={loading}>
      {(() => {
        if (icon && !loading) {
          let Icon

          const isElement = React.isValidElement(icon)

          if (!isElement) {
            Icon = icon
          }

          if (typeof icon === 'string') {
            const nameCapitalized = icon.charAt(0).toUpperCase() + icon.slice(1)
            const iconName = 'Icon' + nameCapitalized
            Icon = (Icons as any)[iconName]
          }

          return (
            <div className="flex items-center">
              {isElement ? icon : <Icon className={`mr-4 icon-${icon}`} />}
              {children}
            </div>
          )
        }

        return children
      })()}
    </StyledButton>
  )
}

const StyledButton = styled(({ circle, rounded, underline, color, ...props }) => (
  <AntButton {...props} />
))<Props>`
  &[ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }

  &.ant-btn-sm {
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 0px 7px !important;
  }

  &.ant-btn {
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    padding: unset;

    .anticon {
      margin-right: 4px;
    }

    ${p =>
      p.underline &&
      css`
        > span {
          text-decoration: underline;
        }
      `};

    ${p =>
      p.shape === 'round' &&
      css`
        border-radius: 24px;
      `};

    ${p =>
      p.type === 'link' &&
      css`
        min-width: unset !important;
        padding: 0 !important;
        background: transparent;
        border-color: transparent;
        color: ${p.theme.btn.linkColor};
        font-size: unset;

        &:hover {
          background: transparent;
          border-color: transparent;

          > span {
            text-decoration: underline;
          }
        }
      `};

    ${p =>
      p.type === 'icon' &&
      css`
        min-width: auto !important;
        border: 0 !important;
        padding: 0 !important;
        background-color: transparent !important;
        font-size: 14px;
        font-weight: 400;

        & [ant-click-animating-without-extra-node]:after {
          animation: 0s !important;
          // border-color: transparent !important;
        }
      `};

    ${p =>
      p.type === 'primary' &&
      css`
        color: ${p => p.theme.btn.text};
        background: ${p => p.theme.btn.bg};
        border: 1px solid ${p => p.theme.btn.bg};
        box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.16);

        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${p => p.theme.btn.bgHover};
          background: ${p => p.theme.btn.bgHover};
        }
      `};

    ${p =>
      p.type === 'danger' &&
      css`
        color: ${p => p.theme.btn.danger};
        background: transparent;
        border: 1px solid ${p => p.theme.btn.danger};
        box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.16);
        text-shadow: unset;

        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${p => p.theme.btn.danger};
          background: ${p => p.theme.btn.dangerHover};
        }
      `};

    ${p =>
      p.type === 'warning' &&
      css`
        color: ${p => p.theme.btn.text};
        background: ${p => p.theme.btn.warning};
        border: 1px solid ${p => p.theme.btn.warning};
        box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.16);

        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${p => p.theme.btn.warningHover};
          background: ${p => p.theme.btn.warningHover};
        }
      `};

    ${p =>
      p.type === 'default' &&
      css`
        color: ${p => p.theme.btn.bg};
        background: transparent;
        border: 1.5px solid ${p => p.theme.btn.bg};

        &:hover,
        &:focus,
        &:active {
          color: ${p => p.theme.btn.text};
          background: ${p => p.theme.btn.bg};
        }
      `};

    ${p =>
      p.ghost &&
      css`
        color: ${p => p.theme.btn.bg};
        background-color: transparent;

        &:hover,
        &:focus,
        &:active {
          color: ${p => p.theme.btn.ghostColor};
          border: 1px solid ${p => p.theme.btn.ghostColor};
          background-color: ${p => p.theme.btn.ghostHover};
        }
      `};

    ${p =>
      p.color &&
      css`
        border-color: ${p => p.color};
        background: ${p => p.color};

        &:hover,
        &:focus,
        &:active {
          filter: brightness(75%) !important;
          border-color: ${p => p.color};
          background: ${p => p.color};
        }
      `
    }

    :not(.ant-btn-icon) svg {
      cursor: pointer;
      width: 16px;
      height: 16px;

      &.icon-add {
        > g path:nth-child(2),
        > g path:nth-child(2),
        use {
          fill: #fff;
        }
      }
    }
  }
`
export { StyledButton as Butotn }
export default React.forwardRef(ButtonComponent)
