import { SVGProps } from 'react'

const RainningRateSVG = (props: SVGProps<any>) => (
  <svg
    viewBox="0 0 96 96"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path id="a" d="M0 0h16.937v82.216H0z" />
      <path id="c" d="M0 .166h72.807v35.321H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h96v96H0z" />
      <g transform="translate(33.882 7.57)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M8.469 82.216C3.799 82.216 0 78.417 0 73.748v-3.242a2.626 2.626 0 0 1 2.626-2.625 2.625 2.625 0 0 1 2.62 2.625v3.242a3.22 3.22 0 0 0 6.44 0V2.626A2.623 2.623 0 0 1 14.31 0a2.623 2.623 0 0 1 2.626 2.626v71.122c0 4.67-3.799 8.468-8.468 8.468"
          fill="#FFF"
          mask="url(#b)"
        />
      </g>
      <g transform="translate(11.79 16.012)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path
          d="M72.807 34.52a10.234 10.234 0 0 0-8.757-4.939 10.251 10.251 0 0 0-9.28 5.897 10.259 10.259 0 0 0-18.57 0 10.259 10.259 0 0 0-9.285-5.897 10.231 10.231 0 0 0-9.276 5.906 10.247 10.247 0 0 0-9.29-5.906 10.21 10.21 0 0 0-8.351 4.322C1.401 15.04 17.16.166 36.381.166c19.435 0 35.326 15.196 36.426 34.353"
          fill="#FFD63C"
          mask="url(#d)"
        />
      </g>
      <path
        d="M29.43 51.74a.605.605 0 0 1-.596-.597c.005-.274.337-27.098 19.098-35.508l.254-.117.26.132c.18.093 18.266 9.71 18.702 35.478a.594.594 0 0 1-.582.601c-.357-.005-.597-.259-.602-.581-.396-23.43-15.685-33.078-17.817-34.31-9.226 4.273-13.656 13.265-15.754 20.1-2.313 7.53-2.371 14.141-2.371 14.21a.594.594 0 0 1-.592.591"
        fill="#FFF"
      />
      <path
        d="M47.99 52.083a.595.595 0 0 1-.596-.592V16.175a.594.594 0 0 1 1.188 0V51.49a.59.59 0 0 1-.592.592"
        fill="#FFF"
      />
    </g>
  </svg>
)

export default RainningRateSVG
