import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'ramda'
import moment from 'moment'
import { Row, Col, Spin } from 'antd'
import HighchartsReact from 'highcharts-react-official'
import * as Highcharts from 'highcharts'
import Box from '@shared/Box'
import theme from '@theme'

import { PAGE_SIZE, TYPE, useAlarmRpt, dateTimeFormat } from '@services/report'
import { initAlarmBarChart, tooltipPointFormat } from '../ReportChartConfig'
import ChartTitle from '../ChartTitle'
import Pagination from '../Pagination'
import type { IAlarmProps } from '../type'

export function AlarmBarChart(props: IAlarmProps) {
  const { queryVariables, yAxisDateStr } = props

  const { t } = useTranslation()

  const chart = React.useRef<HighchartsReact.RefObject>(null)

  const [startIdx, setStartIdx] = React.useState(0)
  const [currentChart, setCurrentChart] = React.useState(0)
  const query = React.useMemo(() => queryVariables, [queryVariables])

  const [options, setOptions] = React.useState<Highcharts.Options>({
    ...initAlarmBarChart,
    tooltip: {
      ...initAlarmBarChart.tooltip,
      formatter() {
        const _this = this as any
        return [''].concat(
          _this.points
            ? _this.points.map(function (val: any) {
                return (
                  `<b style="font-size:13px; color:${theme.tooltip.color};font-weight:500"><div style="margin-bottom:8px;">` +
                  moment(val.point?.category).format('YYYY.MM.DD') +
                  '</div></b>' +
                  tooltipPointFormat(val, false, t('report:count'))
                )
              })
            : []
        )
      },
    },
  })

  const { isLoading: loading, dataSource } = useAlarmRpt(queryVariables)

  React.useEffect(() => {
    if (!loading) {
      chart?.current?.chart?.hideLoading()
      chart?.current?.chart?.setSize(null, null)
    } else {
      chart?.current?.chart?.showLoading()
    }
  }, [chart, loading])

  React.useEffect(() => {
    const endIdx = startIdx + PAGE_SIZE

    setOptions((x) => ({
      ...x,
      time: {
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      xAxis: {
        ...initAlarmBarChart.xAxis,
        type: 'datetime',
        labels: {
          style: {
            fontSize: '10px',
            color: theme.grey,
          },
          formatter: function(this: any) {
            return dateTimeFormat(query.reportType, this.value)
          },
        },
      },
      yAxis: {
        ...initAlarmBarChart.yAxis,
        title: {
          ...initAlarmBarChart.yAxis.title,
          text: t('report:alarm yAxis Label'),
        },
      },
      series: [
        {
          name: t('report:total number of alarm'),
          type: 'column',
          data: dataSource?.chart?.slice(startIdx, endIdx),
        },
      ],
    }))
  }, [dataSource, currentChart]) // eslint-disable-line

  const handleNextPage = React.useCallback(() => {
    const idx = currentChart + 1

    setStartIdx(PAGE_SIZE * idx)
    setCurrentChart(idx)
  }, [currentChart])

  const handlePrevPage = React.useCallback(() => {
    const idx = currentChart - 1

    setStartIdx(PAGE_SIZE * idx)
    setCurrentChart(idx)
  }, [currentChart])

  React.useEffect(() => {
    if (isEmpty(query)) {
      return
    }

    setStartIdx(0)
    setCurrentChart(0)
  }, [query]) // eslint-disable-line

  const { totalPages } = dataSource

  return (
    <>
      <Pagination
        className="my-40 mx-90"
        page={currentChart + 1}
        totalPages={totalPages}
        onNextPage={handleNextPage}
        onPrevPage={handlePrevPage}
      />

      <Spin spinning={loading}>
        <Row>
          <Col span={24}>
            <Box position="relative" css={{ marginLeft: -35 }}>
              <Box
                position="absolute"
                top="-35px"
                left="65px"
                zIndex={1}
                fontSize="18px"
                className="font-medium">
                {t('report:count')}
              </Box>
              <div style={{ width: '90%', margin: '0 auto' }}>
                <HighchartsReact
                  ref={chart}
                  containerProps={{ style: { width: '100%', height: '100%' } }}
                  highcharts={Highcharts}
                  options={options}
                />
              </div>
              {query.reportType !== TYPE.DAY && (
                <Box position="absolute" className="font-medium" right="0px" fontSize="14px">
                  {yAxisDateStr}
                </Box>
              )}
            </Box>
          </Col>
          <ChartTitle {...props} />
        </Row>
      </Spin>
    </>
  )
}

export default React.memo(AlarmBarChart)
