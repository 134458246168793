import { pathOr, join, pluck } from 'ramda'
import moment from 'moment'
import useDailySetEnum from '@services/hooks/useDailySetEnum'
import type { ISchedule } from './schedule.types'
const { compose } = require('ramda')

export const useConvertISchedule = () => {
  const { TimeSetEnum } = useDailySetEnum()

  return {
    convertISchedule: (x: ISchedule) => ({
      ...x,
      lightGroupCount: x.lightGroups?.length,
      scheduleTime: x.scheduleTime
        ? moment(x.scheduleTime, 'HH:mm')
        : undefined,
      scheduleTimeStr:
        x.type === 2
          ? x.scheduleTime
            ? x.scheduleTime
            : ''
          : TimeSetEnum[x.type],
      lightScheduleTargetGroupStr: compose(
        join(' '),
        pluck('lightGroupName'),
        pathOr([], ['lightGroups'])
      )(x),
    }),
  }
}
