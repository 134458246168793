import MapLibreContainer from './MapContainer'
import Provider from './Provider'
import LayerToogle from './LayerToogle'
import ZoomControlFC from './ZoomControl'
import Marker from './Marker'
import Tooltip from './Tooltip'
import Popup from './Popup'
import MarkerCluster from './MarkerCluster'
import DeviceMarker from './Marker/DeviceMarker'
import SessionMarker from './Marker/SessionMarker'

type IMapLibreContainer = typeof MapLibreContainer

interface IInternalMapLibre extends IMapLibreContainer {
  Provide: typeof Provider
  LayerToogle: typeof LayerToogle
  ZoomControl: typeof ZoomControlFC
  Marker: typeof Marker
  Tooltip: typeof Tooltip
  Popup: typeof Popup
  MarkerCluster: typeof MarkerCluster
  DeviceMarker: typeof DeviceMarker
  SessionMarker: typeof SessionMarker
}

const MapLibre: IInternalMapLibre = MapLibreContainer as IInternalMapLibre
MapLibre.Provide = Provider
MapLibre.LayerToogle = LayerToogle
MapLibre.ZoomControl = ZoomControlFC
MapLibre.Marker = Marker
MapLibre.Tooltip = Tooltip
MapLibre.Popup = Popup
MapLibre.MarkerCluster = MarkerCluster
MapLibre.DeviceMarker = DeviceMarker
MapLibre.SessionMarker = SessionMarker

export default MapLibre
