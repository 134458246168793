import React from 'react'
import { pathOr } from 'ramda'
import styled, { css } from 'styled-components'
import cx from 'classnames'
import {
  Marker as MarkerComponent,
  OverlayView,
  OverlayViewF,
  OverlayViewProps,
  InfoWindow as MarkerInfoWindow,
} from '@react-google-maps/api'

import GoogleMap from '@shared/innmaxMap/map/GoogleMap'
import useVisible from '@shared/innmaxLib/hooks/useVisible'
import { ISession } from '@services/session'
import { DeviceStatus } from '@services/constants'
import { Badge } from '@shared/innmaxUI'
import { DeleteWindow } from '../../components/Window'

import { toThousandSeparator } from '@shared/innmaxLib/utils/webHelper'
import { imageUrlPrefix } from '@shared/env'
import theme from '@theme'

interface MarkerComponentProps extends Omit<OverlayViewProps, 'position' | 'mapPaneName'> {
  item: ISession
  focus?: boolean
  tooltip?: boolean
  deletable?: boolean
  draggable?: boolean
  selected?: boolean
  size?: 'small' | 'large'

  onLeftClick?: (d: ISession) => void
  onRightClick?: (d: ISession) => void
  onMouseOver?: (d: ISession) => void
  onMouseOut?: (d: ISession) => void
  onDrag?: (e: google.maps.MapMouseEvent) => void
  onDragEnd?: (e: ISession) => void
  onDelete?: (d: ISession) => void
}

export default function AreaMarker ({
  children,
  item,
  focus,
  tooltip = true,
  deletable = false,
  draggable = false,
  selected = false,
  size,

  onLeftClick,
  onRightClick,
  onMouseOver,
  onMouseOut,
  onDrag,
  onDragEnd,
  onDelete,
  ...others
}: MarkerComponentProps) {
  const markerRef = React.useRef<any>()

  const { isStreetMode } = GoogleMap.useGoogleMap()

  const [markerPosition, setMarkerPosition] = React.useState<
    google.maps.LatLng | google.maps.LatLngLiteral
  >()

  const tooltipWindow = useVisible()

  const deleteWindow = useVisible()

  const markerVisible = useVisible()

  const status: DeviceStatus = React.useMemo(() => {
    return (
      item?.values?.alarm ||
      item?.devices?.filter(d => d.deviceStatus === DeviceStatus.ALARM).length ?
        DeviceStatus.ALARM :
      item?.values?.repair ||
      item?.devices?.filter(d => d.deviceStatus === DeviceStatus.REPAIR).length ?
        DeviceStatus.REPAIR :
        DeviceStatus.NORMAL
    )
  }, [item])

  const icon = React.useMemo(() => {
    switch(status) {
      case DeviceStatus.ALARM:
        return `${imageUrlPrefix}/cluster/cluster-alarm${size === 'small' ? '-small' : ''}.svg`
      case DeviceStatus.REPAIR:
        return `${imageUrlPrefix}/cluster/cluster-repair${size === 'small' ? '-small' : ''}.svg`
      case DeviceStatus.NORMAL:
        return `${imageUrlPrefix}/cluster/cluster-normal${size === 'small' ? '-small' : ''}.svg`
    }
  }, [status, size])

  const handleLeftClick = React.useCallback((e: any) => {
    item && onLeftClick && onLeftClick(item)
  }, [onLeftClick]) //eslint-disable-line

  const handleRightClick = React.useCallback((e: any) => {
    item && onRightClick && onRightClick(item)

    if (deletable) {
      deleteWindow.toggle()
    }
  }, [onRightClick]) //eslint-disable-line

  const handleOnDrag = React.useCallback((e: google.maps.MapMouseEvent) => {
    setMarkerPosition(e.latLng?.toJSON())
    onDrag && onDrag(e)
  }, [onDrag]) //eslint-disable-line

  const handleOnDragEnd = React.useCallback((e: google.maps.MapMouseEvent) => {
    setMarkerPosition(e.latLng?.toJSON())
    onDragEnd && onDragEnd({
      ...item,
      lat: e?.latLng?.lat() || 0,
      lon: e?.latLng?.lng() || 0,
    })
  }, [onDragEnd]) //eslint-disable-line

  const handleMouseOver = React.useCallback((e: any) => {
    tooltipWindow.open()
    onMouseOver && onMouseOver(e)
  }, []) //eslint-disable-line

  const handleMouseOut = React.useCallback((e: any) => {
    tooltipWindow.close()
    onMouseOut && onMouseOut(e)
  }, []) //eslint-disable-line

  const handleOnMarkerVisibleChanged = React.useCallback(() => {
    if (markerRef) {
      markerRef?.current?.marker?.visible ?
      markerVisible.open() :
      markerVisible.close()
    }
    tooltipWindow.close()
  }, [markerRef]) //eslint-disable-line

  React.useEffect(() => {
    setMarkerPosition({
      lat: item?.lat,
      lng: item?.lon,
    })
  }, [item])

  React.useEffect(() => {
    // 關閉刪除window
    document.addEventListener('click', deleteWindow.close)
    return () => document.removeEventListener('click', deleteWindow.close)
  }, []) //eslint-disable-line

  if (!markerPosition?.lat || !markerPosition?.lng) {
    return <></>
  }

  return (
    <MarkerComponent
      icon={{
        path: google.maps.SymbolPath.CIRCLE,
        scale: size === 'small' ? 30 : 60,
      }}
      ref={markerRef}
      title={isStreetMode ? item?.name || '' : ''}
      position={markerPosition as google.maps.LatLng}
      onVisibleChanged={handleOnMarkerVisibleChanged}
      opacity={0}
      onDrag={handleOnDrag}
      onDragEnd={handleOnDragEnd}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleLeftClick}
      onRightClick={handleRightClick}
      zIndex={others.zIndex || 99}
      draggable={draggable}>
      <OverlayViewF
        mapPaneName={OverlayView.MARKER_LAYER}
        position={markerPosition as google.maps.LatLng}
        {...others}>
        <>
          {
            tooltip && tooltipWindow.visible && item?.name && (
              <MarkerInfoWindow
                position={markerPosition}
                options={{
                  ...infoboxOptions,
                  pixelOffset: (
                    size === 'small' ?
                      new google.maps.Size(0, -50) :
                      new google.maps.Size(0, -82)
                  ),
                }}>
                <StyledTooltipWrapper>
                  <StyledTootipContent>{item?.name}</StyledTootipContent>
                  <StyledTooltipArrow></StyledTooltipArrow>
                </StyledTooltipWrapper>
              </MarkerInfoWindow>
            )
          }
          {
            deleteWindow.visible && (
              <MarkerInfoWindow
                position={markerPosition}
                options={{
                  ...infoboxOptions,
                  pixelOffset: (
                    size === 'small' ?
                      new google.maps.Size(140, 30) :
                      new google.maps.Size(170, 30)
                  ),
                }}>
                <DeleteWindow
                  data={item}
                  onCancel={deleteWindow.toggle}
                  deleteMarker={() => onDelete && onDelete(item)}
                />
              </MarkerInfoWindow>
            )
          }
          <StyledMarkerWrapper
            size={size}
            style={{ backgroundImage: `url(${icon})` }}>
            <div className="area truncate notranslate">
              {item.name}
            </div>
            {
              pathOr([], ['devices'], item) && (
                <>
                  <div
                    className={
                      cx('total notranslate', DeviceStatus[status].toLocaleLowerCase())
                    }>
                    {
                      toThousandSeparator(item?.values?.total || item?.devices?.length)
                    }
                  </div>
                  <div className="status notranslate">
                    <Badge status={DeviceStatus.ALARM} dotsize={8} border/>
                    <span>
                      {
                        toThousandSeparator(
                          item?.values?.alarm ||
                          item?.devices?.filter(d => d.deviceStatus === DeviceStatus.ALARM).length
                        )
                      }
                    </span>
                  </div>
                  <div className="status notranslate">
                    <Badge status={DeviceStatus.REPAIR} dotsize={8} border/>
                    <span>
                      {
                        toThousandSeparator(
                          item?.values?.repair ||
                          item?.devices?.filter(d => d.deviceStatus === DeviceStatus.REPAIR).length
                        )
                      }
                    </span>
                  </div>
                </>
              )
            }
          </StyledMarkerWrapper>
        </>
      </OverlayViewF>
    </MarkerComponent>
  )
}

const StyledMarkerWrapper = styled(({ ...props }) => (
  <div {...props} />
))`
  width: 180px;
  height: 180px;
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  margin-left: -90px;
  margin-top: -90px;

  ${p =>
    p.size === 'small' &&
    css`
      width: 108px;
      height: 108px;
      margin-left: -53px;
      margin-top: -55px;
      padding-top: 5px;
    `};

  .area {
    width: 55%;
    border-radius: 13px;
    background-color: ${theme.light};
    color: ${theme.n.gray400};
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 5px;
    padding: 0 10px;

    ${p =>
      p.size === 'small' &&
      css`
        font-size: 12px;
        background-color: unset;
        margin-bottom: 2px;
      `};
  }

  .total {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 5px;
    color: ${theme.light};

    &.alarm,
    &.repair {
      color: ${theme.n.yellow400};
    }

    ${p =>
      p.size === 'small' &&
      css`
        font-size: 13px;
        margin-bottom: 2px;
        color: ${theme.light};
      `};
  }

  .status {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: ${theme.light};
    margin-bottom: 3px;

    span:first-child {
      margin-right: 2px;
    }

    ${p =>
      p.size === 'small' &&
      css`
        font-size: 13px;
        margin-bottom: 2px;
      `};
  }

`

const StyledTooltipWrapper = styled.div`
  position: relative;
`

const StyledTootipContent = styled.div`
  background-color: ${p => p.theme.yellow100};
  border-radius: 2px;
  font-size: 13px;
  font-weight: normal;
  padding: 3px 8px;
  min-width: 68px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
`

const StyledTooltipArrow = styled.div`
  background-color: transparent;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid ${p => p.theme.yellow100};
`

const infoboxOptions = {
  visible: true,
  alignBottom: false,
  disableAutoPan: false,
  enableEventPropagation: false,
  boxClass: 'style-info-box',
  boxStyle: {
    overflow: 'hidden',
  },
}