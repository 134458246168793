import { TableProps } from './table.type'
import MobileTable from './MobileTable'
import QueryTable from './QueryTable'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toThousandSeparator } from '@shared/innmaxLib/utils/webHelper'

export { default as QueryTable } from './QueryTable'
export { default as BaseTable } from './Table'
export { default as ReportTable } from './ReportTable'

export { EditableCell, EditableRow } from './EditableTableContext'

export { default as EditableTable } from './EditableTable'

export type { RowAddHandler, RowDeleteHandler } from './EditableTable'

export type {
  EditableCellProps,
  EditableRowProps,
} from './EditableTableContext'

function TableFC<T = any>(props: TableProps<T>) {
  const [tableMode, setTableMode] = React.useState<'mobile' | 'desktop'>(
    'desktop'
  )

  React.useEffect(() => {
    const detectTableModel = () => {
      const breakPoints = 768
      if (window.innerWidth < breakPoints) {
        setTableMode('mobile')
      } else {
        setTableMode('desktop')
      }
    }
    detectTableModel()
    window.addEventListener('resize', detectTableModel)
    return () => {
      window.removeEventListener('resize', detectTableModel)
    }
  }, [])

  return tableMode === 'desktop' ? (
    <QueryTable {...props} />
  ) : (
    <MobileTable {...props} />
  )
}

const Table = React.memo(TableFC) as typeof TableFC

const RowCount = ({ count = 0 }: { count?: number }) => {
  const { t } = useTranslation()
  return (
    <div className="text-primary text-sm">
      {t('common:total count', {
        total: toThousandSeparator(count),
      })}
    </div>
  )
}

export default Object.assign(Table, {
  RowCount,
})
