import React, { SVGProps } from 'react'
const PowerConsumption = (props: SVGProps<any>) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.6087 23.9551H9.42025C7.89887 23.9551 6.66663 25.2038 6.66663 26.7456V54.6505C6.66663 56.1923 7.89887 57.441 9.42025 57.441H20.8144"
      stroke="#003248"
      strokeWidth="3.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.4575 57.441H60.3623C61.8836 57.441 63.1159 56.1923 63.1159 54.6505V26.7456C63.1159 25.2038 61.8836 23.9551 60.3623 23.9551H46.7016"
      stroke="#003248"
      strokeWidth="3.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.1159 32.3267H67.2463C68.7677 32.3267 70 33.5754 70 35.1172V46.2791C70 47.8209 68.7677 49.0696 67.2463 49.0696H63.1159"
      stroke="#003248"
      strokeWidth="3.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.4785 35.4719L40.4203 13.3335L18.6984 46.4371L31.9268 44.5284L27.985 66.6668L49.7069 33.5632L36.4785 35.4719Z"
      fill="#003248"
    />
  </svg>
)

export default PowerConsumption
