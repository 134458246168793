import React from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'
import { pathOr } from 'ramda'
import { QueryVariables } from '@shared/innmaxLib/services'
import { formatDate, isNil } from '@shared/utils/webHelper'

import { ILinkRate } from './report.types'
import { IQueryProps, PAGE_SIZE, TYPE, generateDatetime, dateTimeUnit, dateTimeFormat } from '@services/report'

export const useLinkRateChart = (querys: { [key: string]: any }, options?: QueryVariables) => {
  const { data, ...others } = useQuery(
    [
      '/device/streetlight/report/lampDailyLinkRate',
      { ...querys, pageNum: undefined, pageSize: undefined },
    ],
    {
      enabled: !isNil(querys),
      ...options,
    }
  )

  const _dataSource = pathOr([], ['data'], data)

  const dataSource = React.useMemo(() => {
    const date: any = generateDatetime(querys.reportType, querys.start, querys.end)
    _dataSource?.forEach((item: any) => {
      const index: number = moment(item.date).startOf(dateTimeUnit(querys.reportType)).valueOf()
      date[index][1] = item.rate * 100
      item['displayDate'] = dateTimeFormat(querys.reportType, item.date)
      item['rate'] = item.rate * 100
    })

    return {
      content: _dataSource,
      totalPages: Math.ceil(Object.keys(date).length / PAGE_SIZE),
      chart: Object.keys(date).map(k => date[k]).sort(),
    }
  }, [data, querys]) //eslint-disable-line

  return {
    dataSource,
    ...others,
  }
}

export const useExportLinkRateRpt = (query?: IQueryProps, options?: QueryVariables) => {
  const [enabled, setIsEnabled] = React.useState(false)

  const { isLoading } = useQuery(
    [
      '/device/streetlight/report/lampDailyLinkRate/export',
      { deep: true, ...query, pageNum: undefined, pageSize: undefined },
    ],
    {
      ...options,
      enabled,
      onSuccess: (response: any) => {
        if (options?.onSuccess) {
          options.onSuccess(response)
        }
        setIsEnabled(false)
      },
      onError: error => {
        console.log(error)
        setIsEnabled(false)
      },
    }
  )

  return [
    isLoading,
    () => {
      setIsEnabled(true)
    },
  ] as [boolean, () => void]
}
